.about-navbar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100px;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 70px 70px 5px;
  background: rgba(50, 50, 50, 0.5);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  z-index: 10;
  padding-top: 50px;
}
.about-navbar.thought-process {
  background: rgba(255, 255, 255, 0.5);
}
.about-navbar.thought-process .logo .scope p {
  color: #323232;
}
.about-navbar.thought-process .links .scope a {
  color: #323232;
}
.about-navbar.thought-process .links.links-bamise .scope a {
  color: #323232;
}
.about-navbar .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.about-navbar .logo .scope {
  width: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.about-navbar .logo .scope p {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.about-navbar .logo .scope.scope-bamise {
  height: 210px;
  width: 50px;
  margin-bottom: 5px;
}
.about-navbar .logo .scope.scope-bamise p {
  font-size: 18px;
}
.about-navbar .links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  padding-bottom: 60px;
}
.about-navbar .links.links-bamise {
  padding-bottom: 30px;
}
.about-navbar .links.links-bamise .scope {
  width: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.about-navbar .links.links-bamise .scope.get {
  height: 50px;
}
.about-navbar .links.links-bamise .scope:first-child {
  height: 40px;
  margin-bottom: 28px;
}
.about-navbar .links.links-bamise .scope:nth-child(2) {
  margin-bottom: 20px;
}
.about-navbar .links.links-bamise .scope:nth-child(3) {
  height: 80px;
  margin-bottom: 20px;
}
.about-navbar .links.links-bamise .scope:nth-child(4) {
  height: 50px;
}
.about-navbar .links.links-bamise .scope a {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #fff;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.48px;
  transition: 0.2s all ease-in-out;
}
.about-navbar .links.links-bamise .scope a.active {
  font-weight: 800;
}
.about-navbar .links.links-bamise .scope a:hover {
  font-weight: 800;
}
.about-navbar .links .scope {
  width: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.about-navbar .links .scope.get {
  height: 50px;
}
.about-navbar .links .scope:first-child {
  height: 65px;
}
.about-navbar .links .scope:nth-child(3) {
  height: 80px;
}
.about-navbar .links .scope:nth-child(4) {
  height: 50px;
}
.about-navbar .links .scope a {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.48px;
  transition: 0.2s all ease-in-out;
}
.about-navbar .links .scope a.active {
  font-weight: 800;
}
.about-navbar .links .scope a:hover {
  font-weight: 800;
}
@media only screen and (max-width: 575px) {
  .about-navbar {
    display: none;
  }
}/*# sourceMappingURL=about-nav.css.map */