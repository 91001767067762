.project-flex-list {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-right: 100px;

  .project {
    display: flex;
    padding: var(--Radius-50, 50px) 0px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: var(--Radius-50, 50px);
    background: #000;
    height: 90%;
    width: 770px;
    margin-right: 30px;
    overflow: hidden;

    &.desktop {
      width: 1301px;
      padding: var(--Radius-50, 50px) 0;
    }

    img {
      //   width: 110%;
      height: 100%;
      object-fit: contain;
    }

    video {
      width: 100%;
      height: 100%;
      //   object-fit: cover;
      object-fit: contain;

      &.edit {
        object-fit: contain;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
