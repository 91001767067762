.work-mobile-project-page {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0;
  padding: 0;
  /* Hide scrollbar for IE, Edge, and Firefox */
  /* Hide scrollbar for Chrome, Safari, and Opera */
}
.work-mobile-project-page *::-webkit-scrollbar {
  display: none;
}
.work-mobile-project-page * {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.work-mobile-project-page .mobile-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  z-index: 0;
}
.work-mobile-project-page .container {
  position: relative;
  z-index: 1;
  margin-top: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.work-mobile-project-page .container .project-title {
  text-transform: capitalize;
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.5px;
  margin-bottom: 100px;
  padding: 0 20px;
}
.work-mobile-project-page .container .owned {
  margin-bottom: 100px;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
}
.work-mobile-project-page .container .service {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 0 20px;
}
.work-mobile-project-page .container .service h2 {
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: -0.48px;
  width: 99;
  margin-bottom: 20px;
  text-align: right;
}
.work-mobile-project-page .container .service p {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
  border-top: 1px solid #fff;
  padding-top: 20px;
  text-align: right;
}
.work-mobile-project-page .container .service .role h2 {
  text-align: left;
}
.work-mobile-project-page .container .service .role p {
  text-align: left;
}
.work-mobile-project-page .container .project-image {
  width: 100%;
  margin-bottom: 30px;
  padding: 0 20px;
}
.work-mobile-project-page .container .project-flex-mobile-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-bottom: 100px;
}
.work-mobile-project-page .container .project-flex-mobile-list .project {
  display: flex;
  margin-top: 20px;
  width: 100%;
  padding: var(--Radius-50, 50px) 0px;
  align-items: center;
  gap: 10px;
  border-radius: var(--Radius-50, 50px);
  background: #000;
  overflow: hidden;
}
.work-mobile-project-page .container .project-flex-mobile-list .project.desktop video {
  height: 100%;
}
.work-mobile-project-page .container .project-flex-mobile-list .project video {
  width: 100%;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
}
.work-mobile-project-page .container .owned-img-container {
  height: 607px;
  width: 100%;
}
.work-mobile-project-page .container .owned-img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.work-mobile-project-page .container .laptop-image-container {
  width: 100%;
  height: 363px;
  align-self: stretch;
  background: #1a1a1a;
  overflow: hidden;
}
.work-mobile-project-page .container .laptop-image-container video {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center;
     object-position: center;
  transform: scale(1.2);
}
.work-mobile-project-page .container .phone-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 80px 0;
}
.work-mobile-project-page .container .phone-video-container video {
  width: 250px;
  height: 544.271px;
  -o-object-fit: contain;
     object-fit: contain;
}
.work-mobile-project-page .container .visit {
  display: flex;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50%;
  background: #2c2c2c;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 100px;
  letter-spacing: -0.6px;
}
.work-mobile-project-page .container .phones {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
}
.work-mobile-project-page .container .phones img {
  width: 306.12px;
  height: 593.537px;
  margin-bottom: 30px;
}
.work-mobile-project-page .container .phones img:last-child {
  margin-bottom: 0;
}
.work-mobile-project-page .case-container {
  width: 100%;
  background: url("../../svgs/contact_bg.svg");
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 0 30px;
  padding-top: 128px;
  padding-bottom: 100px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
}
.work-mobile-project-page .case-container .content {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  height: 454px;
  overflow: hidden;
  padding: 0 10px;
  margin-bottom: 100px;
  border-bottom: 1px solid rgba(27, 27, 27, 0.3);
}
.work-mobile-project-page .case-container .content p {
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.6px;
}
.work-mobile-project-page .case-container .content h2 {
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 45px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.8px;
  padding: 40px 0;
}
.work-mobile-project-page .case-container .content .case {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px 20px 0px 0px;
  background: rgba(41, 66, 69, 0.8);
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
}
.work-mobile-project-page .case-container .content .case img {
  padding: 0;
  margin: 0;
}
.work-mobile-project-page .case-container .content .case h1 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.work-mobile-project-page .case-container .content .case h3 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.work-mobile-project-page .case-container .content .case .ctn {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.work-mobile-project-page .case-container .work {
  display: flex;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  border-radius: 200px;
  background: #2c2c2c;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
}
.work-mobile-project-page .case-container .view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.work-mobile-project-page .case-container .view p {
  color: var(--Color-Primary-Black, #1b1b1b);
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 17px;
}
@media only screen and (min-device-width: 820px) and (max-device-width: 820px) and (orientation: portrait) {
  .work-mobile-project-page .container {
    transform: translateX(4%);
  }
}/*# sourceMappingURL=work-mobile-project-page.css.map */