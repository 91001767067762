.project-content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 200px;
}
.project-content.thought-process .screen-landing {
  height: 70%;
  margin-right: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.project-content.thought-process .screen-landing .screen-landing-title {
  color: #323232;
  font-family: Raleway;
  font-size: 120px;
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 75% */
  letter-spacing: -6px;
  margin-bottom: 100px;
}
.project-content.thought-process .screen-landing .owned {
  color: #323232;
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 650px;
}
.project-content.thought-process .owned-img-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.project-content.thought-process .owned-img-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.project-content.thought-process .owned-img-container .framed-container {
  display: flex;
  height: 85%;
  width: 100%;
  padding: 50px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 20px 100px 100px 20px;
  border: 1px solid #323232;
}
.project-content.thought-process .owned-img-container .framed-container .left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-right: 90px;
}
.project-content.thought-process .owned-img-container .framed-container .left .title {
  color: #323232;
  font-family: Raleway;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -3.2px;
}
.project-content.thought-process .owned-img-container .framed-container .left .content {
  color: #323232;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.project-content.thought-process .owned-img-container .framed-container .right {
  flex: 1;
  margin-left: 90px;
}
.project-content.thought-process .owned-img-container .framed-container .right.height {
  height: 100%;
}
.project-content.thought-process .owned-img-container .framed-container .right.height img {
  border-radius: 50px;
}
.project-content.thought-process .owned-img-container .framed-container.dark {
  background: #323232;
}
.project-content.thought-process .owned-img-container .framed-container.dark .title {
  color: #f0f0f0;
}
.project-content.thought-process .owned-img-container .framed-container.dark .content {
  color: #ffffff;
}
.project-content.thought-process .owned-img-container .tools-container {
  display: flex;
  padding: 0px 150px;
  align-items: flex-start;
  gap: 150px;
  width: 100%;
}
.project-content.thought-process .owned-img-container .tools-container .tools-section .title {
  color: #323232;
  font-family: Raleway;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 128.571% */
  letter-spacing: -3.5px;
  margin-bottom: 15px;
}
.project-content.thought-process .owned-img-container .tools-container .tools-section .tools {
  display: flex;
}
.project-content.thought-process .owned-img-container .tools-container .tools-section .tools img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.project-content.thought-process .owned-img-container .tools-container .tools-section .tools img:first-child {
  margin-right: 10px;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section {
  flex: 1;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .title {
  color: #323232;
  font-family: Raleway;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 90px; /* 128.571% */
  letter-spacing: -3.5px;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .strengths {
  margin-top: 30px;
  width: 100%;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .strengths .strength {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .strengths .strength p {
  color: #323232;
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .strengths .strength .contain {
  display: flex;
  align-items: center;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .strengths .strength .contain .bar-container {
  width: 221px;
  height: 10.5px;
  border: 1px solid #323232;
  border-radius: 5px;
  margin-right: 21px;
  overflow: hidden;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .strengths .strength .contain .bar-container .bar {
  background: #323232;
  transform-origin: left;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.project-content.thought-process .owned-img-container .tools-container .strength-section .strengths .strength .contain .progress {
  color: #323232;
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.project-content .screen-landing {
  height: 70%;
  margin-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.project-content .screen-landing .screen-landing-title {
  color: #fff;
  font-family: Raleway;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: -3px;
}
.project-content .screen-landing .owned {
  color: #fff;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.9px;
}
.project-content .screen-landing .section-container {
  display: flex;
}
.project-content .screen-landing .section-container p {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: -0.6px;
  margin-bottom: 40px;
  word-wrap: nowrap;
}
.project-content .screen-landing .section-container h2 {
  padding-top: 40px;
  color: #fff;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
  margin: 0;
  max-width: none;
  width: auto;
  letter-spacing: -0.9px;
  border-top: 1px solid #fff;
}
.project-content .screen-landing .section-container .service-work {
  margin-right: 121px;
}
.project-content .screen-landing .section-container .service-work p {
  width: 243px;
}
.project-content .screen-landing .section-container .timeline {
  margin-left: 121px;
}
.project-content .project-full-image {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}
.project-content .project-full-image img {
  width: auto;
  height: 80%;
  max-width: none;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.project-content .owned-img-container {
  height: 100%;
}
.project-content .owned-img-container img {
  width: 100%;
  max-width: none;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.project-content .laptop-image-container {
  width: 1745px;
  height: 100%;
  position: relative;
}
.project-content .laptop-image-container video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.project-content .phone-video-container {
  width: 941px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.project-content .phone-video-container video {
  width: 350px;
  height: 85%;
}
.project-content .phones {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1500px;
}
.project-content .phones .mobile {
  width: 310.556px;
  height: 600px;
  flex-shrink: 0;
}
.project-content .phones .mobile:first-child {
  margin-right: 30px;
}/*# sourceMappingURL=project.content.css.map */