.menu-cursor {
  display: flex;
  width: 200px;
  height: 150px;
  justify-content: center;
  position: fixed;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: #9747ff;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 90;
  letter-spacing: -0.72px;
  opacity: 0;
  transform: scale(0.2);
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}
