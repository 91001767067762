.home-page {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

  .event-none {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 950;
    background: #1a1a1acc;
  }

  .home-main {
    background: url("../svgs/home_background.svg");
    background-position: center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    transition: 0.1s background ease-in-out;

    .developed {
      position: absolute;

      width: 19px;
      height: 282px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 27px;
      top: 34px;

      p {
        transform: rotate(-90deg);
        text-wrap: nowrap;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    &.dark-background {
      background: url("../svgs/home_background_black.svg");
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }

    .group-contents {
      overflow: hidden;
      display: flex;
      flex-direction: row; /* Ensure horizontal layout */
      width: 100vw;
      height: 100vh;
      position: absolute;
    }

    .home-content {
      height: 100vh;
      width: 100vw;
      flex-shrink: 0; /* Prevent shrinking */
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(2%);

      .home-inner {
        margin: auto;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
          margin-top: 50px;
          color: #2c2c2c;
          text-align: center;
          font-size: 64px;
          font-weight: 900;
          line-height: 65px;
          letter-spacing: -1.92px;

          .cursor {
            color: transparent;
            display: inline-block;
            background-color: #2c2c2c;
            width: 4px;
            margin-left: 2px;
            animation: blink 1s infinite;
          }

          /* Blinking effect */
          @keyframes blink {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }

        h4 {
          margin-top: 50px;
          color: #2c2c2c;
          text-align: center;
          font-size: 24px;
          font-weight: 400;
          line-height: normal;
        }

        p {
          margin-top: 20px;
          color: #2c2c2c;
          text-align: center;
          font-size: 16px;
          font-weight: 300;
          line-height: normal;

          &.content-a {
            color: #2c2c2c;
            text-align: center;
            font-size: 25px;
            font-weight: 300;
          }
        }

        .buttons-container {
          display: flex;

          .btn {
            margin-top: 70px;

            &:first-child {
              margin-right: 10px;
            }

            .text {
              font-size: 16px;
              font-weight: 700;
            }
          }
        }

        .btn {
          margin-top: 30px;
          display: flex;
          width: 300px;
          height: 80px;
          padding: 20px 10px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          border-radius: 50px;
          background: #2c2c2c;
          position: relative;
          overflow: hidden;

          &.action-btn {
            background: rgba(240, 240, 240, 0.4);
            backdrop-filter: blur(25px);

            .text {
              color: #323232;
            }

            .icon-container {
              background: rgba(255, 255, 255, 0.54);
              backdrop-filter: blur(25px);
            }
          }
        }

        .text {
          flex: 1;
          text-align: center;
          color: #fff;
          font-size: 20px;
          font-weight: 900;
          position: relative;
          z-index: 1;
        }

        .icon-container {
          position: relative;
          z-index: 1;
          display: flex;
          width: 60px;
          height: 60px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 50px;
          background: var(--color-grey-Altblack, #323232);
        }

        .background {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          transform-origin: bottom;
          z-index: 0;
          border-radius: 50%;
        }
      }

      &.home-getStarted {
        .home-inner {
          margin: auto;
          width: 70%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;

          h1 {
            margin-top: 0;
            color: #f0f0f0;
            font-size: 120px;
            font-style: normal;
            font-weight: 900;
            line-height: 120px; /* 80% */
            letter-spacing: -4.5px;
            text-align: start;
            text-wrap: nowrap;

            &.second {
              display: none;
            }
          }

          .wrapper {
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;

            &.second {
              display: none;
            }

            .content-a {
              margin-top: 45px;
              color: #f0f0f0;
              font-size: 36px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              text-align: start;
            }

            .wrap {
              display: flex;

              p {
                color: #f0f0f0;
                text-align: center;
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px;
                margin-right: 10px;
                text-align: start;
              }

              img {
                z-index: 100;
              }
            }
          }
        }
      }

      &.home-b {
        .home-inner {
          h1 {
            text-align: center;
            font-size: 96px;
            font-style: normal;
            font-weight: 800;
            line-height: 80px; /* 83.333% */
            letter-spacing: -2.88px;
          }

          h4 {
            margin-top: 100px;
            color: var(--color-grey-Altblack, #323232);
            text-align: center;
            font-size: 32px;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      &.home-name {
        .home-inner {
          h4 {
            margin-top: 0;
            color: #f1f1f1;
            text-align: center;
            font-size: 48px;
            font-weight: 800;
            line-height: 50px;
            letter-spacing: -1.44px;
          }

          .form-container {
            margin-top: 70px;
            display: flex;
            align-items: center;

            input[type="text"] {
              outline: none;
              margin-right: 10px;
              color: #fff;
              width: 552px;
              height: 100px;
              padding: 10px 20px;
              text-align: center;
              font-size: 20px;
              font-weight: 400;
              border-radius: 50px;
              background: var(--color-grey-Altblack, #323232);
              box-shadow: 4px 4px 15px 5px #2c2c2c inset,
                -4px -4px 15px 5px rgba(255, 255, 255, 0.2) inset;
            }

            button {
              margin-top: 0;
              width: 300px;
              height: 100px;
              background: #1d1d1d;
              backdrop-filter: blur(25px);

              .text {
                color: #fff;
                font-size: 24px;
                font-weight: 700;
              }

              .icon-container {
                height: 100%;
                width: 80px;
                height: 80px;
                background: #2c2c2c;
                backdrop-filter: blur(25px);
              }
            }
          }
        }
      }

      &.home-org {
        .home-inner {
          h4.org {
            display: none;
          }
          .form-container {
            flex-direction: column;
            width: 100%;
            transition: 0.3s all ease-in-out;

            input[type="text"] {
              width: 75%;
            }

            button {
              width: 300px;
            }

            .select-container {
              display: flex;
              flex-direction: column;
              width: 75%;
              height: 320px;
              padding: 20px 10px;
              padding-top: 10px;
              cursor: pointer;
              border-radius: 50px;
              background: #1c1c1c;
              position: relative;
              overflow: hidden;
              margin: 10px 0;
              transform-origin: bottom;
              transition: 0.3s all ease-in-out;

              &.extend {
                height: 100px;

                .selected-content {
                  .icon-container {
                    transform: scaleY(1);
                  }
                }

                .organisations {
                  opacity: 0;
                }
              }

              &.shrink {
                height: 190px;

                .organisations {
                  opacity: 0;
                  display: none;
                }
              }

              .selected-org {
                margin-top: 10px;
                border-radius: 50px;
                background: linear-gradient(
                  90deg,
                  #6a5a38 0%,
                  #563e38 30%,
                  #515f61 70%,
                  #463b4c 100%
                );
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                border-radius: 50px;

                .ctn {
                  flex: 1;
                  text-align: center;
                  color: white;
                  font-size: 18px;
                  font-weight: 700;
                  transform: translateX(2%);
                }

                img {
                  filter: invert(1) brightness(2);
                }
              }

              .selected-content {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .selected-text {
                  flex: 1;
                  text-align: center;
                  color: #fff;
                  position: relative;
                  z-index: 1;
                  font-size: 24px;
                  font-weight: 500;
                }

                .icon-container {
                  position: relative;
                  z-index: 1;
                  display: flex;
                  width: 80px;
                  height: 80px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  flex-shrink: 0;
                  border-radius: 50px;
                  background: transparent;
                  transform: scaleY(-1);
                  transition: 0.3s transform ease-in-out;
                }
              }

              .organisations {
                width: 100%;
                margin-top: 10px;
                opacity: 1;
                transition: 0.1s all ease-in-out;

                .option {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  padding: 20px 0;
                  cursor: pointer;
                  border-radius: 50px;
                  transition: 0.2s all ease-in-out;

                  .label {
                    flex: 1;
                    font-size: 20px;
                    text-align: center;
                    color: #d3d3d3;
                  }

                  input {
                    margin-left: 50px;
                    appearance: none;
                    position: relative;
                    cursor: pointer;

                    &::before {
                      right: 10%;
                      content: "";
                      position: absolute;
                      transform: translateX(-150%);
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      background-color: white;
                      transition: 0.1s all ease-in-out;
                      top: -10px;
                    }

                    &:checked::before {
                      background-color: #2d2d2d;
                    }
                  }

                  &:hover {
                    background: #d3d3d3;

                    .label {
                      color: #2c2c2c;
                    }

                    input {
                      &::before {
                        background-color: #1c1c1c;
                      }

                      &:checked::before {
                        background-color: #2d2d2d;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.home-service {
        .home-inner {
          height: 100%;
          position: absolute;

          h1 {
            color: #2c2c2c;
            text-align: center;
            font-size: 64px;
            font-weight: 800;

            &.service-h1-mobile {
              display: none;
            }
          }

          .ctn-wrapper {
            display: flex;
            transform: translateX(9%);
            align-items: center;

            .content {
              width: 100%;
              flex: 1;
              margin-right: 70px;

              h4 {
                margin-top: 30px;
                color: #2c2c2c;
                font-size: 32px;
                font-weight: 400;
              }

              p {
                margin-top: 10px;
                color: #2c2c2c;
                font-size: 20px;
                font-weight: 400;

                &.ctn-mobile-p {
                  display: none;
                }
              }
            }

            .btn {
              margin-top: 0;
              display: flex;
              width: 100px;
              height: 150px;
              padding: 0px 10px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              pointer-events: none;
              overflow: hidden;
              opacity: 0;
              transition: 0.2s opacity ease-in-out;
              transition-delay: 2.5s;

              &.btn-fade {
                opacity: 1;
                pointer-events: all;
              }

              &.action-btn {
                border-radius: 300px;
                background: #2c2c2c;
                backdrop-filter: blur(25px);
              }

              img {
                width: 80px;
                height: 80px;
                z-index: 10;
                color: #37bb9ed3;
              }
            }
          }

          .service-cards {
            width: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
            padding-bottom: 30px;

            .service-card {
              cursor: pointer;
              display: flex;
              width: 450px;
              padding: 30px;
              align-items: center;
              gap: 15px;
              border-radius: 50px;
              overflow: hidden;
              background: radial-gradient(
                225.11% 98.34% at 90.75% 93.53%,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(153, 153, 153, 0.1) 100%
              );
              backdrop-filter: blur(25px);
              position: relative;
              z-index: 1;
              height: 450px;
              transition: 0.2s all ease-in-out;

              &:hover {
                transform: scale(0.99);
              }

              @media screen and (max-height: 1535px) and (min-height: 1344px) {
                height: 450px;
              }

              // If the screen height is below 14 inches (1344px)
              @media screen and (max-height: 1343px) {
                height: 450px;
              }

              .bg-card {
                position: absolute;
                top: 0;
                background: #2c2c2c;
                width: 100%;
                height: 100%;

                // &.bg-rg {
                //   right: 0;
                //   transform-origin: right;
                // }
              }

              .scope {
                width: 113px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .scope-title {
                  transform: rotate(-90deg);
                  color: #2c2c2c;
                  font-size: 90px;
                  font-weight: 700;
                  transition: 2s ease-in-out;
                }
              }

              .work {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                z-index: 1;

                p {
                  color: #2c2c2c;
                  font-size: 16px;
                  font-weight: 400;
                  text-align: start;
                  transition: 2s ease-in-out;

                  &:first-child {
                    padding-top: 5px;
                  }

                  &:last-child {
                    padding-bottom: 30px;
                  }
                }
              }

              img {
                display: flex;
                width: 60px;
                height: 60px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                z-index: 1;
                transition: 2s ease-in-out;
              }

              &.filled {
                .scope {
                  .scope-title {
                    color: #ffffff;
                  }
                }

                .work {
                  p {
                    color: #ffffff;
                  }
                }

                img {
                  filter: invert(1) brightness(2);
                }
              }

              &.snd {
                flex-direction: row-reverse;

                .work {
                  p {
                    text-align: end;
                  }
                }
              }
            }

            .fusion {
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: center;

              .left,
              .right {
                flex: 1;
                display: flex;
                position: relative;
                cursor: pointer;

                .bar {
                  flex: 1;
                  height: 6px;
                  border-radius: 10px;
                  z-index: 1;
                  background: #f1f1f1;

                  &.lb {
                    margin: 0 2px;
                  }

                  &.rb {
                    margin: 0 2px;
                  }
                }
              }

              .bp {
                width: 40px;
                height: 40px;
                opacity: 0.2;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          .btn-mobile-proceed-container {
            display: none;
          }
        }
      }

      &.home-device {
        .home-inner {
          h4 {
            margin: 0;
            color: #1d1d1d;
            text-align: center;
            font-size: 48px;
            font-weight: 800;
            letter-spacing: -1.44px;
          }

          .cards {
            display: flex;
            align-items: center;
            justify-content: center;

            &.cards-mobile-repo {
              display: none;
            }

            .card {
              display: flex;
              margin-top: 70px;
              width: 300px;
              height: 300px;
              padding: 0px 10px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 20px;
              align-self: stretch;
              border-radius: 50px;
              background: radial-gradient(
                256.3% 103.57% at 12.22% 89.9%,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(153, 153, 153, 0.2) 100%
              );
              backdrop-filter: blur(25px);
              margin-right: 20px;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              transition: 0.2s all ease-in-out;

              &:hover {
                transform: scale(0.95);
              }

              &.filled {
                p {
                  color: white;
                }

                img {
                  filter: invert(1) brightness(2);
                }
              }

              .bg-cd {
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 50%;
                background: #2c2c2c;
                width: 100%;
                height: 100%;
                pointer-events: none;

                // &.bg-rg {
                //   right: 0;
                //   transform-origin: right;
                // }
              }

              img {
                z-index: 1;
                transition: 1s all ease-in-out;
              }

              p {
                margin: 0;
                z-index: 1;
                color: #1d1d1d;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                transition: 1s all ease-in-out;
              }
            }
          }

          button {
            margin-top: 90px;
            border-radius: 50px;
            backdrop-filter: blur(25px);
            width: 600px;

            &.device-btn {
              background: #1d1d1d;

              .text {
                color: #fff;
                text-align: center;
                font-size: 24px;
                font-weight: 700;
              }

              .icon-container {
                border-radius: 60px;
                background: #2c2c2c;
                backdrop-filter: blur(25px);
              }
            }
          }
        }
      }

      &.home-tell {
        position: relative;

        .home-inner {
          position: absolute;

          h4 {
            margin-top: 0;
            color: #1d1d1d;
            text-align: center;
            font-size: 48px;
            font-weight: 800;
            line-height: 50px;
            letter-spacing: -1.44px;

            &.tell-h4-mobile {
              display: none;
            }
          }

          textarea {
            display: flex;
            width: 700px;
            height: 300px;
            padding: 40px 50px;
            align-items: flex-start;
            gap: 10px;
            outline: none;
            border: none;
            resize: none;
            border-radius: 50px;
            background: radial-gradient(
                256.3% 103.57% at 12.22% 89.9%,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(237, 237, 237, 0.2) 100%
              ),
              #fff;
            box-shadow: 4px 4px 15px 5px rgba(44, 44, 44, 0.1) inset,
              -4px -4px 15px 5px rgba(255, 255, 255, 0.1) inset;
            margin-top: 50px;
            color: #2c2c2c;
            font-family: inherit;
            font-size: 20px;
            font-weight: 400;
          }

          button {
            width: 700px;

            &.tell-btn {
              background: #1d1d1d;
              backdrop-filter: blur(25px);

              .text {
                color: white;
              }

              .icon-container {
                border-radius: 60px;
                background: #2c2c2c;
                backdrop-filter: blur(25px);
              }
            }
          }
        }

        .img-bp {
          position: absolute;
          right: 0;
          top: 40%;
          transition: 0.2s opacity ease-in-out;
          transition-delay: 1;
          pointer-events: none;

          &.hide {
            opacity: 0;
            display: none;
          }
        }

        .img-bpp {
          position: absolute;
          top: 40%;
          right: 43%;
          transform: scale(2);
          opacity: 0;
          pointer-events: none;

          &.reveal {
            opacity: 1;
          }
        }
      }

      &.home-features {
        position: relative;

        .home-inner {
          h4.feature-h4-mobile {
            display: none;
          }

          .form-container {
            margin-top: 30px;

            .container {
              width: 55%;
              max-height: 400px;
              overflow-y: auto;
              overscroll-behavior-y: none;
              scroll-behavior: smooth;
              margin-bottom: 30px;
              padding-right: 10px;
              -ms-overflow-style: scrollbar; /* IE and Edge */
              scrollbar-width: auto;

              &::-webkit-scrollbar {
                display: block;
                width: 12px;
                border-radius: 10px;
                background: #2e2e32;
              }

              &::-webkit-scrollbar-thumb {
                display: block;
                border-radius: 10px;
                background: linear-gradient(
                  180deg,
                  rgba(81, 81, 81, 0) 0%,
                  rgba(255, 255, 255, 0.2) 100%
                );
              }

              .service-container {
                display: flex;
                width: 100%;
                // align-items: center;
                flex-direction: column;

                .select-container {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  height: 390px;
                  padding: 20px 10px;
                  padding-top: 10px;
                  cursor: pointer;
                  border-radius: var(--Radius-50, 50px);
                  background: rgba(27, 27, 27, 0.6);
                  backdrop-filter: blur(25px);
                  position: relative;
                  overflow: hidden;
                  // margin: 10px 0;
                  margin: 0;
                  margin-bottom: 5px;
                  transform-origin: bottom;
                  transition: 0.3s all ease-in-out;

                  &.brand {
                    height: 250px;
                  }

                  &.extend {
                    height: 100px;

                    .selected-content {
                      .icon-container {
                        transform: scaleY(1);
                      }
                    }

                    .organisations {
                      opacity: 0;
                    }
                  }

                  &.shrink {
                    height: 190px;

                    .organisations {
                      opacity: 0;
                      display: none;
                    }
                  }

                  .selected-org {
                    margin-top: 10px;
                    border-radius: 50px;
                    background: linear-gradient(
                      90deg,
                      #6a5a38 0%,
                      #563e38 30%,
                      #515f61 70%,
                      #463b4c 100%
                    );
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    border-radius: 50px;

                    .ctn {
                      flex: 1;
                      text-align: center;
                      color: white;
                      font-size: 18px;
                      font-weight: 700;
                      transform: translateX(2%);
                    }

                    img {
                      filter: invert(1) brightness(2);
                    }
                  }

                  .selected-content {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;

                    .selected-text {
                      flex: 1;
                      text-align: center;
                      color: #fff;
                      position: relative;
                      z-index: 1;
                      font-size: 24px;
                      font-weight: 500;
                      transform: translateX(3%);
                    }

                    .icon-container {
                      position: relative;
                      z-index: 1;
                      display: flex;
                      width: 80px;
                      height: 80px;
                      justify-content: center;
                      align-items: center;
                      gap: 10px;
                      flex-shrink: 0;
                      border-radius: 50px;
                      background: transparent;
                      transform: scaleY(-1);
                      transition: 0.3s transform ease-in-out;
                    }
                  }

                  .organisations {
                    width: 100%;
                    margin-top: 10px;
                    opacity: 1;
                    transition: 0.1s all ease-in-out;

                    .option {
                      display: flex;
                      width: 100%;
                      align-items: center;
                      padding: 20px 0;
                      cursor: pointer;
                      border-radius: 50px;
                      transition: 0.2s all ease-in-out;

                      .label {
                        flex: 1;
                        font-size: 20px;
                        text-align: center;
                        color: #d3d3d3;
                      }

                      input {
                        margin-left: 50px;
                        appearance: none;
                        position: relative;
                        cursor: pointer;

                        &::before {
                          right: 10%;
                          content: "";
                          position: absolute;
                          transform: translateX(-140%);
                          width: 20px;
                          height: 20px;
                          border-radius: 50%;
                          background-color: white;
                          transition: 0.1s all ease-in-out;
                          top: -10px;
                        }

                        &:checked::before {
                          background-color: #2d2d2d;
                        }
                      }

                      &:hover {
                        background: #d3d3d3;

                        .label {
                          color: #2c2c2c;
                        }

                        input {
                          &::before {
                            background-color: #1c1c1c;
                          }

                          &:checked::before {
                            background-color: #2d2d2d;
                          }
                        }
                      }
                    }
                  }
                }

                .ft-container {
                  width: 100%;

                  .ft {
                    width: 100%;
                    display: flex;
                    height: 100px;
                    padding: 0px 10px;
                    justify-content: space-between;
                    align-items: center;
                    flex-shrink: 0;
                    align-self: stretch;
                    border-radius: 50px;
                    border-radius: var(--Radius-50, 50px);
                    background: rgba(27, 27, 27, 0.6);
                    backdrop-filter: blur(25px);
                    margin-bottom: 5px;
                    cursor: pointer;
                    position: relative;

                    .ft-text {
                      flex: 1;
                      color: #fff;
                      text-align: center;
                      font-size: 24px;
                      font-weight: 500;
                      transform: translateX(-3%);
                    }

                    .checkbox-container {
                      position: absolute;
                      right: 35px;
                      width: 30px;
                      height: 30px;

                      .checkmark {
                        color: white;
                        font-size: 20px;
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        right: 0%;
                        transform: translate(-25%, -50%);
                      }

                      input {
                        appearance: none;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;

                        &::before {
                          // right: 10%;
                          right: 0;
                          content: "";
                          position: absolute;
                          // transform: translateX(-40%);
                          width: 30px;
                          height: 30px;
                          border-radius: 5px;
                          backdrop-filter: blur(20px);
                          transition: 0.1s all ease-in-out;
                          top: 0px;
                          background-color: #323232;
                        }

                        &:checked::before {
                          background-color: #151515;
                        }
                      }
                    }
                  }

                  .organisations {
                    width: 100%;
                    margin-top: 10px;
                    opacity: 1;
                    transition: 0.1s all ease-in-out;

                    .option {
                      display: flex;
                      width: 100%;
                      align-items: center;
                      padding: 20px 0;
                      cursor: pointer;
                      border-radius: 50px;
                      transition: 0.2s all ease-in-out;

                      .label {
                        flex: 1;
                        font-size: 20px;
                        text-align: center;
                        color: #d3d3d3;
                      }

                      input {
                        margin-left: 50px;
                        appearance: none;
                        position: relative;
                        cursor: pointer;

                        &::before {
                          right: 10%;
                          content: "";
                          position: absolute;
                          transform: translateX(-100%);
                          width: 20px;
                          height: 20px;
                          border-radius: 50%;
                          background-color: white;
                          transition: 0.1s all ease-in-out;
                          top: -10px;
                        }

                        &:checked::before {
                          background-color: #2d2d2d;
                        }
                      }

                      &:hover {
                        background: #d3d3d3;

                        .label {
                          color: #2c2c2c;
                        }

                        input {
                          &::before {
                            background-color: #1c1c1c;
                          }

                          &:checked::before {
                            background-color: #2d2d2d;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            button {
              margin-top: 0;
              width: 500px;
              height: 100px;
              background: #1d1d1d;
              backdrop-filter: blur(25px);

              .text {
                color: #fff;
                font-size: 24px;
                font-weight: 700;
              }

              .icon-container {
                height: 100%;
                width: 80px;
                height: 80px;
                background: #2c2c2c;
                backdrop-filter: blur(25px);
              }
            }
          }
        }
      }

      &.home-selection {
        .home-inner {
          position: relative;

          .animation {
            width: 100px;
            pointer-events: none;
          }

          h1 {
            margin-top: 0;
            color: #f0f0f0;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 800;
            line-height: 50px;
            letter-spacing: -1.44px;

            &.sel-h1-mobile {
              display: none;
            }
          }

          .selection-modal {
            margin-top: 30px;
            width: 1000px;
            padding: 30px 50px;
            border-radius: 10px;
            background: rgba(27, 27, 27, 0.5);

            .section {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 0;
              border-bottom: 1px solid rgba(255, 255, 255, 0.3);

              &:first-child {
                padding-top: 0;
              }

              &.error-section {
                border-bottom: none;
              }

              p {
                color: red;
                font-size: 14px;
              }

              .section-title {
                color: #fff;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px; /* 208.333% */
                letter-spacing: -0.72px;
              }

              .section-purpose {
                color: #fff;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 50px; /* 208.333% */
                letter-spacing: -0.72px;
              }
            }

            .your-selection {
              padding-top: 10px;
              width: 100%;
              display: flex;

              h2 {
                color: #fff;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px; /* 208.333% */
                letter-spacing: -0.72px;
              }

              .tags {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                align-content: flex-start;
                gap: 8px;
                align-self: stretch;
                flex-wrap: wrap;

                .tag {
                  display: flex;
                  padding: 15px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  border-radius: var(--Radius-50, 50px);
                  background: linear-gradient(
                    90deg,
                    #6a5a38 0%,
                    #563e38 30%,
                    #515f61 70%,
                    #463b4c 100%
                  );
                  backdrop-filter: blur(25px);
                  color: #fff;
                  text-align: center;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
              }
            }
          }

          button {
            margin-top: 30px;

            &.selection-btn {
              width: 500px;
            }
          }
        }

        .img-bp {
          position: absolute;
          right: 0;
          top: 40%;
          transition: 0.2s opacity ease-in-out;
          transition-delay: 1;
          pointer-events: none;
          filter: invert(1) brightness(2);

          &.hide {
            opacity: 0;
            display: none;
          }
        }

        .img-bpp {
          position: absolute;
          top: 40%;
          right: 43%;
          transform: scale(2);
          opacity: 0;
          pointer-events: none;
          filter: invert(1) brightness(2);

          &.reveal {
            opacity: 1;
          }
        }
      }
    }
  }

  .home-main-mobile {
    display: none;
  }

  .code-sample {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    height: 100vh;
    object-fit: cover;
    object-position: left;
    width: 30%;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
    overflow-x: hidden;

    .event-none {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    .home-main {
      display: none;
    }

    .home-inner {
      .btn {
        margin-top: 30px;
        display: flex;
        width: 300px;
        height: 80px;
        padding: 20px 10px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-radius: 50px;
        background: #2c2c2c;
        position: relative;
        overflow: hidden;

        &.action-btn {
          background: rgba(240, 240, 240, 0.4);
          backdrop-filter: blur(25px);

          .text {
            color: #323232;
          }

          .icon-container {
            background: rgba(255, 255, 255, 0.54);
            backdrop-filter: blur(25px);
          }
        }
      }

      .text {
        flex: 1;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 900;
        position: relative;
        z-index: 1;
      }

      .icon-container {
        position: relative;
        z-index: 1;
        display: flex;
        width: 60px;
        height: 60px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 50px;
        background: var(--color-grey-Altblack, #323232);
      }

      .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform-origin: bottom;
        z-index: 0;
        border-radius: 50%;
      }
    }

    .home-main-mobile {
      display: block;
      position: relative;
      background: url("../svgs/home_background_black.svg");
      width: 100%;
      background-position: center;
      background-size: cover;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }

      .img-bg {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
      }

      .home-getStarted {
        position: relative;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .home-inner {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          padding: 0 20px;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          h1 {
            display: none;
            margin-top: 150px;
            color: #f0f0f0;
            text-wrap: wrap;
            font-size: 57px;
            font-style: normal;
            font-weight: 900;
            line-height: 56px; /* 100% */
            letter-spacing: -1.8px;
            z-index: 100;

            &.second {
              display: block;
            }

            .cursor {
              color: transparent;
              display: inline-block;
              background-color: #2c2c2c;
              width: 4px;
              margin-left: 2px;
              animation: blink 1s infinite;
            }

            /* Blinking effect */
            @keyframes blink {
              0% {
                opacity: 1;
              }
              50% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            }
          }

          .wrapper {
            z-index: 100;
            width: 100%;
            display: none;
            align-items: center;
            justify-content: space-between;

            &.second {
              display: flex;
              flex-direction: column;

              .stretch {
                margin-bottom: 120px;
                margin-top: 50px;
                width: 100%;
                display: flex;
                justify-content: flex-end;
              }
            }

            .content-a {
              margin-top: 30px;
              color: #f0f0f0;
              font-size: 18px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }

            .wrap {
              display: flex;

              p {
                color: #f0f0f0;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-right: 10px;
              }

              img {
                z-index: 100;
                width: 15px;
              }
            }
          }
        }
      }

      .home-b {
        position: relative;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .home-inner {
          margin-top: 160px;
          padding: 0 30px;

          h1 {
            color: var(--color-grey-Altblack, #323232);
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px; /* 93.75% */
            letter-spacing: -0.96px;
          }

          h4 {
            margin-top: 40px;
            color: var(--color-grey-Altblack, #323232);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .buttons-container {
            margin-top: 109px;
            margin-bottom: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .btn {
              margin-top: 0;
              height: 70px;
              width: 340px;
              margin-bottom: 10px;

              .icon-container {
                display: none;
              }

              .text {
                color: var(--Color-Primary-Black, #1b1b1b);
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
        }
      }

      .home-name {
        position: relative;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }
        .home-inner {
          padding: 0 30px;
          margin-top: 260px;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          h4 {
            margin-top: 0;
            color: #f0f0f0;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 25px; /* 78.125% */
            letter-spacing: -0.96px;
          }

          .form-container {
            width: 100%;
            margin-top: 45px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            input[type="text"] {
              margin-bottom: 35px;
              outline: none;
              margin-right: 0;
              color: #fff;
              width: 100%;
              height: 70px;
              padding: 10px 20px;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              border-radius: 50px;
              background: var(--color-grey-Altblack, #323232);
              box-shadow: 4px 4px 15px 5px #2c2c2c inset,
                -4px -4px 15px 5px rgba(255, 255, 255, 0.2) inset;
            }

            button {
              margin-top: 0;
              margin-bottom: 100px;
              width: 320px;
              height: 70px;
              background: #1d1d1d;
              backdrop-filter: blur(25px);

              .text {
                color: #f0f0f0;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .icon-container {
                display: none;
              }
            }
          }
        }
      }

      .home-org {
        .home-inner {
          .h4-org {
            display: none;

            &.org {
              display: block;
            }
          }

          .form-container {
            flex-direction: column;
            width: 100%;
            transition: 0.3s all ease-in-out;

            input[type="text"] {
              width: 100%;
              font-size: 16px;
            }

            button {
              width: 320px;
              margin-bottom: 200px;
            }

            .select-container {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 320px;
              padding: 20px 10px;
              padding-right: 5px;
              padding-top: 10px;
              cursor: pointer;
              border-radius: 50px;
              background: #1c1c1c;
              position: relative;
              overflow: hidden;
              margin: 10px 0;
              transform-origin: bottom;
              transition: 0.3s all ease-in-out;

              &.extend {
                height: 80px;

                .selected-content {
                  .icon-container {
                    transform: scaleY(1);
                  }
                }

                .organisations {
                  opacity: 0;
                }
              }

              &.shrink {
                height: 170px;

                .organisations {
                  opacity: 0;
                  display: none;
                }
              }

              .selected-org {
                margin-top: 10px;
                border-radius: 50px;
                background: linear-gradient(
                  90deg,
                  #6a5a38 0%,
                  #563e38 30%,
                  #515f61 70%,
                  #463b4c 100%
                );
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                border-radius: 50px;
                height: 60px;

                .ctn {
                  flex: 1;
                  text-align: center;
                  color: white;
                  font-size: 16px;
                  font-weight: 600;
                  transform: translateX(10%);
                }

                img {
                  width: 70px;
                  filter: invert(1) brightness(2);
                }
              }

              .selected-content {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .selected-text {
                  transform: translateX(3%);
                  flex: 1;
                  text-align: center;
                  color: #fff;
                  position: relative;
                  z-index: 1;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                }

                .icon-container {
                  position: relative;
                  z-index: 1;
                  display: flex;
                  width: 60px;
                  height: 60px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  flex-shrink: 0;
                  border-radius: 50px;
                  background: transparent;
                  transform: scaleY(-1);
                  transition: 0.3s transform ease-in-out;
                }
              }

              .organisations {
                width: 100%;
                margin-top: 10px;
                opacity: 1;
                transition: 0.1s all ease-in-out;

                .option {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  padding: 20px 0;
                  cursor: pointer;
                  border-radius: 50px;
                  transition: 0.2s all ease-in-out;

                  .label {
                    flex: 1;
                    font-size: 16px;
                    text-align: center;
                    color: #d3d3d3;
                  }

                  input {
                    margin-left: 50px;
                    appearance: none;
                    position: relative;
                    cursor: pointer;

                    &::before {
                      right: 10%;
                      content: "";
                      position: absolute;
                      transform: translateX(-150%);
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      background-color: white;
                      transition: 0.1s all ease-in-out;
                      top: -10px;
                    }

                    &:checked::before {
                      background-color: #2d2d2d;
                    }
                  }

                  &:hover {
                    background: #d3d3d3;

                    .label {
                      color: #2c2c2c;
                    }

                    input {
                      &::before {
                        background-color: #1c1c1c;
                      }

                      &:checked::before {
                        background-color: #2d2d2d;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .home-tell {
        position: relative;
        overflow-x: hidden;
        height: 100vh;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .home-inner {
          position: absolute;
          // left: 0;
          padding: 0 30px;
          margin-top: 200px;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          h4 {
            margin-top: 0;
            color: var(--color-grey-Altblack, #323232);
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px; /* 93.75% */
            letter-spacing: -0.96px;
            display: none;

            &.tell-h4-mobile {
              display: block;
            }
          }

          textarea {
            display: flex;
            width: 100%;
            height: 200px;
            padding: 40px 30px;
            align-items: flex-start;
            gap: 10px;
            outline: none;
            border: none;
            resize: none;
            border-radius: 50px;
            background: radial-gradient(
                256.3% 103.57% at 12.22% 89.9%,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(237, 237, 237, 0.2) 100%
              ),
              #fff;
            box-shadow: 4px 4px 15px 5px rgba(44, 44, 44, 0.1) inset,
              -4px -4px 15px 5px rgba(255, 255, 255, 0.1) inset;
            margin-top: 40px;
            color: #2c2c2c;
            font-family: inherit;
            font-size: 20px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
          }

          button {
            margin-top: 0;
            margin-top: 40px;
            margin-bottom: 100px;
            width: 330px;
            height: 70px;
            transform: translateX(2%);

            &.tell-btn {
              background: #1d1d1d;
              backdrop-filter: blur(25px);

              .text {
                color: #f0f0f0;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .icon-container {
                display: none;
              }
            }
          }
        }

        .img-bp {
          position: absolute;
          width: 70px;
          right: 0;
          top: 350px;
          transition: 0.2s opacity ease-in-out;
          transition-delay: 1;
          pointer-events: none;

          &.hide {
            opacity: 0;
            display: none;
          }
        }

        .img-bpp {
          position: absolute;
          top: 350px;
          right: 43%;
          width: 70px;
          transform: scale(1.8);
          opacity: 0;
          pointer-events: none;

          &.reveal {
            opacity: 1;
          }
        }
      }

      .home-service {
        position: relative;
        overflow-x: hidden;
        height: 100vh;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .home-inner {
          margin-top: 160px;
          position: absolute;
          padding: 0 20px;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          h1 {
            color: var(--color-grey-Altblack, #323232);
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px; /* 93.75% */
            letter-spacing: -0.96px;

            &.service-h1 {
              display: none;
            }

            &.service-h1-mobile {
              display: block;
            }
          }

          .ctn-wrapper {
            display: flex;
            transform: translateX(11%);
            align-items: center;
            flex-direction: column;

            .content {
              width: 100%;
              flex: 1;
              margin-right: 70px;

              h4 {
                margin-top: 45px;
                color: #2c2c2c;
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: block;
              }

              p {
                margin-top: 25px;
                color: #2c2c2c;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .ctn-p {
                display: none;
              }

              .ctn-mobile-p {
                display: block;
              }
            }

            .btn {
              margin-top: 0;
              display: flex;
              display: none;
              width: 100px;
              height: 150px;
              padding: 0px 10px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              pointer-events: none;
              overflow: hidden;
              opacity: 0;
              transition: 0.2s opacity ease-in-out;
              transition-delay: 2.5s;

              &.btn-fade {
                opacity: 1;
                pointer-events: all;
              }

              &.action-btn {
                border-radius: 300px;
                background: #2c2c2c;
                backdrop-filter: blur(25px);
              }

              img {
                width: 80px;
                height: 80px;
                z-index: 10;
                color: #37bb9ed3;
              }
            }
          }

          .service-cards {
            width: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 25px;
            padding-bottom: 0;
            flex-direction: column;

            .service-card {
              cursor: pointer;
              display: flex;
              width: 100%;
              padding: 30px;
              align-items: center;
              gap: 15px;
              border-radius: 50px;
              overflow: hidden;
              background: radial-gradient(
                225.11% 98.34% at 90.75% 93.53%,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(153, 153, 153, 0.1) 100%
              );
              backdrop-filter: blur(25px);
              position: relative;
              z-index: 1;
              height: 450px;
              transition: 0.2s all ease-in-out;

              &:hover {
                transform: scale(0.99);
              }

              @media screen and (max-height: 1535px) and (min-height: 1344px) {
                height: 450px;
              }

              // If the screen height is below 14 inches (1344px)
              @media screen and (max-height: 1343px) {
                height: 450px;
              }

              .bg-card {
                position: absolute;
                top: 0;
                background: #2c2c2c;
                width: 100%;
                height: 100%;

                // &.bg-rg {
                //   right: 0;
                //   transform-origin: right;
                // }
              }

              .scope {
                width: 70px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .scope-title {
                  transform: rotate(-90deg);
                  color: #2c2c2c;
                  font-size: 80px;
                  font-weight: 700;
                  transition: 2s ease-in-out;
                }
              }

              .work {
                width: 50%;
                height: 75%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                z-index: 1;

                p {
                  color: #2c2c2c;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-align: start;
                  transition: 2s ease-in-out;

                  &:first-child {
                    padding-top: 5px;
                  }

                  &:last-child {
                    padding-bottom: 0;
                  }
                }
              }

              img {
                display: flex;
                width: 60px;
                height: 60px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                z-index: 1;
                transition: 2s ease-in-out;
              }

              &.filled {
                .scope {
                  .scope-title {
                    color: #ffffff;
                  }
                }

                .work {
                  p {
                    color: #ffffff;
                  }
                }

                img {
                  filter: invert(1) brightness(2);
                }
              }

              &.snd {
                margin-top: 20px;
                margin-bottom: 40px;
              }
            }

            .fusion {
              display: none;
            }
          }

          .btn-mobile-proceed-container {
            width: 100%;
            padding-bottom: 250px;
            display: flex;
            justify-content: flex-end;

            .btn-mobile-proceed {
              display: flex;
              width: 100px;
              height: 150px;
              padding: 0px 10px;
              align-items: center;
              justify-content: space-between;
              align-items: center;
              border-radius: 300px;
              background: #2c2c2c;
              backdrop-filter: blur(25px);

              img {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }

      .home-device {
        position: relative;

        &::-webkit-scrollbar {
          display: none;
        }

        &::-webkit-scrollbar-thumb {
          display: none;
        }

        .home-inner {
          position: absolute;
          // left: 0;
          padding: 0 20px;
          margin-top: 160px;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          h4 {
            display: block;
            margin: 0;
            color: var(--color-grey-Altblack, #323232);
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px; /* 93.75% */
            letter-spacing: -0.96px;
          }

          .cards {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            display: none;
            margin-top: 40px;

            &.cards-mobile-repo {
              display: flex;

              .card-wrap {
                display: flex;
                align-items: center;
                width: 100%;

                .card {
                  margin-top: 0;
                  &:first-child {
                    margin-right: 10px;
                  }
                }
              }
            }

            .card {
              display: flex;
              margin-top: 10px;
              width: 100%;
              height: 180px;
              padding: 0px 10px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 20px;
              align-self: stretch;
              border-radius: 50px;
              background: radial-gradient(
                256.3% 103.57% at 12.22% 89.9%,
                rgba(255, 255, 255, 0.2) 0%,
                rgba(153, 153, 153, 0.2) 100%
              );
              backdrop-filter: blur(25px);
              margin-right: 0;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              transition: 0.2s all ease-in-out;

              &:hover {
                transform: scale(0.95);
              }

              &.filled {
                p {
                  color: white;
                }

                img {
                  filter: invert(1) brightness(2);
                }
              }

              .bg-cd {
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 50%;
                background: #2c2c2c;
                width: 100%;
                height: 100%;
                pointer-events: none;

                // &.bg-rg {
                //   right: 0;
                //   transform-origin: right;
                // }
              }

              img {
                width: 50px;
                height: 50px;
                z-index: 1;
                transition: 1s all ease-in-out;
              }

              p {
                margin: 0;
                z-index: 1;
                color: #1d1d1d;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                transition: 1s all ease-in-out;
              }
            }
          }

          button {
            margin-top: 40px;
            margin-bottom: 300px;
            border-radius: 50px;
            backdrop-filter: blur(25px);
            width: 350px;
            height: 70px;

            &.device-btn {
              background: #1d1d1d;

              .text {
                color: #f0f0f0;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .icon-container {
                display: none;
              }
            }
          }
        }
      }

      .home-features {
        position: relative;

        .home-inner {
          margin-top: 160px;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          .feature-h4 {
            display: none;

            &.feature-h4-mobile {
              display: block;
            }
          }

          .form-container {
            margin-top: 30px;

            .container {
              width: 100%;
              // max-height: 400px;
              overflow-y: auto;
              overscroll-behavior-y: none;
              scroll-behavior: smooth;
              margin-bottom: 30px;
              padding-right: 0;

              &::-webkit-scrollbar {
                width: 5px;
                border-radius: 10px;
                background: #2e2e32;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: linear-gradient(
                  180deg,
                  rgba(81, 81, 81, 0) 0%,
                  rgba(255, 255, 255, 0.2) 100%
                );
              }

              .service-container {
                display: flex;
                width: 100%;
                // align-items: center;
                flex-direction: column;

                .select-container {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  height: 350px;
                  padding: 20px 10px;
                  padding-top: 10px;
                  cursor: pointer;
                  border-radius: var(--Radius-50, 50px);
                  background: rgba(27, 27, 27, 0.6);
                  backdrop-filter: blur(25px);
                  position: relative;
                  overflow: hidden;
                  // margin: 10px 0;
                  margin: 0;
                  margin-bottom: 5px;
                  transform-origin: bottom;
                  transition: 0.3s all ease-in-out;

                  &.brand {
                    height: 230px;
                  }

                  &.extend {
                    height: 70px;

                    .selected-content {
                      .icon-container {
                        transform: scaleY(1);
                      }
                    }

                    .organisations {
                      opacity: 0;
                    }
                  }

                  &.shrink {
                    height: 170px;

                    .organisations {
                      opacity: 0;
                      display: none;
                    }
                  }

                  .selected-org {
                    margin-top: 10px;
                    border-radius: 50px;
                    background: linear-gradient(
                      90deg,
                      #6a5a38 0%,
                      #563e38 30%,
                      #515f61 70%,
                      #463b4c 100%
                    );
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    border-radius: 50px;
                    height: 60px;

                    .ctn {
                      flex: 1;
                      text-align: center;
                      color: white;
                      font-size: 16px;
                      font-weight: 600;
                      transform: translateX(10%);
                    }

                    img {
                      width: 70px;
                      filter: invert(1) brightness(2);
                    }
                  }

                  .selected-content {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                    transform: translateY(-10%);

                    .selected-text {
                      margin-left: 16px;
                      // transform: translateX(3%);
                      flex: 1;
                      text-align: start;
                      color: #fff;
                      position: relative;
                      z-index: 1;
                      font-size: 16px;
                      font-weight: 500;
                    }

                    .icon-container {
                      position: relative;
                      z-index: 1;
                      display: flex;
                      width: 60px;
                      height: 60px;
                      justify-content: center;
                      align-items: center;
                      gap: 10px;
                      flex-shrink: 0;
                      border-radius: 50px;
                      background: transparent;
                      transform: scaleY(-1);
                      transition: 0.3s transform ease-in-out;
                    }
                  }

                  .organisations {
                    width: 100%;
                    margin-top: 10px;
                    opacity: 1;
                    transition: 0.1s all ease-in-out;

                    .option {
                      display: flex;
                      width: 100%;
                      align-items: center;
                      padding: 20px 0;
                      cursor: pointer;
                      border-radius: 50px;
                      transition: 0.2s all ease-in-out;

                      .label {
                        flex: 1;
                        font-size: 16px;
                        text-align: center;
                        color: #d3d3d3;
                      }

                      input {
                        margin-left: 50px;
                        appearance: none;
                        position: relative;
                        cursor: pointer;

                        &::before {
                          right: 10%;
                          content: "";
                          position: absolute;
                          transform: translateX(-150%);
                          width: 20px;
                          height: 20px;
                          border-radius: 50%;
                          background-color: white;
                          transition: 0.1s all ease-in-out;
                          top: -10px;
                        }

                        &:checked::before {
                          background-color: #2d2d2d;
                        }
                      }

                      &:hover {
                        background: #d3d3d3;

                        .label {
                          color: #2c2c2c;
                        }

                        input {
                          &::before {
                            background-color: #1c1c1c;
                          }

                          &:checked::before {
                            background-color: #2d2d2d;
                          }
                        }
                      }
                    }
                  }
                }

                .ft-container {
                  width: 100%;

                  .ft {
                    width: 100%;
                    display: flex;
                    height: 70px;
                    padding: 0px 10px;
                    justify-content: flex-start;
                    align-items: center;
                    flex-shrink: 0;
                    align-self: stretch;
                    border-radius: 50px;
                    border-radius: var(--Radius-50, 50px);
                    background: rgba(27, 27, 27, 0.6);
                    backdrop-filter: blur(25px);
                    margin-bottom: 5px;
                    cursor: pointer;
                    position: relative;

                    .ft-text {
                      flex: 1;
                      margin-left: 20px;
                      color: #fff;
                      text-align: start;
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      transform: translateX(1%);
                    }

                    .checkbox-container {
                      position: absolute;
                      right: 20px;
                      width: 30px;
                      height: 30px;

                      .checkmark {
                        color: white;
                        font-size: 20px;
                        position: absolute;
                        z-index: 1;
                        top: 50%;
                        right: 0%;
                        transform: translate(-25%, -50%);
                      }

                      input {
                        appearance: none;
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;

                        &::before {
                          // right: 10%;
                          right: 0;
                          content: "";
                          position: absolute;
                          // transform: translateX(-40%);
                          width: 30px;
                          height: 30px;
                          border-radius: 5px;
                          backdrop-filter: blur(20px);
                          transition: 0.1s all ease-in-out;
                          top: 0px;
                          background-color: #323232;
                        }

                        &:checked::before {
                          background-color: #151515;
                        }
                      }
                    }
                  }

                  .organisations {
                    width: 100%;
                    margin-top: 10px;
                    opacity: 1;
                    transition: 0.1s all ease-in-out;

                    .option {
                      display: flex;
                      width: 100%;
                      align-items: center;
                      padding: 20px 0;
                      cursor: pointer;
                      border-radius: 50px;
                      transition: 0.2s all ease-in-out;

                      .label {
                        flex: 1;
                        font-size: 20px;
                        text-align: center;
                        color: #d3d3d3;
                      }

                      input {
                        margin-left: 50px;
                        appearance: none;
                        position: relative;
                        cursor: pointer;

                        &::before {
                          right: 10%;
                          content: "";
                          position: absolute;
                          transform: translateX(-100%);
                          width: 20px;
                          height: 20px;
                          border-radius: 50%;
                          background-color: white;
                          transition: 0.1s all ease-in-out;
                          top: -10px;
                        }

                        &:checked::before {
                          background-color: #2d2d2d;
                        }
                      }

                      &:hover {
                        background: #d3d3d3;

                        .label {
                          color: #2c2c2c;
                        }

                        input {
                          &::before {
                            background-color: #1c1c1c;
                          }

                          &:checked::before {
                            background-color: #2d2d2d;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            button {
              margin-top: 0;
              width: 350px;
              height: 70px;
              background: #1d1d1d;
              backdrop-filter: blur(25px);

              .text {
                color: #f0f0f0;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .icon-container {
                display: none;
              }
            }
          }
        }
      }

      .home-selection {
        .home-inner {
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }

          h1 {
            margin-top: 0;
            color: #f0f0f0;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 30px; /* 93.75% */
            letter-spacing: -0.96px;
            display: none;

            &.sel-h1-mobile {
              display: block;
            }
          }

          .selection-modal {
            margin-top: 30px;
            width: 100%;
            padding: 30px 20px;
            border-radius: 10px;
            background: rgba(27, 27, 27, 0.5);

            .section {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 0;
              border-bottom: 1px solid rgba(255, 255, 255, 0.3);

              &:first-child {
                padding-top: 0;
              }

              .section-title {
                color: #fff;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px; /* 416.667% */
                letter-spacing: -0.36px;
              }

              .section-purpose {
                color: #fff;
                text-align: center;
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 800;
                line-height: 50px; /* 357.143% */
                letter-spacing: -0.42px;
              }
            }

            .your-selection {
              padding-top: 10px;
              width: 100%;
              display: flex;

              h2 {
                color: #fff;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 50px; /* 416.667% */
                letter-spacing: -0.36px;
              }

              .tags {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                align-content: flex-start;
                gap: 8px;
                align-self: stretch;
                flex-wrap: wrap;

                .tag {
                  display: flex;
                  padding: 15px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  border-radius: 10px;
                  background: linear-gradient(
                    90deg,
                    #6a5a38 0%,
                    #563e38 30%,
                    #515f61 70%,
                    #463b4c 100%
                  );
                  backdrop-filter: blur(25px);
                  color: #fff;
                  text-align: center;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  height: 40px;
                  padding: 20px 10px;
                }
              }
            }
          }

          button {
            margin-top: 30px;

            &.selection-btn {
              width: 350px;
            }
          }
        }
        .img-bp {
          position: absolute;
          width: 70px;
          right: 0;
          top: 350px;
          transition: 0.2s opacity ease-in-out;
          transition-delay: 1;
          pointer-events: none;
          filter: invert(1) brightness(2);

          &.hide {
            opacity: 0;
            display: none;
          }
        }

        .img-bpp {
          position: absolute;
          top: 350px;
          right: 43%;
          width: 70px;
          transform: scale(1.8);
          opacity: 0;
          pointer-events: none;
          filter: invert(1) brightness(2);

          &.reveal {
            opacity: 1;
          }
        }
      }
    }
  }

  @media (min-width: 576px) and (max-width: 768px) {
    .home-main {
      .home-content {
        transform: translateX(0);

        &.home-getStarted {
          transform: translateX(5%);

          .home-inner {
            width: 80%;

            h1 {
              font-size: 80px;
              line-height: 100px;
            }

            .wrapper {
              width: 100%;

              .content-a {
                margin-top: 87px;
                font-size: 30px;
              }
            }
          }
        }

        &.home-b {
          transform: translateX(5%);
        }

        &.home-name {
          .home-inner {
            .form-container {
              flex-direction: column;

              input[type="text"] {
                margin-bottom: 30px;
              }
            }
          }
        }

        &.home-device {
          transform: translateX(5%);
          .home-inner {
            h4 {
              margin: 0;
              color: #1d1d1d;
              text-align: center;
              font-size: 48px;
              font-weight: 800;
              letter-spacing: -1.44px;
            }

            .cards {
              display: flex;
              align-items: center;
              justify-content: center;
              display: none;

              &.cards-mobile-repo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .card-wrap {
                  display: flex;
                }
              }

              .card {
                display: flex;
                margin-top: 20px;
                width: 300px;
                height: 300px;
                padding: 0px 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                align-self: stretch;
                border-radius: 50px;
                background: radial-gradient(
                  256.3% 103.57% at 12.22% 89.9%,
                  rgba(255, 255, 255, 0.2) 0%,
                  rgba(153, 153, 153, 0.2) 100%
                );
                backdrop-filter: blur(25px);
                margin-right: 20px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                transition: 0.2s all ease-in-out;

                &:hover {
                  transform: scale(0.95);
                }

                &.filled {
                  p {
                    color: white;
                  }

                  img {
                    filter: invert(1) brightness(2);
                  }
                }

                .bg-cd {
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-radius: 50%;
                  background: #2c2c2c;
                  width: 100%;
                  height: 100%;
                  pointer-events: none;

                  // &.bg-rg {
                  //   right: 0;
                  //   transform-origin: right;
                  // }
                }

                img {
                  z-index: 1;
                  transition: 1s all ease-in-out;
                }

                p {
                  margin: 0;
                  z-index: 1;
                  color: #1d1d1d;
                  text-align: center;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  transition: 1s all ease-in-out;
                }
              }
            }

            button {
              margin-top: 90px;
              border-radius: 50px;
              backdrop-filter: blur(25px);
              width: 600px;

              &.device-btn {
                background: #1d1d1d;

                .text {
                  color: #fff;
                  text-align: center;
                  font-size: 24px;
                  font-weight: 700;
                }

                .icon-container {
                  border-radius: 60px;
                  background: #2c2c2c;
                  backdrop-filter: blur(25px);
                }
              }
            }
          }
        }

        &.home-tell {
          position: relative;
          transform: translateX(4%);
        }

        &.home-features {
          position: relative;

          .home-inner {
            h4.feature-h4-mobile {
              display: none;
            }

            .form-container {
              margin-top: 30px;

              .container {
                width: 70%;
              }
            }
          }
        }

        &.home-service {
          transform: translateX(5%);
          .home-inner {
            height: 100%;
            width: 90%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
              padding-top: 200px;
              color: #2c2c2c;
              text-align: center;
              font-size: 64px;
              font-weight: 800;

              &.service-h1-mobile {
                display: none;
              }
            }

            .ctn-wrapper {
              display: flex;
              transform: translateX(-9%);
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;

              .content {
                margin: 0 auto;
                width: 80%;
                flex: 0.5;
                margin-right: 30px;

                h4 {
                  margin-top: 30px;
                  color: #2c2c2c;
                  font-size: 32px;
                  font-weight: 400;
                }

                p {
                  margin-top: 10px;
                  color: #2c2c2c;
                  font-size: 20px;
                  font-weight: 400;

                  &.ctn-mobile-p {
                    display: none;
                  }
                }
              }

              .btn {
                margin-top: 0;
                display: flex;
                width: 100px;
                height: 150px;
                padding: 0px 10px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                pointer-events: none;
                overflow: hidden;
                opacity: 0;
                transition: 0.2s opacity ease-in-out;
                transition-delay: 2.5s;

                &.btn-fade {
                  opacity: 1;
                  pointer-events: all;
                }

                &.action-btn {
                  border-radius: 300px;
                  background: #2c2c2c;
                  backdrop-filter: blur(25px);
                }

                img {
                  width: 80px;
                  height: 80px;
                  z-index: 10;
                  color: #37bb9ed3;
                }
              }
            }

            .service-cards {
              width: 100%;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              // flex-direction: column;
              // padding: 0 20px;
              margin-top: 0px;
              padding-bottom: 30px;

              .service-card {
                cursor: pointer;
                display: flex;
                width: 350px;
                padding: 20px 0;
                align-items: center;
                gap: 15px;
                border-radius: 50px;
                overflow: hidden;
                background: radial-gradient(
                  225.11% 98.34% at 90.75% 93.53%,
                  rgba(255, 255, 255, 0.1) 0%,
                  rgba(153, 153, 153, 0.1) 100%
                );
                backdrop-filter: blur(25px);
                position: relative;
                z-index: 1;
                height: 350px;
                transition: 0.2s all ease-in-out;

                &:first-child {
                  margin-right: 20px;
                }

                &:hover {
                  transform: scale(0.99);
                }

                @media screen and (max-height: 1535px) and (min-height: 1344px) {
                  height: 400px;
                }

                // If the screen height is below 14 inches (1344px)
                @media screen and (max-height: 1343px) {
                  height: 400px;
                }

                .bg-card {
                  position: absolute;
                  top: 0;
                  background: #2c2c2c;
                  width: 100%;
                  height: 100%;

                  // &.bg-rg {
                  //   right: 0;
                  //   transform-origin: right;
                  // }
                }

                .scope {
                  width: 103px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .scope-title {
                    transform: rotate(-90deg);
                    color: #2c2c2c;
                    font-size: 80px;
                    font-weight: 700;
                    transition: 2s ease-in-out;
                  }
                }

                .work {
                  width: 40%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  z-index: 1;

                  p {
                    color: #2c2c2c;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: start;
                    transition: 2s ease-in-out;

                    &:first-child {
                      padding-top: 5px;
                    }

                    &:last-child {
                      padding-bottom: 30px;
                    }
                  }
                }

                img {
                  display: flex;
                  width: 60px;
                  height: 60px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  flex-shrink: 0;
                  z-index: 1;
                  transition: 2s ease-in-out;
                }

                &.filled {
                  .scope {
                    .scope-title {
                      color: #ffffff;
                    }
                  }

                  .work {
                    p {
                      color: #ffffff;
                    }
                  }

                  img {
                    filter: invert(1) brightness(2);
                  }
                }

                &.snd {
                  flex-direction: row-reverse;

                  .work {
                    p {
                      text-align: end;
                    }
                  }
                }
              }

              .fusion {
                display: none;

                .left,
                .right {
                  flex: 1;
                  display: flex;
                  position: relative;
                  cursor: pointer;

                  .bar {
                    flex: 1;
                    height: 6px;
                    border-radius: 10px;
                    z-index: 1;
                    background: #f1f1f1;

                    &.lb {
                      margin: 0 2px;
                    }

                    &.rb {
                      margin: 0 2px;
                    }
                  }
                }

                .bp {
                  width: 40px;
                  height: 40px;
                  opacity: 0.2;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }

            .btn-mobile-proceed-container {
              display: none;
            }
          }
        }

        &.home-selection {
          .home-inner {
            h1 {
              margin-top: 0;
              color: #f0f0f0;
              text-align: center;
              font-size: 40px;
              font-style: normal;
              font-weight: 800;
              line-height: 46px;
              letter-spacing: -1.44px;

              &.sel-h1-mobile {
                display: none;
              }
            }

            .selection-modal {
              margin-top: 30px;
              width: 100%;
              padding: 30px 50px;
              border-radius: 10px;
              background: rgba(27, 27, 27, 0.5);

              .section {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                &:first-child {
                  padding-top: 0;
                }

                .section-title {
                  color: #fff;
                  text-align: center;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 50px; /* 208.333% */
                  letter-spacing: -0.72px;
                }

                .section-purpose {
                  color: #fff;
                  text-align: center;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 50px; /* 208.333% */
                  letter-spacing: -0.72px;
                }
              }

              .your-selection {
                padding-top: 10px;
                width: 100%;
                display: flex;

                h2 {
                  color: #fff;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 50px; /* 208.333% */
                  letter-spacing: -0.72px;
                }

                .tags {
                  flex: 1;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-start;
                  align-content: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex-wrap: wrap;

                  .tag {
                    display: flex;
                    padding: 15px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: var(--Radius-50, 50px);
                    background: linear-gradient(
                      90deg,
                      #6a5a38 0%,
                      #563e38 30%,
                      #515f61 70%,
                      #463b4c 100%
                    );
                    backdrop-filter: blur(25px);
                    color: #fff;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }
                }
              }
            }

            button {
              margin-top: 30px;

              &.selection-btn {
                width: 500px;
              }
            }
          }

          .img-bp {
            position: absolute;
            right: 0;
            top: 40%;
            transition: 0.2s opacity ease-in-out;
            transition-delay: 1;
            pointer-events: none;
            filter: invert(1) brightness(2);

            &.hide {
              opacity: 0;
              display: none;
            }
          }

          .img-bpp {
            position: absolute;
            top: 40%;
            right: 43%;
            transform: scale(2);
            opacity: 0;
            pointer-events: none;
            filter: invert(1) brightness(2);

            &.reveal {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .home-main {
      .home-content {
        transform: translateX(0);

        &.home-getStarted {
          transform: translateX(5%);

          .home-inner {
            width: 80%;

            h1 {
              font-size: 100px;
              line-height: 100px; /* 80% */
            }

            .wrapper {
              width: 100%;

              .content-a {
                margin-top: 87px;
                font-size: 30px;
              }
            }
          }
        }

        &.home-b {
          transform: translateX(5%);
        }

        &.home-name {
          .home-inner {
            .form-container {
              flex-direction: column;

              input[type="text"] {
                margin-bottom: 30px;
              }
            }
          }
        }

        &.home-device {
          transform: translateX(5%);
          .home-inner {
            h4 {
              margin: 0;
              color: #1d1d1d;
              text-align: center;
              font-size: 48px;
              font-weight: 800;
              letter-spacing: -1.44px;
            }

            .cards {
              display: flex;
              align-items: center;
              justify-content: center;
              display: none;

              &.cards-mobile-repo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .card-wrap {
                  display: flex;
                }
              }

              .card {
                display: flex;
                margin-top: 20px;
                width: 300px;
                height: 300px;
                padding: 0px 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                align-self: stretch;
                border-radius: 50px;
                background: radial-gradient(
                  256.3% 103.57% at 12.22% 89.9%,
                  rgba(255, 255, 255, 0.2) 0%,
                  rgba(153, 153, 153, 0.2) 100%
                );
                backdrop-filter: blur(25px);
                margin-right: 20px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                transition: 0.2s all ease-in-out;

                &:hover {
                  transform: scale(0.95);
                }

                &.filled {
                  p {
                    color: white;
                  }

                  img {
                    filter: invert(1) brightness(2);
                  }
                }

                .bg-cd {
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-radius: 50%;
                  background: #2c2c2c;
                  width: 100%;
                  height: 100%;
                  pointer-events: none;

                  // &.bg-rg {
                  //   right: 0;
                  //   transform-origin: right;
                  // }
                }

                img {
                  z-index: 1;
                  transition: 1s all ease-in-out;
                }

                p {
                  margin: 0;
                  z-index: 1;
                  color: #1d1d1d;
                  text-align: center;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  transition: 1s all ease-in-out;
                }
              }
            }

            button {
              margin-top: 90px;
              border-radius: 50px;
              backdrop-filter: blur(25px);
              width: 600px;

              &.device-btn {
                background: #1d1d1d;

                .text {
                  color: #fff;
                  text-align: center;
                  font-size: 24px;
                  font-weight: 700;
                }

                .icon-container {
                  border-radius: 60px;
                  background: #2c2c2c;
                  backdrop-filter: blur(25px);
                }
              }
            }
          }
        }

        &.home-tell {
          position: relative;
          transform: translateX(4%);
        }

        &.home-features {
          position: relative;

          .home-inner {
            h4.feature-h4-mobile {
              display: none;
            }

            .form-container {
              margin-top: 30px;

              .container {
                width: 70%;
              }
            }
          }
        }

        &.home-service {
          transform: translateX(5%);
          .home-inner {
            height: 100%;
            width: 90%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
              padding-top: 200px;
              color: #2c2c2c;
              text-align: center;
              font-size: 64px;
              font-weight: 800;

              &.service-h1-mobile {
                display: none;
              }
            }

            .ctn-wrapper {
              display: flex;
              transform: translateX(-9%);
              flex-direction: column;
              justify-content: center;
              align-items: flex-end;

              .content {
                margin: 0 auto;
                width: 80%;
                flex: 0.5;
                margin-right: 30px;

                h4 {
                  margin-top: 30px;
                  color: #2c2c2c;
                  font-size: 32px;
                  font-weight: 400;
                }

                p {
                  margin-top: 10px;
                  color: #2c2c2c;
                  font-size: 20px;
                  font-weight: 400;

                  &.ctn-mobile-p {
                    display: none;
                  }
                }
              }

              .btn {
                margin-top: 0;
                display: flex;
                width: 100px;
                height: 150px;
                padding: 0px 10px;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                pointer-events: none;
                overflow: hidden;
                opacity: 0;
                transition: 0.2s opacity ease-in-out;
                transition-delay: 2.5s;

                &.btn-fade {
                  opacity: 1;
                  pointer-events: all;
                }

                &.action-btn {
                  border-radius: 300px;
                  background: #2c2c2c;
                  backdrop-filter: blur(25px);
                }

                img {
                  width: 80px;
                  height: 80px;
                  z-index: 10;
                  color: #37bb9ed3;
                }
              }
            }

            .service-cards {
              width: 100%;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              // flex-direction: column;
              // padding: 0 20px;
              margin-top: 0px;
              padding-bottom: 30px;

              .service-card {
                cursor: pointer;
                display: flex;
                width: 350px;
                padding: 20px 0;
                align-items: center;
                gap: 15px;
                border-radius: 50px;
                overflow: hidden;
                background: radial-gradient(
                  225.11% 98.34% at 90.75% 93.53%,
                  rgba(255, 255, 255, 0.1) 0%,
                  rgba(153, 153, 153, 0.1) 100%
                );
                backdrop-filter: blur(25px);
                position: relative;
                z-index: 1;
                height: 350px;
                transition: 0.2s all ease-in-out;

                &:first-child {
                  margin-right: 20px;
                }

                &:hover {
                  transform: scale(0.99);
                }

                @media screen and (max-height: 1535px) and (min-height: 1344px) {
                  height: 400px;
                }

                // If the screen height is below 14 inches (1344px)
                @media screen and (max-height: 1343px) {
                  height: 400px;
                }

                .bg-card {
                  position: absolute;
                  top: 0;
                  background: #2c2c2c;
                  width: 100%;
                  height: 100%;

                  // &.bg-rg {
                  //   right: 0;
                  //   transform-origin: right;
                  // }
                }

                .scope {
                  width: 103px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .scope-title {
                    transform: rotate(-90deg);
                    color: #2c2c2c;
                    font-size: 80px;
                    font-weight: 700;
                    transition: 2s ease-in-out;
                  }
                }

                .work {
                  width: 40%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  z-index: 1;

                  p {
                    color: #2c2c2c;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: start;
                    transition: 2s ease-in-out;

                    &:first-child {
                      padding-top: 5px;
                    }

                    &:last-child {
                      padding-bottom: 30px;
                    }
                  }
                }

                img {
                  display: flex;
                  width: 60px;
                  height: 60px;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  flex-shrink: 0;
                  z-index: 1;
                  transition: 2s ease-in-out;
                }

                &.filled {
                  .scope {
                    .scope-title {
                      color: #ffffff;
                    }
                  }

                  .work {
                    p {
                      color: #ffffff;
                    }
                  }

                  img {
                    filter: invert(1) brightness(2);
                  }
                }

                &.snd {
                  flex-direction: row-reverse;

                  .work {
                    p {
                      text-align: end;
                    }
                  }
                }
              }

              .fusion {
                display: none;

                .left,
                .right {
                  flex: 1;
                  display: flex;
                  position: relative;
                  cursor: pointer;

                  .bar {
                    flex: 1;
                    height: 6px;
                    border-radius: 10px;
                    z-index: 1;
                    background: #f1f1f1;

                    &.lb {
                      margin: 0 2px;
                    }

                    &.rb {
                      margin: 0 2px;
                    }
                  }
                }

                .bp {
                  width: 40px;
                  height: 40px;
                  opacity: 0.2;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }

            .btn-mobile-proceed-container {
              display: none;
            }
          }
        }

        &.home-selection {
          .home-inner {
            h1 {
              margin-top: 0;
              color: #f0f0f0;
              text-align: center;
              font-size: 40px;
              font-style: normal;
              font-weight: 800;
              line-height: 46px;
              letter-spacing: -1.44px;

              &.sel-h1-mobile {
                display: none;
              }
            }

            .selection-modal {
              margin-top: 30px;
              width: 100%;
              padding: 30px 50px;
              border-radius: 10px;
              background: rgba(27, 27, 27, 0.5);

              .section {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);

                &:first-child {
                  padding-top: 0;
                }

                .section-title {
                  color: #fff;
                  text-align: center;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 50px; /* 208.333% */
                  letter-spacing: -0.72px;
                }

                .section-purpose {
                  color: #fff;
                  text-align: center;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 50px; /* 208.333% */
                  letter-spacing: -0.72px;
                }
              }

              .your-selection {
                padding-top: 10px;
                width: 100%;
                display: flex;

                h2 {
                  color: #fff;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 50px; /* 208.333% */
                  letter-spacing: -0.72px;
                }

                .tags {
                  flex: 1;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-start;
                  align-content: flex-start;
                  gap: 8px;
                  align-self: stretch;
                  flex-wrap: wrap;

                  .tag {
                    display: flex;
                    padding: 15px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: var(--Radius-50, 50px);
                    background: linear-gradient(
                      90deg,
                      #6a5a38 0%,
                      #563e38 30%,
                      #515f61 70%,
                      #463b4c 100%
                    );
                    backdrop-filter: blur(25px);
                    color: #fff;
                    text-align: center;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }
                }
              }
            }

            button {
              margin-top: 30px;

              &.selection-btn {
                width: 500px;
              }
            }
          }

          .img-bp {
            position: absolute;
            right: 0;
            top: 40%;
            transition: 0.2s opacity ease-in-out;
            transition-delay: 1;
            pointer-events: none;
            filter: invert(1) brightness(2);

            &.hide {
              opacity: 0;
              display: none;
            }
          }

          .img-bpp {
            position: absolute;
            top: 40%;
            right: 43%;
            transform: scale(2);
            opacity: 0;
            pointer-events: none;
            filter: invert(1) brightness(2);

            &.reveal {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    .home-main {
      .home-content {
        transform: translateX(0);

        &.home-b {
          transform: translateX(5%);
        }

        &.home-device {
          transform: translateX(5%);
          .home-inner {
            h4 {
              margin: 0;
              color: #1d1d1d;
              text-align: center;
              font-size: 48px;
              font-weight: 800;
              letter-spacing: -1.44px;
            }

            .cards {
              display: flex;
              align-items: center;
              justify-content: center;

              &.cards-mobile-repo {
                display: none;
              }

              .card {
                display: flex;
                margin-top: 70px;
                width: 300px;
                height: 300px;
                padding: 0px 10px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                align-self: stretch;
                border-radius: 50px;
                background: radial-gradient(
                  256.3% 103.57% at 12.22% 89.9%,
                  rgba(255, 255, 255, 0.2) 0%,
                  rgba(153, 153, 153, 0.2) 100%
                );
                backdrop-filter: blur(25px);
                margin-right: 20px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                transition: 0.2s all ease-in-out;

                &:hover {
                  transform: scale(0.95);
                }

                &.filled {
                  p {
                    color: white;
                  }

                  img {
                    filter: invert(1) brightness(2);
                  }
                }

                .bg-cd {
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-radius: 50%;
                  background: #2c2c2c;
                  width: 100%;
                  height: 100%;
                  pointer-events: none;

                  // &.bg-rg {
                  //   right: 0;
                  //   transform-origin: right;
                  // }
                }

                img {
                  z-index: 1;
                  transition: 1s all ease-in-out;
                }

                p {
                  margin: 0;
                  z-index: 1;
                  color: #1d1d1d;
                  text-align: center;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  transition: 1s all ease-in-out;
                }
              }
            }

            button {
              margin-top: 90px;
              border-radius: 50px;
              backdrop-filter: blur(25px);
              width: 600px;

              &.device-btn {
                background: #1d1d1d;

                .text {
                  color: #fff;
                  text-align: center;
                  font-size: 24px;
                  font-weight: 700;
                }

                .icon-container {
                  border-radius: 60px;
                  background: #2c2c2c;
                  backdrop-filter: blur(25px);
                }
              }
            }
          }
        }

        &.home-tell {
          position: relative;
          transform: translateX(4%);
        }

        &.home-features {
          position: relative;

          .home-inner {
            h4.feature-h4-mobile {
              display: none;
            }

            .form-container {
              margin-top: 30px;

              .container {
                width: 70%;
              }
            }
          }
        }

        &.home-selection {
          .home-inner {
            .selection-modal {
              margin-top: 30px;
              width: 90%;
              padding: 30px 50px;
              border-radius: 10px;
              background: rgba(27, 27, 27, 0.5);
            }
          }
        }
      }
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-10px);
  }
  20% {
    transform: translateX(10px);
  }
  30% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(10px);
  }
  70% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  90% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake-animation {
  animation: shake 0.8s ease-in-out infinite;
  animation-iteration-count: 3;
}
