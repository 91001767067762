.work-mobile {
  width: 100vw;
  position: relative;
  padding-top: 157px;
}
.work-mobile::-webkit-scrollbar {
  display: none;
}
.work-mobile .mobile-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  z-index: 0;
}
.work-mobile main {
  position: relative;
  z-index: 1;
}
.work-mobile main .work-title {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.2px;
}
.work-mobile main .view-project-flex {
  margin: 0 auto;
  display: flex;
  padding: 25px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: rgba(27, 27, 27, 0.5);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  margin-top: 20px;
  align-self: center;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
}
.work-mobile main .work-mobile-container {
  width: 100%;
  margin-bottom: 150px;
  padding: 0 20px;
}
.work-mobile main .work-mobile-container .cardContainer {
  height: 100vh;
  padding-top: 30px;
  padding-top: 200px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  width: 100%;
}
.work-mobile main .work-mobile-container .cardContainer .card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 540px;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
}
.work-mobile main .work-mobile-container .cardContainer .card .card-content {
  width: 100%;
  flex: 0.3;
  margin-bottom: 15px;
}
.work-mobile main .work-mobile-container .cardContainer .card .card-content h2 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.work-mobile main .work-mobile-container .cardContainer .card .card-content h3 {
  padding: 20px 0;
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.work-mobile main .work-mobile-container .cardContainer .card .card-content p {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.work-mobile main .work-mobile-container .cardContainer .card .imageContainer {
  flex: 0.7;
  width: 100%;
  overflow: hidden;
}
.work-mobile main .work-mobile-container .cardContainer .card .imageContainer .inner {
  width: 100%;
  height: 100%;
}
.work-mobile main .work-mobile-container .cardContainer .card .imageContainer .inner img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: cover;
     object-position: cover;
}/*# sourceMappingURL=work-mobile.css.map */