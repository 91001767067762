.about-help-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 150px;
}
.about-help-container .scope {
  height: 100%;
  width: 150px;
  margin-right: 100px;
}
.about-help-container .scope .slide-text {
  color: #fff;
  font-family: Raleway;
  font-size: 200px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  transform: rotate(-90deg) translateX(-550px); /* Initial transform */
  white-space: nowrap;
}
.about-help-container .home-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.about-help-container .home-section.bamise {
  padding-left: 120px;
}
.about-help-container .home-section .first-frame {
  height: 80%;
  display: grid;
  grid-template-columns: repeat(3, 500px);
  grid-template-rows: repeat(2, 1fr);
  gap: 0px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.about-help-container .home-section .first-frame .card {
  width: 500px;
  height: 100%;
  border-radius: 15px;
  border: 1px solid rgba(240, 240, 240, 0.3);
  background: rgba(240, 240, 240, 0.01);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.about-help-container .home-section .first-frame .card:first-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border: 1px solid rgba(240, 240, 240, 0.3);
  background: radial-gradient(66.3% 99.7% at 75.7% 29.37%, rgba(230, 229, 203, 0.24) 0%, rgba(138, 138, 138, 0) 100%);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.about-help-container .home-section .first-frame .card:first-child h2 {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 140.625% */
  letter-spacing: -1.92px;
}
.about-help-container .home-section .first-frame .card h3 {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.08px;
}
.about-help-container .home-section .first-frame .card p {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.6px;
}
.about-help-container .home-section .first-frame .card.arrow-card {
  z-index: 1;
}
.about-help-container .home-section .first-frame .card.arrow-card.first .arrow-straight {
  position: absolute;
  left: 424px;
  bottom: 160px;
  z-index: 100;
}
.about-help-container .home-section .first-frame .card.arrow-card.first .arrow-up {
  position: absolute;
  left: 460px;
  bottom: -80px;
  z-index: 100;
}
.about-help-container .home-section .first-frame .card.arrow-card.first .arrow-down {
  position: absolute;
  right: 20px;
  top: -55px;
  z-index: 100;
}
.about-help-container .home-section .middle-card {
  display: flex;
  width: 800px;
  height: 400px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: rgba(44, 44, 44, 0.1);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  margin: 0 100px;
  position: relative;
}
.about-help-container .home-section .middle-card h1 {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 80% */
  letter-spacing: -1.5px;
  margin-bottom: 50px;
}
.about-help-container .home-section .middle-card p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.6px;
}
.about-help-container .home-section .middle-card .left {
  position: absolute;
  left: -130px;
  bottom: 110px;
}
.about-help-container .home-section .middle-card .right {
  right: -100px;
  position: absolute;
  bottom: -100px;
}
.about-help-container .home-section .additional-card {
  margin: 0 100px;
}
.about-help-container .home-section .additional-card h1 {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 125% */
  letter-spacing: -1.2px;
}
.about-help-container .home-section .other-features {
  display: flex;
  height: 45%;
}
.about-help-container .home-section .other-features .card {
  width: 500px;
  height: 100%;
  border-radius: 15px;
  border: 1px solid rgba(240, 240, 240, 0.3);
  background: rgba(240, 240, 240, 0.01);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.about-help-container .home-section .other-features .card h3 {
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.08px;
}
.about-help-container .home-section .other-features .card p {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.6px;
}/*# sourceMappingURL=about-help-container.css.map */