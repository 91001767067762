.work-project-page {
  .work-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  .work-landing {
    padding: 150px 0;
    // padding-left: 300px;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 100px;

    h1 {
      color: #fff;
      font-family: Raleway;
      font-size: 100px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -3px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .container {
      display: flex;

      p {
        color: #fff;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        letter-spacing: -0.6px;
        margin-bottom: 40px;
        word-wrap: nowrap;
      }

      h2 {
        padding-top: 40px;
        color: #fff;
        font-family: Raleway;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-wrap: nowrap;
        letter-spacing: -0.9px;
        border-top: 1px solid #fff;
      }

      .service {
        margin-right: 121px;

        p {
          width: 243px;
        }
      }
    }
  }

  .work-image-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // width: ;

    // .project-image {
    //   width: 5000px;
    //   // max-width: none;
    //   height: 80%;
    //   display: block;
    //   object-fit: cover;
    //   margin-right: 50px;
    // }

    .laptop-image-container {
      width: 1745px;
      height: 100%;
      position: relative;

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .phones {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1100px;

      .mobile {
        width: 310.556px;
        height: 600px;
        flex-shrink: 0;
      }
    }
  }

  .next-case {
    background: url("../../svgs/home_background.svg");
    height: 100vh;
    width: 1300px;
    background-size: cover;
    background-position: center;
    display: flex;

    .left {
      display: flex;
      align-items: flex-end;
      padding-bottom: 50px;
      margin-right: 26px;
      padding-left: 50px;

      .stretch {
        p {
          color: #fff;
          text-align: center;
          font-family: Raleway;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      .scope {
        width: 47px;
        height: 173px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 127px;

        p {
          transform: rotate(-90deg);
          text-wrap: nowrap;
          color: var(--Color-Primary-Black, #1b1b1b);
          font-family: Raleway;
          font-size: 40px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -1.2px;
        }
      }

      h1 {
        color: var(--Color-Primary-Black, #1b1b1b);
        font-family: Raleway;
        font-size: 70px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -2.1px;
        transition: 0.3s all ease-in-out;
        text-transform: capitalize;
      }

      .case {
        position: absolute;
        right: -65%;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        width: 850px;
        height: 600px;
        padding: 70px;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        background: var(--Color-Primary-Black, #1b1b1b);
        backdrop-filter: blur(150px);
        transition: 0.3s all ease-in-out;

        .left-case {
          flex: 0.3;
          display: flex;
          align-items: start;
          flex-direction: column;
          justify-content: center;
          margin-right: 11px;

          h2 {
            padding: 0;
            margin: 0;
            color: #fff;
            font-family: Raleway;
            font-size: 50px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
          }

          h3 {
            padding: 30px 0;
            color: #fff;
            font-family: Raleway;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }

          p {
            color: #fff;
            font-family: Raleway;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }

        .right-case {
          flex: 0.7;
          height: 100%;
          background: red;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      &:hover {
        .case {
          right: 0%;
        }

        h1 {
          transform: translateX(40%) rotate(-90deg);
        }
      }
    }
  }
}
