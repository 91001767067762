.stick-menu-cursor {
  height: 355px;
  width: 420px;
  position: fixed;
  overflow: hidden;
  border-radius: 40px;
  background: rgba(44, 44, 44, 0.9);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  opacity: 0;
  transform: scale(0.2);
  z-index: 90;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.stick-menu-cursor.visible {
  opacity: 1;
  transform: scale(1);
}
.stick-menu-cursor .modalContainer {
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stick-menu-cursor .modalSlider {
  height: 100%;
  width: 100%;
  position: absolute;
  transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}
.stick-menu-cursor .modal {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 20px;
  position: relative;
}
.stick-menu-cursor .modal h2 {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.stick-menu-cursor .modal .img-project {
  height: 150px;
  border-radius: 30px;
  width: 100%;
  background-size: cover;
  background-position: center;
  margin: 20px 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.stick-menu-cursor .modal .img-project .proceed-icon {
  border-radius: 60px;
  background: rgba(250, 255, 188, 0.8);
  box-shadow: 0px 4px 50px 0px rgba(231, 255, 133, 0.64);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  width: 80px;
  height: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  display: flex;
}
.stick-menu-cursor .modal p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}/*# sourceMappingURL=sticky-menu-cursor.css.map */