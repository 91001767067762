.meet-container {
  height: 100vh;
  width: 1800px;
  padding: 20px 0;
  padding-left: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.meet-bamise-container {
    padding-left: 230px;
    width: 1600px;
  }

  .content {
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0.5;

    .ctn-title {
      display: flex;
      justify-content: space-between;

      h4 {
        color: #f0f0f0;
        font-family: Raleway;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -2px;
        text-wrap: nowrap;
      }

      .stretch {
        margin-right: 30px;
        position: relative;
        top: -20%;

        .btn-content {
          z-index: 10;
          display: flex;

          p {
            color: #fff;
            font-family: Raleway;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.72px;
            transform: rotate(-10deg) translateY(30%);
            margin-right: 10px;
          }
        }
      }
    }

    .keys {
      h1 {
        color: #f0f0f0;
        font-family: Raleway;
        font-size: 100px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -5px;
        text-wrap: nowrap;
      }
    }
  }

  .images {
    height: 80%;
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .img {
      height: 100%;
      width: 198px;
      margin-right: 11px;
      overflow: hidden;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.4s all ease-in-out;
      cursor: pointer;
      position: relative;
      background: rgb(18, 18, 18);

      &:hover {
        background: rgb(0, 30, 0);
      }

      &:nth-child(2) {
        &:hover {
          background: rgb(67, 67, 0);
        }
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      .ctn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 60px 50px;
        padding-left: 39px;
        display: flex;
        padding-bottom: 35px;
        padding-right: 0;
        transition: 0.8s all ease-in-out;

        .left {
          flex: 1;
          display: none;
          transition: 0.8s all ease-in-out;
        }

        .right {
          flex: 1;
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          justify-content: flex-end;
          margin-right: 13px;
          transition: 0.5s all ease-in-out;

          .social {
            display: none;
            transition: 0.8s all ease-in-out;
          }

          .scope {
            height: 240px;
            transform: translateY(43%);
            transition: 0.2s all ease-in-out;

            .career {
              transform: rotate(-90deg);
              text-wrap: nowrap;
              color: #fff;
              font-family: Raleway;
              font-size: 50px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              letter-spacing: -1.5px;
              transition: 0.2s all ease-in-out;
            }
          }
        }
      }

      &:hover {
        width: 600px;
        .ctn {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 60px 50px;
          padding-left: 39px;
          display: flex;

          .left {
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;

            h3 {
              color: #fff;
              font-family: Raleway;
              font-size: 40px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -1.2px;
              margin-bottom: 20px;
              text-wrap: nowrap;
            }

            p {
              color: #fff;
              font-family: Raleway;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              letter-spacing: -0.72px;
              text-wrap: nowrap;
            }
          }

          .right {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-direction: column;

            .scope {
              width: 80px;
              height: 384px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 18px;
              transform: translateY(0);

              .career {
                transform: rotate(-90deg);
                text-wrap: nowrap;
                color: #fff;
                font-family: Raleway;
                font-size: 80px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: -2.4px;
              }
            }

            .social {
              display: inherit;
              border-radius: var(--Radius-50, 50px);
              background: rgba(27, 27, 27, 0.05);
              backdrop-filter: blur(15px);
              padding: 20px;
            }
          }
        }
      }

      &:nth-child(2) {
        // width: 600px;
        .ctn {
          .right {
            .scope {
              transform: translateY(50%) translateX(5%);
            }
          }
        }

        &:hover {
          .ctn {
            .right {
              .scope {
                transform: translateY(0%) translateX(0%);
              }
            }
          }
        }
      }

      &.bamise {
        width: 600px;
        .ctn {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 60px 50px;
          padding-left: 39px;
          display: flex;

          .left {
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            flex-direction: column;

            h3 {
              color: #fff;
              font-family: Raleway;
              font-size: 40px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -1.2px;
              margin-bottom: 20px;
              text-wrap: nowrap;
            }

            p {
              color: #fff;
              font-family: Raleway;
              font-size: 24px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
              letter-spacing: -0.72px;
              text-wrap: nowrap;
            }
          }

          .right {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            flex-direction: column;

            .scope {
              width: 80px;
              height: 384px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 18px;
              transform: translateY(0);

              .career {
                transform: rotate(-90deg);
                text-wrap: nowrap;
                color: #fff;
                font-family: Raleway;
                font-size: 80px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: -2.4px;
              }
            }

            .social {
              display: inherit;
              border-radius: var(--Radius-50, 50px);
              background: rgba(27, 27, 27, 0.05);
              backdrop-filter: blur(15px);
              padding: 20px;
            }
          }
        }
      }

      &.bamise {
        // width: 600px;
        .ctn {
          .right {
            .scope {
              transform: translateY(50%) translateX(5%);
            }
          }
        }

        & {
          .ctn {
            .right {
              .scope {
                transform: translateY(0%) translateX(0%);
              }
            }
          }
        }
      }
    }
  }
}
