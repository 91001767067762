.work-section {
  background: url("../../svgs/home_background_black.svg");
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-left: 100px;
}
.work-section .work-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  z-index: 0;
}
.work-section .work-container {
  position: fixed;
  top: 0;
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 150px;
}
.work-section .work-container h1 {
  padding-top: 80px;
  padding-left: 126px;
  color: #fff;
  font-family: Raleway;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -3px;
}
.work-section .work-container .layout-btn {
  background: black;
  margin-bottom: 58px;
  display: flex;
  margin-left: 35px;
  display: flex;
  width: 150px;
  height: 387px;
  padding: 100px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  background: var(--Color-Primary-Black, #1b1b1b);
}
.work-section .work-container .layout-btn .scope {
  width: 28px;
  height: 0px;
  transform: translateY(60px);
}
.work-section .work-container .layout-btn .scope p {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
}
.work-section .work-portofolio-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  overflow-y: scroll;
}
.work-section .work-portofolio-container::-webkit-scrollbar {
  display: none;
}
.work-section .work-portofolio-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
}
.work-section .work-portofolio-container .portofolio-container {
  margin-top: 300px;
  display: flex;
  pointer-events: all;
  width: 79%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 100px;
  flex-direction: column;
  align-items: center;
  border-radius: var(--Radius-50, 50px) var(--Radius-50, 50px) 0px 0px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.work-section .work-portofolio-container .portofolio-container .portofolio {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 55px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.work-section .work-portofolio-container .portofolio-container .portofolio h2 {
  color: #fff;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
  flex: 0.35;
}
.work-section .work-portofolio-container .portofolio-container .portofolio p {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.72px;
  flex: 0.3;
}
.work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(3) {
  flex: 0.25;
}
.work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(4) {
  flex: 0.1;
}
.work-section .work-landscape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 0;
  pointer-events: none;
}
.work-section .work-landscape .landscape-container {
  pointer-events: all;
}
.work-section .work-landscape .landscape-container .cards .card {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 60px;
}
.work-section .work-landscape .landscape-container .cards .card .cd {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 930px;
  height: 70%;
  padding: 70px var(--Radius-50, 50px);
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(41, 47, 69, 0.8);
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
}
.work-section .work-landscape .landscape-container .cards .card .cd .left {
  flex: 0.4;
  margin-left: 15px;
}
.work-section .work-landscape .landscape-container .cards .card .cd .left h1 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.work-section .work-landscape .landscape-container .cards .card .cd .left h2 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px 0;
}
.work-section .work-landscape .landscape-container .cards .card .cd .left p {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.work-section .work-landscape .landscape-container .cards .card .cd .right {
  flex: 0.6;
  height: 100%;
}
.work-section .work-landscape .landscape-container .cards .card .cd .right img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
@media (min-width: 576px) and (max-width: 768px) {
  .work-section .work-portofolio-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    overflow-y: scroll;
  }
  .work-section .work-portofolio-container::-webkit-scrollbar {
    display: none;
  }
  .work-section .work-portofolio-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  }
  .work-section .work-portofolio-container .portofolio-container {
    margin-top: 300px;
    display: flex;
    pointer-events: all;
    width: 79%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 100px 20px;
    flex-direction: column;
    align-items: center;
    border-radius: var(--Radius-50, 50px) var(--Radius-50, 50px) 0px 0px;
    background: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 55px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio h2 {
    color: #fff;
    font-family: Raleway;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
    flex: 0.35;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p {
    color: #fff;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.72px;
    flex: 0.3;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(3) {
    flex: 0.25;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(4) {
    flex: 0.1;
  }
  .work-section .work-container .layout-btn {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .work-section .work-portofolio-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    overflow-y: scroll;
  }
  .work-section .work-portofolio-container::-webkit-scrollbar {
    display: none;
  }
  .work-section .work-portofolio-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  }
  .work-section .work-portofolio-container .portofolio-container {
    margin-top: 300px;
    display: flex;
    pointer-events: all;
    width: 79%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 100px 50px;
    flex-direction: column;
    align-items: center;
    border-radius: var(--Radius-50, 50px) var(--Radius-50, 50px) 0px 0px;
    background: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 55px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio h2 {
    color: #fff;
    font-family: Raleway;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
    flex: 0.35;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p {
    color: #fff;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.72px;
    flex: 0.3;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(3) {
    flex: 0.25;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(4) {
    flex: 0.1;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .work-section .work-portofolio-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;
    overflow-y: scroll;
  }
  .work-section .work-portofolio-container::-webkit-scrollbar {
    display: none;
  }
  .work-section .work-portofolio-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
  }
  .work-section .work-portofolio-container .portofolio-container {
    margin-top: 300px;
    display: flex;
    pointer-events: all;
    width: 79%;
    height: -moz-fit-content;
    height: fit-content;
    padding: 100px 50px;
    flex-direction: column;
    align-items: center;
    border-radius: var(--Radius-50, 50px) var(--Radius-50, 50px) 0px 0px;
    background: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 55px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio h2 {
    color: #fff;
    font-family: Raleway;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
    flex: 0.35;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p {
    color: #fff;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.72px;
    flex: 0.3;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(3) {
    flex: 0.25;
  }
  .work-section .work-portofolio-container .portofolio-container .portofolio p:nth-child(4) {
    flex: 0.1;
  }
}/*# sourceMappingURL=work.css.map */