.navbar {
  height: 100vh;
  overflow: hidden;
  // width: 7%;
  width: 100px;
  background: rgba(240, 240, 240, 0.5);
  backdrop-filter: blur(25px);
  display: flex;
  flex-direction: column;
  padding: 100px 20px;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transition: 0.2s background ease-in-out;

  .logo {
    color: #2c2c2c;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;

    .logo-txt {
      color: #2c2c2c;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 5px;
    }
  }

  .burger {
    width: 40px;
    height: 40px;
    align-self: center;
    cursor: pointer;
    z-index: 2;
  }

  .text {
    color: #2c2c2c;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  .nav-mobile {
    display: none;
  }

  &.nav-tablet {
    & {
      background: transparent;
      backdrop-filter: none;
      height: 100px;
      width: 100%;
      background: transparent;
      align-items: flex-start;
      padding: 0;
      position: fixed;
      top: 0;

      .nav-mobile {
        width: 100%;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &.nav-mobile-dark {
          .icon-wrap {
            background: rgba(239, 239, 239, 0.5);
            backdrop-filter: blur(20px);

            img {
              filter: invert(1) brightness(2);
            }

            &.icon-btn {
              img {
                filter: invert(0) brightness(2);
              }
            }
          }
        }

        .icon-wrap {
          display: flex;
          width: 60px;
          height: 60px;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          background: rgba(27, 27, 27, 0.5);
          backdrop-filter: blur(20px);
          cursor: pointer;

          &.icon-burger {
            transform: translateX(5px);

            &.icon-burger-mb {
              transform: translateX(0px);
            }
          }

          &.icon-btn {
            position: absolute;
            left: 20px;

            img {
              width: 24px;
              height: 24px;
              filter: invert(1) brightness(2);
            }
          }

          img {
            width: 19.555px;
            height: 27.835px;
            object-position: center;
          }

          &:nth-child(2) {
            margin-right: 5px;
            img {
              width: 16px;
              height: 16px;
            }
          }

          img.logo_img {
            width: 19.555px;
            height: 27.835px;
            flex-shrink: 0;
          }
        }

        &.change {
          justify-content: flex-end;
        }
      }

      .logo {
        display: none;
      }

      .text {
        display: none;
      }
    }

    &.dark-nav {
      background: transparent;
      backdrop-filter: none;

      .logo {
        display: none;
      }

      .text {
        display: none;
      }
    }
  }

  &.dark-bamise-nav {
    .logo {
      img {
        filter: none;
      }
    }
  }

  &.dark-nav {
    background: rgba(50, 50, 50, 0.5);

    backdrop-filter: blur(25px);

    .logo {
      color: white;
      img {
        filter: invert(1) brightness(2);
      }

      .logo-txt {
        color: #fff;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 5px;
      }
    }

    .text {
      color: white;
    }
  }

  @media only screen and (max-width: 575px) {
    & {
      background: transparent;
      backdrop-filter: none;
      height: 100px;
      width: 100%;
      background: transparent;
      align-items: flex-start;
      padding: 0;
      position: fixed;
      top: 0;

      .nav-mobile {
        width: 100%;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &.nav-mobile-dark {
          .icon-wrap {
            background: rgba(239, 239, 239, 0.5);
            backdrop-filter: blur(20px);

            img {
              filter: invert(1) brightness(2);
            }

            &.icon-btn {
              img {
                filter: invert(0) brightness(2);
              }
            }
          }
        }

        .icon-wrap {
          display: flex;
          width: 60px;
          height: 60px;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          background: rgba(27, 27, 27, 0.5);
          backdrop-filter: blur(20px);
          cursor: pointer;

          &.icon-burger {
            transform: translateX(5px);

            &.icon-burger-mb {
              transform: translateX(0px);
            }
          }

          &.icon-btn {
            position: absolute;
            left: 20px;

            img {
              width: 24px;
              height: 24px;
              filter: invert(1) brightness(2);
            }
          }

          img {
            width: 19.555px;
            height: 27.835px;
            object-position: center;
          }

          &:nth-child(2) {
            margin-right: 5px;
            img {
              width: 16px;
              height: 16px;
            }
          }

          img.logo_img {
            width: 19.555px;
            height: 27.835px;
            flex-shrink: 0;
          }
        }

        &.change {
          justify-content: flex-end;
        }
      }

      .logo {
        display: none;
      }

      .text {
        display: none;
      }
    }

    &.dark-nav {
      background: transparent;
      backdrop-filter: none;

      .logo {
        display: none;
      }

      .text {
        display: none;
      }
    }
  }
}
