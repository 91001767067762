.menu-container {
  height: 100%;
  width: 95%;
  background: rgba(20, 20, 20, 0.9);
  backdrop-filter: blur(50px);
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;

  .svgCurve {
    position: absolute;
    top: 0;
    right: -99px;
    width: 100px;
    height: 100%;
    fill: rgba(20, 20, 20, 0.9);
    backdrop-filter: blur(50px);
    stroke: none;
    transform: scaleX(-1);
  }

  .content {
    .title-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      h2 {
        color: #fff;
        font-size: 64px;
        font-weight: 700;
        line-height: normal;
      }

      .btn-contact {
        display: flex;
        width: 300px;
        height: 100px;
        padding: 20px 10px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-radius: 50px;
        border-radius: var(--Radius-50, 50px);
        background: #1d1d1d;
        backdrop-filter: blur(25px);
        position: relative;
        overflow: hidden;

        &.hidden {
          opacity: 0;
          pointer-events: none;
        }

        .text {
          flex: 1;
          text-align: center;
          color: #fff;
          font-size: 20px;
          font-weight: 900;
          position: relative;
          z-index: 1;
        }

        .icon-container {
          position: relative;
          z-index: 1;
          display: flex;
          width: 80px;
          height: 80px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 50px;
          background: var(--color-grey-Altblack, #323232);
        }

        .background {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          transform-origin: bottom;
          z-index: 0;
          border-radius: 50%;
        }

        .icon-container {
          border-radius: 60px;
          background: #3c3c3c;
          backdrop-filter: blur(25px);
        }
      }
    }

    .locations {
      margin-top: 30px;
      display: flex;

      .location {
        flex: 1;
        display: flex;
        padding: 30px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 10px;
        gap: 15px;
        border-radius: 50px;
        background: radial-gradient(
          225.11% 98.34% at 90.75% 93.53%,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 177, 252, 0.1) 38.5%
        );
        backdrop-filter: blur(25px);

        .head {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: normal;
        }

        .text {
          color: #fff;
          font-size: 18px;
          font-weight: 700;
          line-height: normal;
        }

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }

        .body {
          color: #fff;
          font-size: 18px;
          font-weight: 300;
          line-height: normal;
        }

        &:nth-child(2) {
          background: radial-gradient(
            225.11% 98.34% at 90.75% 93.53%,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(187, 255, 170, 0.1) 100%
          );
        }

        &:nth-child(3) {
          background: radial-gradient(
            225.11% 98.34% at 90.75% 93.53%,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 162, 162, 0.1) 100%
          );
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;

    margin: 0;
    padding: 0;

    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 100px;

      .left {
        margin: 0;
        padding: 0;
        margin-right: 20px;

        h3 {
          color: #fff;
          font-size: 24px;
          font-weight: 600;
          margin: 0;
          padding: 0;
        }

        p {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
          margin: 0;
          padding: 0;
        }
      }

      .right {
        margin: 0;
        padding: 0;
        .icon-container {
          display: flex;
          width: 60px;
          height: 60px;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #1d1d1d;
        }
      }
    }
  }

  @media only screen and (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 768px) {
    padding: 50px 20px;
    width: 88%;

    .content {
      .title-content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        h2 {
          color: #fff;
          font-size: 64px;
          font-weight: 700;
          line-height: normal;
        }

        .btn-contact {
          display: flex;
          width: 300px;
          height: 100px;
          padding: 20px 10px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          border-radius: 50px;
          border-radius: var(--Radius-50, 50px);
          background: #1d1d1d;
          backdrop-filter: blur(25px);
          position: relative;
          overflow: hidden;

          &.hidden {
            opacity: 0;
            pointer-events: none;
          }

          .text {
            flex: 1;
            text-align: center;
            color: #fff;
            font-size: 20px;
            font-weight: 900;
            position: relative;
            z-index: 1;
          }

          .icon-container {
            position: relative;
            z-index: 1;
            display: flex;
            width: 80px;
            height: 80px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 50px;
            background: var(--color-grey-Altblack, #323232);
          }

          .background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform-origin: bottom;
            z-index: 0;
            border-radius: 50%;
          }

          .icon-container {
            border-radius: 60px;
            background: #3c3c3c;
            backdrop-filter: blur(25px);
          }
        }
      }

      .locations {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        display: none;
      }
    }

    .footer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
      margin: 0;
      padding: 0;

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;

        &:last-child {
          margin-right: 0;
        }

        .left {
          margin: 0;
          padding: 0;
          margin-right: 20px;

          h3 {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            padding: 0;
          }

          p {
            color: #fff;
            font-size: 16px;
            font-weight: 300;
            margin: 0;
            padding: 0;
          }
        }

        .right {
          margin: 0;
          padding: 0;
          .icon-container {
            display: flex;
            width: 60px;
            height: 60px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #1d1d1d;
          }
        }
      }
    }
  }

  @media (min-width: 769px) and (max-width: 1000px) {
    padding: 50px 20px;
    width: 90%;

    .content {
      .title-content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        h2 {
          color: #fff;
          font-size: 64px;
          font-weight: 700;
          line-height: normal;
        }

        .btn-contact {
          display: flex;
          width: 300px;
          height: 100px;
          padding: 20px 10px;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          border-radius: 50px;
          border-radius: var(--Radius-50, 50px);
          background: #1d1d1d;
          backdrop-filter: blur(25px);
          position: relative;
          overflow: hidden;

          &.hidden {
            opacity: 0;
            pointer-events: none;
          }

          .text {
            flex: 1;
            text-align: center;
            color: #fff;
            font-size: 20px;
            font-weight: 900;
            position: relative;
            z-index: 1;
          }

          .icon-container {
            position: relative;
            z-index: 1;
            display: flex;
            width: 80px;
            height: 80px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 50px;
            background: var(--color-grey-Altblack, #323232);
          }

          .background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform-origin: bottom;
            z-index: 0;
            border-radius: 50%;
          }

          .icon-container {
            border-radius: 60px;
            background: #3c3c3c;
            backdrop-filter: blur(25px);
          }
        }
      }

      .locations {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .location {
          display: flex;
          padding: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 20px;
          gap: 15px;
          border-radius: 50px;
          background: radial-gradient(
            225.11% 98.34% at 90.75% 93.53%,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 177, 252, 0.1) 38.5%
          );
          backdrop-filter: blur(25px);

          .head {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
          }

          .text {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            line-height: normal;
          }

          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
          }

          .body {
            color: #fff;
            font-size: 18px;
            font-weight: 300;
            line-height: normal;
          }

          &:nth-child(2) {
            background: radial-gradient(
              225.11% 98.34% at 90.75% 93.53%,
              rgba(255, 255, 255, 0.1) 0%,
              rgba(187, 255, 170, 0.1) 100%
            );
          }

          &:nth-child(3) {
            background: radial-gradient(
              225.11% 98.34% at 90.75% 93.53%,
              rgba(255, 255, 255, 0.1) 0%,
              rgba(255, 162, 162, 0.1) 100%
            );
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: flex-end;

      margin: 0;
      padding: 0;

      .link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        .left {
          margin: 0;
          padding: 0;
          margin-right: 20px;

          h3 {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            padding: 0;
          }

          p {
            color: #fff;
            font-size: 16px;
            font-weight: 300;
            margin: 0;
            padding: 0;
          }
        }

        .right {
          margin: 0;
          padding: 0;
          .icon-container {
            display: flex;
            width: 60px;
            height: 60px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #1d1d1d;
          }
        }
      }
    }
  }
}
