.project-content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 200px;

  &.thought-process {
    .screen-landing {
      height: 70%;
      margin-right: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .screen-landing-title {
        color: #323232;
        font-family: Raleway;
        font-size: 120px;
        font-style: normal;
        font-weight: 800;
        line-height: 90px; /* 75% */
        letter-spacing: -6px;
        margin-bottom: 100px;
      }

      .owned {
        color: #323232;
        font-family: Raleway;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 650px;
      }
    }

    .owned-img-container {
      // width: 1000px !important;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      img {
        //   object-fit: cover;
        width: 100%;
        // max-width: none;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .framed-container {
        display: flex;
        height: 85%;
        width: 100%;
        padding: 50px;
        justify-content: center;
        align-items: center;
        gap: 30px;
        border-radius: 20px 100px 100px 20px;
        border: 1px solid #323232;

        .left {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          margin-right: 90px;

          .title {
            color: #323232;
            font-family: Raleway;
            font-size: 64px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            letter-spacing: -3.2px;
          }

          .content {
            color: #323232;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }

        .right {
          flex: 1;
          margin-left: 90px;

          &.height {
            height: 100%;

            img {
              border-radius: 50px;
            }
          }
        }

        &.dark {
          background: #323232;

          .title {
            color: #f0f0f0;
          }

          .content {
            color: #ffffff;
          }
        }
      }

      .tools-container {
        display: flex;
        padding: 0px 150px;
        align-items: flex-start;
        gap: 150px;
        width: 100%;

        .tools-section {
          .title {
            color: #323232;
            font-family: Raleway;
            font-size: 70px;
            font-style: normal;
            font-weight: 800;
            line-height: 90px; /* 128.571% */
            letter-spacing: -3.5px;
            margin-bottom: 15px;
          }

          .tools {
            display: flex;

            img {
              width: 100px;
              height: 100px;
              border-radius: 10px;

              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }

        .strength-section {
          flex: 1;

          .title {
            color: #323232;
            font-family: Raleway;
            font-size: 70px;
            font-style: normal;
            font-weight: 800;
            line-height: 90px; /* 128.571% */
            letter-spacing: -3.5px;
          }

          .strengths {
            margin-top: 30px;
            width: 100%;

            .strength {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;

              p {
                color: #323232;
                font-family: Raleway;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .contain {
                display: flex;
                align-items: center;

                .bar-container {
                  width: 221px;
                  height: 10.5px;
                  border: 1px solid #323232;
                  border-radius: 5px;
                  margin-right: 21px;
                  overflow: hidden;

                  .bar {
                    background: #323232;
                    transform-origin: left;
                    height: 100%;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                  }
                }

                .progress {
                  color: #323232;
                  font-family: Raleway;
                  font-size: 25px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }

  .screen-landing {
    height: 70%;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .screen-landing-title {
      color: #fff;
      font-family: Raleway;
      font-size: 100px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: -3px;
    }

    .owned {
      color: #fff;
      font-family: Raleway;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.9px;
    }

    .section-container {
      display: flex;

      p {
        color: #fff;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        letter-spacing: -0.6px;
        margin-bottom: 40px;
        word-wrap: nowrap;
      }

      h2 {
        padding-top: 40px;
        color: #fff;
        font-family: Raleway;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-wrap: nowrap;
        margin: 0;
        max-width: none;
        width: auto;
        letter-spacing: -0.9px;
        border-top: 1px solid #fff;
      }

      .service-work {
        margin-right: 121px;

        p {
          width: 243px;
        }
      }

      .timeline {
        margin-left: 121px;
      }
    }
  }

  .project-full-image {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;

    img {
      width: auto;
      height: 80%;
      max-width: none;
      object-fit: cover;
      object-position: center;
    }
  }

  .owned-img-container {
    // width: 1000px !important;
    height: 100%;

    img {
      //   object-fit: cover;
      width: 100%;
      max-width: none;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .laptop-image-container {
    width: 1745px;
    height: 100%;
    position: relative;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .phone-video-container {
    width: 941px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    video {
      width: 350px;
      height: 85%;
    }
  }

  .phones {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1500px;

    .mobile {
      width: 310.556px;
      height: 600px;
      flex-shrink: 0;

      &:first-child {
        margin-right: 30px;
      }
    }
  }
}
