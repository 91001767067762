.page-not-found {
  background: url("../svgs/home_background_black.svg");
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
}
.page-not-found .error-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.page-not-found .error-container .left-error {
  display: flex;
  padding-left: 250px;
  padding-top: 150px;
  padding-bottom: 100px;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.page-not-found .error-container .left-error h1 {
  color: #fff;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -3px;
}
.page-not-found .error-container .left-error .error-footer {
  display: flex;
}
.page-not-found .error-container .left-error .error-footer .link {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
.page-not-found .error-container .left-error .error-footer .link .left {
  margin-right: 20px;
}
.page-not-found .error-container .left-error .error-footer .link .left h3 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.page-not-found .error-container .left-error .error-footer .link .left p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.page-not-found .error-container .left-error .error-footer .link .right .icon-container {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--Color-Primary-Black, #1b1b1b);
}
.page-not-found .error-container .left-error .error-footer .link .right .icon-container img {
  width: 24px;
  height: 24px;
}
.page-not-found .error-container .right-error {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-right: 30px;
}
.page-not-found .error-container .right-error p {
  margin-bottom: 40px;
  color: #f0f0f0;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.page-not-found .error-container .right-error img {
  width: 32px;
  height: 32px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .page-not-found {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .page-not-found .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 50px 0;
  }
  .page-not-found .error-container .left-error {
    display: flex;
    padding-left: 150px;
    padding-top: 150px;
    padding-bottom: 100px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  .page-not-found .error-container .left-error h1 {
    color: #fff;
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -3px;
  }
  .page-not-found .error-container .left-error .error-footer {
    display: flex;
    flex-direction: column;
  }
  .page-not-found .error-container .left-error .error-footer .link {
    display: flex;
    align-items: center;
    margin-right: 50px;
    margin-bottom: 50px;
  }
  .page-not-found .error-container .left-error .error-footer .link:last-child {
    margin-right: 0;
  }
  .page-not-found .error-container .left-error .error-footer .link .left {
    margin-right: 20px;
  }
  .page-not-found .error-container .left-error .error-footer .link .left h3 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }
  .page-not-found .error-container .left-error .error-footer .link .left p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .page-not-found .error-container .left-error .error-footer .link .right .icon-container {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--Color-Primary-Black, #1b1b1b);
  }
  .page-not-found .error-container .left-error .error-footer .link .right .icon-container img {
    width: 24px;
    height: 24px;
  }
  .page-not-found .error-container .right-error {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 30px;
  }
  .page-not-found .error-container .right-error p {
    margin-bottom: 40px;
    color: #f0f0f0;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .page-not-found .error-container .right-error img {
    width: 32px;
    height: 32px;
    margin-bottom: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .page-not-found .error-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 250px 0;
  }
  .page-not-found .error-container .left-error {
    display: flex;
    padding-left: 200px;
    padding-top: 150px;
    padding-bottom: 100px;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }
  .page-not-found .error-container .left-error h1 {
    color: #fff;
    font-size: 90px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -3px;
  }
  .page-not-found .error-container .left-error .error-footer {
    display: flex;
  }
  .page-not-found .error-container .left-error .error-footer .link {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }
  .page-not-found .error-container .left-error .error-footer .link:last-child {
    margin-right: 0;
  }
  .page-not-found .error-container .left-error .error-footer .link .left {
    margin-right: 20px;
  }
  .page-not-found .error-container .left-error .error-footer .link .left h3 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }
  .page-not-found .error-container .left-error .error-footer .link .left p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .page-not-found .error-container .left-error .error-footer .link .right .icon-container {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--Color-Primary-Black, #1b1b1b);
  }
  .page-not-found .error-container .left-error .error-footer .link .right .icon-container img {
    width: 24px;
    height: 24px;
  }
  .page-not-found .error-container .right-error {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 30px;
  }
  .page-not-found .error-container .right-error p {
    margin-bottom: 40px;
    color: #f0f0f0;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .page-not-found .error-container .right-error img {
    width: 32px;
    height: 32px;
    margin-bottom: 20px;
  }
}

.page-not-found-mobile {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  position: relative;
  padding-top: 160px;
}
.page-not-found-mobile .mobile-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  z-index: 0;
}
.page-not-found-mobile .container {
  position: relative;
  z-index: 1;
}
.page-not-found-mobile .container h1 {
  color: #f0f0f0;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.5px;
  margin-bottom: 90px;
}
.page-not-found-mobile .container .error-footer {
  width: 100%;
  margin-bottom: 100px;
}
.page-not-found-mobile .container .error-footer .link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  padding-right: 30px;
}
.page-not-found-mobile .container .error-footer .link h3 {
  margin-bottom: 10px;
  color: #f0f0f0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.page-not-found-mobile .container .error-footer .link p {
  color: #f0f0f0;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.page-not-found-mobile .container .error-footer .link img {
  width: 24px;
  height: 24px;
}/*# sourceMappingURL=page-not-found.css.map */