.contact-us {
  background: url("../../svgs/home_background.svg");
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 50px 0;
  padding-left: 200px;
  padding-right: 100px;
  height: 100vh;
  overflow: hidden;
}
.contact-us .contact-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0;
  right: 0;
}
.contact-us .contact-container .container-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}
.contact-us .contact-container .container-wrapper h1 {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: -2.1px;
  margin-right: 112px;
}
.contact-us .contact-container .container-wrapper .form {
  flex: 0.8;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}
.contact-us .contact-container .container-wrapper .form .animation {
  position: absolute;
  left: -400px;
  top: 15%;
  width: 600px;
  pointer-events: none;
}
.contact-us .contact-container .container-wrapper .form .label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.contact-us .contact-container .container-wrapper .form .label.first-child .wrap-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-us .contact-container .container-wrapper .form .label.first-child .wrap-label p {
  color: rgb(255, 45, 45);
  font-size: 14px;
}
.contact-us .contact-container .container-wrapper .form .label label {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.contact-us .contact-container .container-wrapper .form .label input {
  height: 50px;
  padding: 0px 30px;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  line-height: normal;
  border-radius: var(--Radius-50, 50px);
  background: #f1f1f1;
  border: none;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
  outline: none;
}
.contact-us .contact-container .container-wrapper .form .label input::-moz-placeholder {
  color: #a7a7a7;
}
.contact-us .contact-container .container-wrapper .form .label input::placeholder {
  color: #a7a7a7;
}
.contact-us .contact-container .container-wrapper .form .label textarea {
  resize: none;
  display: flex;
  height: 100px;
  padding: 20px 30px;
  border-radius: 30px;
  background: #f1f1f1;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: none;
}
.contact-us .contact-container .container-wrapper .form .label textarea::-moz-placeholder {
  color: #a7a7a7;
}
.contact-us .contact-container .container-wrapper .form .label textarea::placeholder {
  color: #a7a7a7;
}
.contact-us .contact-container .contact-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.contact-us .contact-container .contact-footer .details {
  flex: 1;
  display: flex;
  transform: translateY(20%);
}
.contact-us .contact-container .contact-footer .details h2 {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 10px;
}
.contact-us .contact-container .contact-footer .details .contact-details {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}
.contact-us .contact-container .contact-footer .details .contact-details a {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contact-us .contact-container .contact-footer .details .buisness-details {
  display: flex;
  flex-direction: column;
}
.contact-us .contact-container .contact-footer .details .buisness-details p {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.contact-us .contact-container .contact-footer .stretch p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
}
.contact-us .img-bp {
  position: absolute;
  right: 0;
  top: 40%;
  transition: 0.2s opacity ease-in-out;
  transition-delay: 1;
  pointer-events: none;
}
.contact-us .img-bp.hide {
  opacity: 0;
  display: none;
}
.contact-us .img-bpp {
  position: absolute;
  top: 40%;
  right: 43%;
  transform: scale(2);
  opacity: 0;
  pointer-events: none;
}
.contact-us .img-bpp.reveal {
  opacity: 1;
}
@media only screen and (max-width: 575px) {
  .contact-us {
    display: none;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .contact-us .contact-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .contact-us .contact-container .container-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .contact-us .contact-container .container-wrapper h1 {
    color: #2c2c2c;
    font-family: Raleway;
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px;
    letter-spacing: -2.1px;
    margin-right: 112px;
    margin-bottom: 40px;
  }
  .contact-us .contact-container .container-wrapper .form {
    flex: 0.9;
    height: 100%;
    position: relative;
  }
  .contact-us .contact-container .container-wrapper .form .animation {
    position: absolute;
    left: 300px;
    top: -60%;
    width: 600px;
    pointer-events: none;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1200px) and (min-device-width: 820px) and (max-device-width: 820px) and (orientation: portrait) {
  .contact-us .contact-container .container-wrapper .form .animation {
    position: absolute;
    left: 200px;
    top: -60%;
    width: 600px;
    pointer-events: none;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .contact-us .contact-container .container-wrapper .form .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .contact-us .contact-container .container-wrapper .form .label label {
    color: #2c2c2c;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
  }
  .contact-us .contact-container .container-wrapper .form .label input {
    height: 50px;
    padding: 0px 30px;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    line-height: normal;
    border-radius: var(--Radius-50, 50px);
    background: #f1f1f1;
    border: none;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
    outline: none;
  }
  .contact-us .contact-container .container-wrapper .form .label input::-moz-placeholder {
    color: #a7a7a7;
  }
  .contact-us .contact-container .container-wrapper .form .label input::placeholder {
    color: #a7a7a7;
  }
  .contact-us .contact-container .container-wrapper .form .label textarea {
    resize: none;
    display: flex;
    height: 100px;
    padding: 20px 30px;
    border-radius: 30px;
    background: #f1f1f1;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border: none;
  }
  .contact-us .contact-container .container-wrapper .form .label textarea::-moz-placeholder {
    color: #a7a7a7;
  }
  .contact-us .contact-container .container-wrapper .form .label textarea::placeholder {
    color: #a7a7a7;
  }
  .contact-us .contact-container .contact-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .contact-us .contact-container .contact-footer .details {
    flex: 1;
    display: flex;
    transform: translateY(20%);
  }
  .contact-us .contact-container .contact-footer .details h2 {
    color: #2c2c2c;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 10px;
  }
  .contact-us .contact-container .contact-footer .details .contact-details {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }
  .contact-us .contact-container .contact-footer .details .contact-details a {
    color: #2c2c2c;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .contact-us .contact-container .contact-footer .details .buisness-details {
    display: flex;
    flex-direction: column;
  }
  .contact-us .contact-container .contact-footer .details .buisness-details p {
    color: #2c2c2c;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .contact-us .contact-container .contact-footer .stretch p {
    color: #fff;
    text-align: center;
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.72px;
  }
}

.contact-mobile {
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  padding: 0 20px;
  position: relative;
  /* Hide scrollbar for IE, Edge, and Firefox */
  /* Hide scrollbar for Chrome, Safari, and Opera */
}
.contact-mobile *::-webkit-scrollbar {
  display: none;
}
.contact-mobile * {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.contact-mobile .mobile-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  z-index: 0;
}
.contact-mobile .stretch {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 2;
}
.contact-mobile .stretch p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
}
.contact-mobile .container {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 180px;
  /* Hide scrollbar for IE, Edge, and Firefox */
}
.contact-mobile .container::-webkit-scrollbar {
  display: none;
}
.contact-mobile .container::-webkit-scrollbar-thumb {
  display: none;
}
.contact-mobile .container::-webkit-scrollbar {
  display: none;
}
.contact-mobile .container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.contact-mobile .container .contact-title {
  padding-right: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.contact-mobile .container .contact-title h1 {
  color: var(--color-grey-Altblack, #323232);
  text-align: right;
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px; /* 78.125% */
  letter-spacing: -0.96px;
  width: 70%;
}
.contact-mobile .container .form {
  width: 100%;
  padding-bottom: 200px;
}
.contact-mobile .container .form .label {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.contact-mobile .container .form .label.first-child .wrap-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-mobile .container .form .label.first-child .wrap-label p {
  color: rgb(255, 45, 45);
  font-size: 12px;
}
.contact-mobile .container .form .label label {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.contact-mobile .container .form .label input {
  height: 60px;
  padding: 0px 30px;
  border-radius: var(--Radius-50, 50px);
  background: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
}
.contact-mobile .container .form .label input::-moz-placeholder {
  color: rgba(27, 27, 27, 0.4);
}
.contact-mobile .container .form .label input::placeholder {
  color: rgba(27, 27, 27, 0.4);
}
.contact-mobile .container .form .label textarea {
  resize: none;
  height: 120px;
  padding: 30px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  outline: none;
  border: none;
}
.contact-mobile .container .form .label textarea::-moz-placeholder {
  color: rgba(27, 27, 27, 0.4);
}
.contact-mobile .container .form .label textarea::placeholder {
  color: rgba(27, 27, 27, 0.4);
}
.contact-mobile .img-bp {
  position: absolute;
  width: 70px;
  right: 0;
  top: 350px;
  transition: 0.2s opacity ease-in-out;
  transition-delay: 1;
  pointer-events: none;
}
.contact-mobile .img-bp.hide {
  opacity: 0;
  display: none;
}
.contact-mobile .img-bpp {
  position: absolute;
  top: 350px;
  right: 43%;
  width: 70px;
  transform: scale(1.8);
  opacity: 0;
  pointer-events: none;
}
.contact-mobile .img-bpp.reveal {
  opacity: 1;
}/*# sourceMappingURL=contact-us.css.map */