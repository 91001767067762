.burger-menu-icon {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 90;
  position: fixed;
  left: 0;
  transition: 0.4s all ease-in-out;
  pointer-events: none;
  left: 30px;
  top: 45%;
}
.burger-menu-icon.burger-dark {
  transition: 0.2s background ease-in-out;
}
.burger-menu-icon.burger-dark .bar {
  background: white;
}
.burger-menu-icon.burger-dark:hover .bar {
  background: #2c2c2c;
}
.burger-menu-icon:hover .bar {
  background: white;
}
.burger-menu-icon .bar {
  border-radius: 50px;
  background-color: #2c2c2c;
}
.burger-menu-icon .bar:first-child {
  height: 3px;
  border-radius: 60px;
}
.burger-menu-icon .bar:nth-child(2) {
  height: 5px;
}
.burger-menu-icon .bar:nth-child(3) {
  height: 6.5px;
}
.burger-menu-icon .bar:nth-child(4) {
  height: 8px;
}
.burger-menu-icon .bounds {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: all;
  cursor: pointer;
}
.burger-menu-icon .bounds:hover {
  transform: scale(3);
}
@media only screen and (max-width: 575px) {
  .burger-menu-icon {
    display: none;
  }
}/*# sourceMappingURL=burger-menu.css.map */