.menu-mobile-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(27, 27, 27, 0.9);
  backdrop-filter: blur(50px);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  .svgCurve {
    position: absolute;
    top: 0;
    left: -99px;
    width: 100px;
    height: 100%;
    fill: rgba(27, 27, 27, 0.9);
    stroke: none;
  }

  .bar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    padding-top: 15px;

    .icon-bg {
      display: flex;
      width: 60px;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      background: rgba(27, 27, 27, 0.5);
      backdrop-filter: blur(20px);
      cursor: pointer;
    }
  }

  .wrap {
    padding: 0 50px;
    margin-top: 39px;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .auth-contain {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      // padding-right: 15px;
      margin-bottom: 150px;

      h1 {
        color: #f0f0f0;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.92px;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }

    .rest-contents {
      width: 100%;

      .links {
        width: 100%;

        .link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 50px;

          &:last-child {
            margin-bottom: 70px;
          }

          .left {
            h3 {
              color: #f0f0f0;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 5px;
            }

            p {
              color: #f0f0f0;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }

          .right {
            img {
              width: 24px;
              height: 24px;
              // margin-right: 15px;
            }
          }
        }
      }

      .footer-contents {
        width: 100%;

        p {
          color: #f0f0f0;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.42px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid rgba(240, 240, 240, 0.3);
        }

        .socials {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 15px;

          .txt {
            color: #f0f0f0;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .icons {
            display: flex;
            align-items: center;

            img {
              width: 30px;
              height: 30px;
              filter: invert(1) brightness(2);

              &:nth-child(2) {
                margin-top: 5px;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
