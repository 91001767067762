.about-portofolio {
  height: 100vh;
  width: 1512px;
  background: url("../../svgs/home_background.svg");
  background-position: center;
  background-size: cover;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.about-portofolio h1 {
  color: var(--color-grey-Altblack, #323232);
  font-family: Raleway;
  font-size: 200px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.about-portofolio .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.about-portofolio .content h3 {
  color: var(--color-grey-Altblack, #323232);
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 15px;
}
.about-portofolio .content h3.bamise {
  font-size: 32px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.about-portofolio .content p {
  color: var(--color-grey-Altblack, #323232);
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about-portofolio .content p.first {
  margin-bottom: 10px;
}
.about-portofolio .content .btn-ctrn {
  width: 300px;
}
.about-portofolio .content .btn-ctrn .btn {
  display: flex;
  width: 300px;
  height: 100px;
  border-radius: 50px;
  background: #1d1d1d;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.about-portofolio .content .btn-ctrn .btn .text {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.about-portofolio .content .btn-ctrn .btn .icon-container {
  border-radius: 60px;
  background: #2c2c2c;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  width: 80px;
  height: 80px;
}
.about-portofolio .portofolio-content-container {
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 0;
  right: 0;
}
.about-portofolio .portofolio-content-container .portofolio {
  display: flex;
  width: 1049px;
  position: absolute;
  right: -10%;
  overflow-y: scroll;
  height: 100vh;
}
.about-portofolio .portofolio-content-container .portofolio::-webkit-scrollbar {
  display: none;
}
.about-portofolio .portofolio-content-container .portofolio::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, rgba(81, 81, 81, 0) 0%, rgba(255, 255, 255, 0.2) 100%);
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper {
  margin-top: 200px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 100px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--Radius-50, 50px);
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper .work {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 80px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper .work:last-child {
  padding-bottom: 80px;
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper .work:hover {
  opacity: 0.5;
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper .work:hover .title {
  transform: translateX(-10px);
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper .work:hover .category {
  transform: translateX(10px);
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper .work .title {
  color: #1d1d1d;
  font-family: Raleway;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.8px;
  transition: 0.2s all ease-in-out;
}
.about-portofolio .portofolio-content-container .portofolio .portofolio-wrapper .work .category {
  color: #1d1d1d;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.9px;
  transition: 0.2s all ease-in-out;
}
.about-portofolio .portofolio-content-container .portofolio .modalContainer {
  height: 350px;
  width: 400px;
  position: absolute;
  background-color: white;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-portofolio .portofolio-content-container .portofolio .modalSlider {
  height: 100%;
  width: 100%;
  position: absolute;
  transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}
.about-portofolio .portofolio-content-container .portofolio .modal {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-portofolio .portofolio-content-container .portofolio .modal img {
  height: auto;
}
.about-portofolio .portofolio-content-container .portofolio .cursor,
.about-portofolio .portofolio-content-container .portofolio .cursorLabel {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #455ce9;
  color: white;
  position: absolute;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  pointer-events: none;
}
.about-portofolio .portofolio-content-container .portofolio .cursorLabel {
  background-color: transparent;
}/*# sourceMappingURL=about-portofolio.css.map */