.next-case {
  background: url("../../svgs/home_background.svg");
  height: 100vh;
  width: 1300px;
  background-size: cover;
  background-position: center;
  display: flex;
}
.next-case.last-case {
  width: 558px;
  position: relative;
}
.next-case.last-case:hover .view img {
  transform: translateX(120px);
}
.next-case.last-case .view {
  position: absolute;
  bottom: 20%;
  left: 20%;
  cursor: pointer;
}
.next-case.last-case .view p {
  color: var(--Color-Primary-Black, #1b1b1b);
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.next-case.last-case .view img {
  transition: 0.3s all ease-in-out;
  transform: translateX(90px);
}
.next-case .left {
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
  margin-right: 26px;
  padding-left: 50px;
}
.next-case .left .stretch p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.next-case .right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.next-case .right .scope {
  width: 47px;
  height: 173px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 127px;
}
.next-case .right .scope p {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.2px;
}
.next-case .right h1 {
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.1px;
  transition: 0.3s all ease-in-out;
  text-transform: capitalize;
}
.next-case .right .case {
  position: absolute;
  right: -65%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 850px;
  height: 600px;
  padding: 70px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: var(--Color-Primary-Black, #1b1b1b);
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
  transition: 0.3s all ease-in-out;
}
.next-case .right .case .left-case {
  flex: 0.3;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  margin-right: 11px;
}
.next-case .right .case .left-case h2 {
  padding: 0;
  margin: 0;
  color: #fff;
  font-family: Raleway;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.next-case .right .case .left-case h3 {
  padding: 30px 0;
  color: #fff;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.next-case .right .case .left-case p {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.next-case .right .case .right-case {
  flex: 0.7;
  height: 100%;
}
.next-case .right .case .right-case img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.next-case .right:hover .case {
  right: 0%;
}
.next-case .right:hover h1 {
  transform: translateX(40%) rotate(-90deg);
}/*# sourceMappingURL=next-case.css.map */