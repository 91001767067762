.work-section-image-container {
  padding-left: 200px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  //   width: 7950px;

  //   overflow: hidden;
  //   width: 3500px;

  //   &.long-image {
  //     width: 7950px;
  //   }

  //   &.long-image {
  //     width: 3500px;
  //     background: blue;
  //   }

  .section {
    height: 70%;
    margin-right: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h5 {
      color: #fff;
      font-family: Raleway;
      font-size: 100px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
      white-space: nowrap;
      letter-spacing: -3px;
    }

    .section-container {
      display: flex;

      p {
        color: #fff;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 200;
        line-height: normal;
        letter-spacing: -0.6px;
        margin-bottom: 40px;
        word-wrap: nowrap;
      }

      h2 {
        padding-top: 40px;
        color: #fff;
        font-family: Raleway;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-wrap: nowrap;
        letter-spacing: -0.9px;
        border-top: 1px solid #fff;
      }

      .service-work {
        margin-right: 121px;

        p {
          width: 243px;
        }
      }
    }
  }

  .project-image {
    width: 5000px;
    // max-width: none;
    height: 80%;
    display: block;
    object-fit: cover;
    margin-right: 50px;
  }

  .laptop-image-container {
    width: 1745px;
    height: 100%;
    position: relative;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .phone-video-container {
    width: 941px;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    video {
      width: 350px;
      height: 85%;
    }
  }

  .phones {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1100px;

    .mobile {
      width: 310.556px;
      height: 600px;
      flex-shrink: 0;
    }
  }
}
