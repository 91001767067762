.about-portofolio {
  height: 100vh;
  width: 1512px;
  background: url("../../svgs/home_background.svg");
  background-position: center;
  background-size: cover;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  position: relative;

  h1 {
    color: var(--color-grey-Altblack, #323232);
    font-family: Raleway;
    font-size: 200px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      color: var(--color-grey-Altblack, #323232);
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 15px;

      &.bamise {
        font-size: 32px;
        margin-top: 30px;
        margin-bottom: 10px;
      }
    }

    p {
      color: var(--color-grey-Altblack, #323232);
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;

      &.first {
        margin-bottom: 10px;
      }
    }

    .btn-ctrn {
      width: 300px;

      .btn {
        display: flex;
        width: 300px;
        height: 100px;
        border-radius: 50px;
        background: #1d1d1d;
        backdrop-filter: blur(25px);

        .text {
          color: #fff;
          text-align: center;
          font-family: Raleway;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .icon-container {
          border-radius: 60px;
          background: #2c2c2c;
          backdrop-filter: blur(25px);
          width: 80px;
          height: 80px;
        }
      }
    }
  }

  .portofolio-content-container {
    position: absolute;
    z-index: 5;
    width: 100%;
    top: 0;
    right: 0;

    &.bamise {
      // top: 10%;
    }

    .portofolio {
      display: flex;
      width: 1049px;
      position: absolute;
      right: -10%;
      overflow-y: scroll;
      height: 100vh;

      &::-webkit-scrollbar {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(81, 81, 81, 0) 0%,
          rgba(255, 255, 255, 0.2) 100%
        );
      }

      .portofolio-wrapper {
        margin-top: 200px;
        width: 100%;
        height: fit-content;
        padding: 100px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: var(--Radius-50, 50px);
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(25px);
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        .work {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 80px;
          cursor: pointer;
          transition: 0.2s all ease-in-out;

          &:last-child {
            padding-bottom: 80px;
          }

          &:hover {
            opacity: 0.5;
          }

          &:hover .title {
            transform: translateX(-10px);
          }

          &:hover .category {
            transform: translateX(10px);
          }

          .title {
            color: #1d1d1d;
            font-family: Raleway;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -1.8px;
            transition: 0.2s all ease-in-out;
          }

          .category {
            color: #1d1d1d;
            font-family: Raleway;
            font-size: 30px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -0.9px;
            transition: 0.2s all ease-in-out;
          }
        }
      }

      .modalContainer {
        height: 350px;
        width: 400px;
        position: absolute;
        background-color: white;
        overflow: hidden;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .modalSlider {
        height: 100%;
        width: 100%;
        position: absolute;
        transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
      }

      .modal {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .modal img {
        height: auto;
      }

      .cursor,
      .cursorLabel {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #455ce9;
        color: white;
        position: absolute;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 300;
        pointer-events: none;
      }

      .cursorLabel {
        background-color: transparent;
      }
    }
  }
}
