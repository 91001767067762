.footer {
  height: 100vh;
  display: flex;
  padding-left: 289px;
}
.footer.thought-process-footer {
  padding-left: 150px;
}
.footer .ctn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 50px;
  margin-right: 60px;
}
.footer .ctn.thought-process h3 {
  color: #323232;
}
.footer .ctn.thought-process p {
  color: #323232;
}
.footer .ctn h3 {
  color: #fff;
  text-align: right;
  font-family: Raleway;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: 70px; /* 87.5% */
  margin-bottom: 50px;
}
.footer .ctn p {
  text-align: right;
  color: #fff;
  text-align: right;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 50px;
  width: 461px;
}
.footer .ctn .btn {
  margin-top: 0;
}
.footer .ctn .btn.footer-btn {
  width: 500px;
  border-radius: var(--Radius-50, 50px);
  background: var(--color-grey-Altblack, #323232);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.footer .ctn .btn.footer-btn .text {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.footer .ctn .btn.footer-btn .icon-container {
  border-radius: 60px;
  background: rgba(94, 94, 94, 0.445);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.footer .ctn .btn.footer-btn img {
  filter: invert(1) brightness(2);
}
.footer .footer-btm {
  height: 100vh;
  background: #f6f6f6;
  width: 160px;
  padding: 50px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.footer .footer-btm.bamise .links {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}
.footer .footer-btm.bamise .links .a {
  margin-bottom: 20px;
  color: #1a1a1a;
}
.footer .footer-btm.bamise .links .a a {
  color: #000000;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.footer .footer-btm.thought-process {
  background: #323232;
}
.footer .footer-btm.thought-process .logo .scope p {
  color: white;
}
.footer .footer-btm.thought-process .links .a {
  color: white;
}
.footer .footer-btm.thought-process .links .a a {
  color: white;
}
.footer .footer-btm.thought-process .social-links p {
  color: white;
}
.footer .footer-btm.thought-process .social-links .socials img {
  filter: invert(1) brightness(2);
}
.footer .footer-btm .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
}
.footer .footer-btm .logo .scope {
  width: 80px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
}
.footer .footer-btm .logo .scope p {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 45px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.footer .footer-btm .logo .scope.scope-bamise p {
  font-size: 20px;
}
.footer .footer-btm .links {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}
.footer .footer-btm .links .a {
  margin-bottom: 10px;
  color: #1a1a1a;
}
.footer .footer-btm .links .a a {
  color: #000000;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.footer .footer-btm .social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer .footer-btm .social-links p {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  line-height: normal;
  margin-bottom: 40px;
}
.footer .footer-btm .social-links .socials {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer .footer-btm .social-links .socials img {
  cursor: pointer;
  width: 32px;
  height: 32px;
}
.footer .footer-btm .social-links .socials .social:first-child {
  margin-bottom: 20px;
}/*# sourceMappingURL=footer.css.map */