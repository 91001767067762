.about-mobile-page {
  //   position: relative;
  //   z-index: 1;
  //   margin-top: 110px;
  //   width: 100%;
  overflow-x: hidden;
  width: 100vw;
  position: relative;

  .section {
    width: 100vw;
    height: 100vh;
  }
}
