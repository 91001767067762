.about-page {
  .dead {
    display: none;
  }

  .btn {
    margin-top: 30px;
    display: flex;
    width: 300px;
    height: 80px;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;
    background: #2c2c2c;
    position: relative;
    overflow: hidden;

    &.action-btn {
      background: rgba(240, 240, 240, 0.4);
      backdrop-filter: blur(25px);

      .text {
        color: #323232;
      }

      .icon-container {
        background: rgba(255, 255, 255, 0.54);
        backdrop-filter: blur(25px);
      }
    }
    .text {
      flex: 1;
      text-align: center;
      color: #fff;
      font-size: 20px;
      font-weight: 900;
      position: relative;
      z-index: 1;
    }

    .icon-container {
      position: relative;
      z-index: 1;
      display: flex;
      width: 60px;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 50px;
      background: var(--color-grey-Altblack, #323232);
    }

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform-origin: bottom;
      z-index: 0;
      border-radius: 50%;
    }
  }

  .about-container {
    .about-landing {
      padding-left: 200px;
      padding-right: 250px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      overflow: hidden;
      height: 100vh;

      .plan {
        margin-bottom: 30px;
        display: flex;
        align-items: center;

        h1 {
          color: #fff;
          font-family: Raleway;
          font-size: 200px;
          font-weight: 800;
          line-height: normal;
          text-wrap: nowrap;

          &.slim {
            color: #fff;
            font-family: Raleway;
            font-size: 200px;
            font-style: normal;
            font-weight: 100;
            line-height: normal;
          }
        }

        .welcome {
          width: 450px;
          color: #fff;
          font-family: Raleway;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 50px;
        }

        .container {
          margin-left: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: #fff;
            font-family: Raleway;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 550px;
          }

          .scope {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;

            .scope-title {
              transform: rotate(-90deg);
              color: #fff;
              font-family: Raleway;
              font-size: 36px;
              font-style: normal;
              font-weight: 800;
              line-height: normal;
              letter-spacing: -1.08px;
              text-wrap: nowrap;
            }
          }

          .btn {
            margin-top: 0;
            display: flex;
            width: 100px;
            height: 150px;
            padding: 0px 10px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            margin-left: 50px;

            &.action-btn {
              border-radius: 60px;
              background: rgba(255, 255, 255, 0.1);
              backdrop-filter: blur(25px);
            }

            img {
              width: 80px;
              height: 80px;
              z-index: 10;
              color: #37bb9ed3;
            }
          }

          .background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform-origin: bottom;
            z-index: 0;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
