.menu-container {
  height: 100%;
  width: 95%;
  background: rgba(20, 20, 20, 0.9);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
}
.menu-container .svgCurve {
  position: absolute;
  top: 0;
  right: -99px;
  width: 100px;
  height: 100%;
  fill: rgba(20, 20, 20, 0.9);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  stroke: none;
  transform: scaleX(-1);
}
.menu-container .content .title-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.menu-container .content .title-content h2 {
  color: #fff;
  font-size: 64px;
  font-weight: 700;
  line-height: normal;
}
.menu-container .content .title-content .btn-contact {
  display: flex;
  width: 300px;
  height: 100px;
  padding: 20px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  border-radius: var(--Radius-50, 50px);
  background: #1d1d1d;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  position: relative;
  overflow: hidden;
}
.menu-container .content .title-content .btn-contact.hidden {
  opacity: 0;
  pointer-events: none;
}
.menu-container .content .title-content .btn-contact .text {
  flex: 1;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  position: relative;
  z-index: 1;
}
.menu-container .content .title-content .btn-contact .icon-container {
  position: relative;
  z-index: 1;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--color-grey-Altblack, #323232);
}
.menu-container .content .title-content .btn-contact .background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 0;
  border-radius: 50%;
}
.menu-container .content .title-content .btn-contact .icon-container {
  border-radius: 60px;
  background: #3c3c3c;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.menu-container .content .locations {
  margin-top: 30px;
  display: flex;
}
.menu-container .content .locations .location {
  flex: 1;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px;
  gap: 15px;
  border-radius: 50px;
  background: radial-gradient(225.11% 98.34% at 90.75% 93.53%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 177, 252, 0.1) 38.5%);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.menu-container .content .locations .location .head {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.menu-container .content .locations .location .text {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
}
.menu-container .content .locations .location img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  border-radius: 50%;
}
.menu-container .content .locations .location .body {
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}
.menu-container .content .locations .location:nth-child(2) {
  background: radial-gradient(225.11% 98.34% at 90.75% 93.53%, rgba(255, 255, 255, 0.1) 0%, rgba(187, 255, 170, 0.1) 100%);
}
.menu-container .content .locations .location:nth-child(3) {
  background: radial-gradient(225.11% 98.34% at 90.75% 93.53%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 162, 162, 0.1) 100%);
}
.menu-container .footer {
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
}
.menu-container .footer .link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;
}
.menu-container .footer .link .left {
  margin: 0;
  padding: 0;
  margin-right: 20px;
}
.menu-container .footer .link .left h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.menu-container .footer .link .left p {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}
.menu-container .footer .link .right {
  margin: 0;
  padding: 0;
}
.menu-container .footer .link .right .icon-container {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #1d1d1d;
}
@media only screen and (max-width: 575px) {
  .menu-container {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .menu-container {
    padding: 50px 20px;
    width: 88%;
  }
  .menu-container .content .title-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .menu-container .content .title-content h2 {
    color: #fff;
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
  }
  .menu-container .content .title-content .btn-contact {
    display: flex;
    width: 300px;
    height: 100px;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;
    border-radius: var(--Radius-50, 50px);
    background: #1d1d1d;
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
    position: relative;
    overflow: hidden;
  }
  .menu-container .content .title-content .btn-contact.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .menu-container .content .title-content .btn-contact .text {
    flex: 1;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    position: relative;
    z-index: 1;
  }
  .menu-container .content .title-content .btn-contact .icon-container {
    position: relative;
    z-index: 1;
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 50px;
    background: var(--color-grey-Altblack, #323232);
  }
  .menu-container .content .title-content .btn-contact .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: bottom;
    z-index: 0;
    border-radius: 50%;
  }
  .menu-container .content .title-content .btn-contact .icon-container {
    border-radius: 60px;
    background: #3c3c3c;
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
  }
  .menu-container .content .locations {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    display: none;
  }
  .menu-container .footer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  .menu-container .footer .link:last-child {
    margin-right: 0;
  }
  .menu-container .footer .link .left {
    margin: 0;
    padding: 0;
    margin-right: 20px;
  }
  .menu-container .footer .link .left h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link .left p {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link .right {
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link .right .icon-container {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #1d1d1d;
  }
}
@media (min-width: 769px) and (max-width: 1000px) {
  .menu-container {
    padding: 50px 20px;
    width: 90%;
  }
  .menu-container .content .title-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .menu-container .content .title-content h2 {
    color: #fff;
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
  }
  .menu-container .content .title-content .btn-contact {
    display: flex;
    width: 300px;
    height: 100px;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;
    border-radius: var(--Radius-50, 50px);
    background: #1d1d1d;
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
    position: relative;
    overflow: hidden;
  }
  .menu-container .content .title-content .btn-contact.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .menu-container .content .title-content .btn-contact .text {
    flex: 1;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    position: relative;
    z-index: 1;
  }
  .menu-container .content .title-content .btn-contact .icon-container {
    position: relative;
    z-index: 1;
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 50px;
    background: var(--color-grey-Altblack, #323232);
  }
  .menu-container .content .title-content .btn-contact .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: bottom;
    z-index: 0;
    border-radius: 50%;
  }
  .menu-container .content .title-content .btn-contact .icon-container {
    border-radius: 60px;
    background: #3c3c3c;
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
  }
  .menu-container .content .locations {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }
  .menu-container .content .locations .location {
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 15px;
    border-radius: 50px;
    background: radial-gradient(225.11% 98.34% at 90.75% 93.53%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 177, 252, 0.1) 38.5%);
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
  }
  .menu-container .content .locations .location .head {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }
  .menu-container .content .locations .location .text {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
  }
  .menu-container .content .locations .location img {
    width: 50px;
    height: 50px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    border-radius: 50%;
  }
  .menu-container .content .locations .location .body {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
  }
  .menu-container .content .locations .location:nth-child(2) {
    background: radial-gradient(225.11% 98.34% at 90.75% 93.53%, rgba(255, 255, 255, 0.1) 0%, rgba(187, 255, 170, 0.1) 100%);
  }
  .menu-container .content .locations .location:nth-child(3) {
    background: radial-gradient(225.11% 98.34% at 90.75% 93.53%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 162, 162, 0.1) 100%);
  }
  .menu-container .footer {
    display: flex;
    align-items: flex-end;
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
  }
  .menu-container .footer .link:last-child {
    margin-right: 0;
  }
  .menu-container .footer .link .left {
    margin: 0;
    padding: 0;
    margin-right: 20px;
  }
  .menu-container .footer .link .left h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link .left p {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link .right {
    margin: 0;
    padding: 0;
  }
  .menu-container .footer .link .right .icon-container {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #1d1d1d;
  }
}/*# sourceMappingURL=menu-container.css.map */