.navbar {
  height: 100vh;
  overflow: hidden;
  width: 100px;
  background: rgba(240, 240, 240, 0.5);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  display: flex;
  flex-direction: column;
  padding: 100px 20px;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transition: 0.2s background ease-in-out;
}
.navbar .logo {
  color: #2c2c2c;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5px;
}
.navbar .logo .logo-txt {
  color: #2c2c2c;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
}
.navbar .burger {
  width: 40px;
  height: 40px;
  align-self: center;
  cursor: pointer;
  z-index: 2;
}
.navbar .text {
  color: #2c2c2c;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.36px;
}
.navbar .nav-mobile {
  display: none;
}
.navbar.nav-tablet {
  background: transparent;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
  height: 100px;
  width: 100%;
  background: transparent;
  align-items: flex-start;
  padding: 0;
  position: fixed;
  top: 0;
}
.navbar.nav-tablet .nav-mobile {
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.navbar.nav-tablet .nav-mobile.nav-mobile-dark .icon-wrap {
  background: rgba(239, 239, 239, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.navbar.nav-tablet .nav-mobile.nav-mobile-dark .icon-wrap img {
  filter: invert(1) brightness(2);
}
.navbar.nav-tablet .nav-mobile.nav-mobile-dark .icon-wrap.icon-btn img {
  filter: invert(0) brightness(2);
}
.navbar.nav-tablet .nav-mobile .icon-wrap {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: rgba(27, 27, 27, 0.5);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  cursor: pointer;
}
.navbar.nav-tablet .nav-mobile .icon-wrap.icon-burger {
  transform: translateX(5px);
}
.navbar.nav-tablet .nav-mobile .icon-wrap.icon-burger.icon-burger-mb {
  transform: translateX(0px);
}
.navbar.nav-tablet .nav-mobile .icon-wrap.icon-btn {
  position: absolute;
  left: 20px;
}
.navbar.nav-tablet .nav-mobile .icon-wrap.icon-btn img {
  width: 24px;
  height: 24px;
  filter: invert(1) brightness(2);
}
.navbar.nav-tablet .nav-mobile .icon-wrap img {
  width: 19.555px;
  height: 27.835px;
  -o-object-position: center;
     object-position: center;
}
.navbar.nav-tablet .nav-mobile .icon-wrap:nth-child(2) {
  margin-right: 5px;
}
.navbar.nav-tablet .nav-mobile .icon-wrap:nth-child(2) img {
  width: 16px;
  height: 16px;
}
.navbar.nav-tablet .nav-mobile .icon-wrap img.logo_img {
  width: 19.555px;
  height: 27.835px;
  flex-shrink: 0;
}
.navbar.nav-tablet .nav-mobile.change {
  justify-content: flex-end;
}
.navbar.nav-tablet .logo {
  display: none;
}
.navbar.nav-tablet .text {
  display: none;
}
.navbar.nav-tablet.dark-nav {
  background: transparent;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}
.navbar.nav-tablet.dark-nav .logo {
  display: none;
}
.navbar.nav-tablet.dark-nav .text {
  display: none;
}
.navbar.dark-bamise-nav .logo img {
  filter: none;
}
.navbar.dark-nav {
  background: rgba(50, 50, 50, 0.5);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.navbar.dark-nav .logo {
  color: white;
}
.navbar.dark-nav .logo img {
  filter: invert(1) brightness(2);
}
.navbar.dark-nav .logo .logo-txt {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5px;
}
.navbar.dark-nav .text {
  color: white;
}
@media only screen and (max-width: 575px) {
  .navbar {
    background: transparent;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    height: 100px;
    width: 100%;
    background: transparent;
    align-items: flex-start;
    padding: 0;
    position: fixed;
    top: 0;
  }
  .navbar .nav-mobile {
    width: 100%;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .navbar .nav-mobile.nav-mobile-dark .icon-wrap {
    background: rgba(239, 239, 239, 0.5);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
  .navbar .nav-mobile.nav-mobile-dark .icon-wrap img {
    filter: invert(1) brightness(2);
  }
  .navbar .nav-mobile.nav-mobile-dark .icon-wrap.icon-btn img {
    filter: invert(0) brightness(2);
  }
  .navbar .nav-mobile .icon-wrap {
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: rgba(27, 27, 27, 0.5);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    cursor: pointer;
  }
  .navbar .nav-mobile .icon-wrap.icon-burger {
    transform: translateX(5px);
  }
  .navbar .nav-mobile .icon-wrap.icon-burger.icon-burger-mb {
    transform: translateX(0px);
  }
  .navbar .nav-mobile .icon-wrap.icon-btn {
    position: absolute;
    left: 20px;
  }
  .navbar .nav-mobile .icon-wrap.icon-btn img {
    width: 24px;
    height: 24px;
    filter: invert(1) brightness(2);
  }
  .navbar .nav-mobile .icon-wrap img {
    width: 19.555px;
    height: 27.835px;
    -o-object-position: center;
       object-position: center;
  }
  .navbar .nav-mobile .icon-wrap:nth-child(2) {
    margin-right: 5px;
  }
  .navbar .nav-mobile .icon-wrap:nth-child(2) img {
    width: 16px;
    height: 16px;
  }
  .navbar .nav-mobile .icon-wrap img.logo_img {
    width: 19.555px;
    height: 27.835px;
    flex-shrink: 0;
  }
  .navbar .nav-mobile.change {
    justify-content: flex-end;
  }
  .navbar .logo {
    display: none;
  }
  .navbar .text {
    display: none;
  }
  .navbar.dark-nav {
    background: transparent;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }
  .navbar.dark-nav .logo {
    display: none;
  }
  .navbar.dark-nav .text {
    display: none;
  }
}/*# sourceMappingURL=navbar.css.map */