.project-flex-list {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-right: 100px;
}
.project-flex-list .project {
  display: flex;
  padding: var(--Radius-50, 50px) 0px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: var(--Radius-50, 50px);
  background: #000;
  height: 90%;
  width: 770px;
  margin-right: 30px;
  overflow: hidden;
}
.project-flex-list .project.desktop {
  width: 1301px;
  padding: var(--Radius-50, 50px) 0;
}
.project-flex-list .project img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.project-flex-list .project video {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.project-flex-list .project video.edit {
  -o-object-fit: contain;
     object-fit: contain;
}
.project-flex-list .project:last-child {
  margin-right: 0;
}/*# sourceMappingURL=project-flex-list.css.map */