.roundedButton {
  border-radius: 50%;
  background: #1b1b1b;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;

  &.start {
    width: 300px;
    height: 199px;
    border-radius: 200px;
  }

  p {
    position: relative;
    z-index: 1;
    transition: color 0.4s linear;
  }

  &:hover {
    p {
      color: white;
    }
  }
}

.circle {
  width: 100%;
  height: 150%;
  position: absolute;
  border-radius: 50%;
  top: 100%;
}
