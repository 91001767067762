.about-mobile-footer {
  width: 100%;
  position: relative;
  z-index: 1;

  .content {
    padding: 0 20px;
    margin-bottom: 50px;

    &.thought-process {
      h2 {
        color: #323232;
      }

      p {
        color: #323232;
      }
    }

    h2 {
      color: #f0f0f0;
      text-align: center;
      font-family: Raleway;
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-bottom: 20px;
    }

    p {
      color: #f0f0f0;
      text-align: center;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .get-started {
      margin: 0 auto;
      border-radius: 50%;
      display: flex;
      width: 150px;
      height: 150px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: #2c2c2c;
      color: #fff;
      text-align: center;
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.6px;
      margin-top: 20px;
    }
  }

  .mobile-footer {
    display: flex;
    width: 100%;
    padding: 20px 20px 280px 20px;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;

    &.thought-process {
      background: #323232;

      .mobile-logo {
        h2 {
          color: #ffffff;
        }
      }

      .contact-details {
        p {
          color: #ffffff;
        }

        a {
          color: #ffffff;
        }
      }

      .buisness-details {
        p {
          color: #ffffff;
        }

        .address {
          color: #ffffff;
        }
      }

      .mobile-links {
        border-top: 1px solid rgba(255, 255, 255, 0.3);

        a {
          color: #ffffff;
        }
      }

      .socials {
        p {
          color: #ffffff;
        }

        .social-icons {
          img {
            filter: invert(1) brightness(2);
          }
        }
      }
    }

    .mobile-logo {
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 80px;
      margin-bottom: 60px;

      &.bamise {
        flex-direction: column;

        h2 {
          margin-top: 10px;
          text-align: center;
        }
      }

      img {
        filter: invert(1) brightness(2);
        margin-right: 10px;
        margin-left: 0;
      }

      h2 {
        padding: 0;
        margin: 0;
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }
    }

    .contact-details {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 50px;

      p {
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 10px;
      }

      a {
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .buisness-details {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 40px;

      p {
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 10px;
      }

      .address {
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .mobile-links {
      margin-bottom: 40px;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;
      border-top: 1px solid rgba(44, 44, 44, 0.3);

      a {
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .socials {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        margin-bottom: 10px;
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .social-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;

        img {
          width: 30px;
          height: 30px;
          margin-bottom: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
