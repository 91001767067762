.work-section-image-container {
  padding-left: 200px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.work-section-image-container .section {
  height: 70%;
  margin-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.work-section-image-container .section h5 {
  color: #fff;
  font-family: Raleway;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: -3px;
}
.work-section-image-container .section .section-container {
  display: flex;
}
.work-section-image-container .section .section-container p {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: -0.6px;
  margin-bottom: 40px;
  word-wrap: nowrap;
}
.work-section-image-container .section .section-container h2 {
  padding-top: 40px;
  color: #fff;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
  letter-spacing: -0.9px;
  border-top: 1px solid #fff;
}
.work-section-image-container .section .section-container .service-work {
  margin-right: 121px;
}
.work-section-image-container .section .section-container .service-work p {
  width: 243px;
}
.work-section-image-container .project-image {
  width: 5000px;
  height: 80%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 50px;
}
.work-section-image-container .laptop-image-container {
  width: 1745px;
  height: 100%;
  position: relative;
}
.work-section-image-container .laptop-image-container video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.work-section-image-container .phone-video-container {
  width: 941px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.work-section-image-container .phone-video-container video {
  width: 350px;
  height: 85%;
}
.work-section-image-container .phones {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1100px;
}
.work-section-image-container .phones .mobile {
  width: 310.556px;
  height: 600px;
  flex-shrink: 0;
}/*# sourceMappingURL=work-image.css.map */