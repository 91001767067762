.screen-animation-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: black;
  font-family: "Raleway", sans-serif;
  cursor: none;
  position: absolute;
  top: 0;
  left: 0;
}
.screen-animation-container .icon-onboard {
  filter: invert(30%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(90%) contrast(100%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.screen-animation-container .icon-onboard-mobile {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  display: none;
}
@media only screen and (max-width: 575px) {
  .screen-animation-container .bulleyes {
    left: 24%;
  }
  .screen-animation-container .icon-onboard {
    display: none;
  }
  .screen-animation-container .scaling {
    display: none;
  }
  .screen-animation-container .icon-onboard-mobile {
    display: none;
    filter: invert(10%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(90%) contrast(100%);
  }
}

.custom-cursor {
  width: 150px;
  height: 150px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #626060;
  position: fixed;
  z-index: 9999;
  color: rgb(224, 224, 224);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: 0.1s all ease;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  mix-blend-mode: difference;
}

.custom-cursor.hidden {
  opacity: 0;
}

.custom-cursor.visible {
  opacity: 1;
}/*# sourceMappingURL=screen-animation.css.map */