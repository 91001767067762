.footer {
  height: 100vh;
  display: flex;
  padding-left: 289px;

  &.thought-process-footer {
    padding-left: 150px;
  }

  .ctn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 50px;
    margin-right: 60px;

    &.thought-process {
      h3 {
        color: #323232;
      }

      p {
        color: #323232;
      }
    }

    h3 {
      color: #fff;
      text-align: right;
      font-family: Raleway;
      font-size: 80px;
      font-style: normal;
      font-weight: 800;
      line-height: 70px; /* 87.5% */
      margin-bottom: 50px;
    }

    p {
      text-align: right;
      color: #fff;
      text-align: right;
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 50px;
      width: 461px;
    }

    .btn {
      margin-top: 0;

      &.footer-btn {
        width: 500px;
        border-radius: var(--Radius-50, 50px);
        background: var(--color-grey-Altblack, #323232);
        backdrop-filter: blur(25px);

        .text {
          color: #fff;
          text-align: center;
          font-family: Raleway;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .icon-container {
          border-radius: 60px;
          background: rgba(94, 94, 94, 0.445);
          backdrop-filter: blur(25px);
        }

        img {
          filter: invert(1) brightness(2);
        }
      }
    }
  }

  .footer-btm {
    height: 100vh;
    background: #f6f6f6;
    width: 160px;
    padding: 50px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    &.bamise {
      .links {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        padding: 0;

        .a {
          margin-bottom: 20px;
          color: #1a1a1a;

          a {
            color: #000000;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }
      }
    }

    &.thought-process {
      background: #323232;

      .logo {
        .scope {
          p {
            color: white;
          }
        }
      }

      .links {
        .a {
          color: white;

          a {
            color: white;
          }
        }
      }

      .social-links {
        p {
          color: white;
        }

        .socials {
          img {
            filter: invert(1) brightness(2);
          }
        }
      }
    }

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 10px;

      .scope {
        width: 80px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 22px;

        p {
          transform: rotate(-90deg);
          text-wrap: nowrap;
          color: #2c2c2c;
          font-family: Raleway;
          font-size: 45px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
        }

        &.scope-bamise {
          p {
            font-size: 20px;
          }
        }
      }
    }

    .links {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      padding: 0;

      .a {
        margin-bottom: 10px;
        color: #1a1a1a;

        a {
          color: #000000;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }

    .social-links {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        text-align: center;
        line-height: normal;
        margin-bottom: 40px;
      }

      .socials {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // margin-bottom: 20px;

        img {
          cursor: pointer;
          width: 32px;
          height: 32px;
        }

        .social:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}
