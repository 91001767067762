.link-cursor {
  cursor: pointer;
  position: fixed;
  display: flex;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  backdrop-filter: blur(25px);
  color: var(--Color-Primary-Black, #1b1b1b);
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0;
  transform: scale(0.2);
  z-index: 90;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}
