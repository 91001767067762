.about-page .dead {
  display: none;
}
.about-page .btn {
  margin-top: 30px;
  display: flex;
  width: 300px;
  height: 80px;
  padding: 20px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  background: #2c2c2c;
  position: relative;
  overflow: hidden;
}
.about-page .btn.action-btn {
  background: rgba(240, 240, 240, 0.4);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.about-page .btn.action-btn .text {
  color: #323232;
}
.about-page .btn.action-btn .icon-container {
  background: rgba(255, 255, 255, 0.54);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.about-page .btn .text {
  flex: 1;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  position: relative;
  z-index: 1;
}
.about-page .btn .icon-container {
  position: relative;
  z-index: 1;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--color-grey-Altblack, #323232);
}
.about-page .btn .background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 0;
  border-radius: 50%;
}
.about-page .about-container .about-landing {
  padding-left: 200px;
  padding-right: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  height: 100vh;
}
.about-page .about-container .about-landing .plan {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.about-page .about-container .about-landing .plan h1 {
  color: #fff;
  font-family: Raleway;
  font-size: 200px;
  font-weight: 800;
  line-height: normal;
  text-wrap: nowrap;
}
.about-page .about-container .about-landing .plan h1.slim {
  color: #fff;
  font-family: Raleway;
  font-size: 200px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}
.about-page .about-container .about-landing .plan .welcome {
  width: 450px;
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 50px;
}
.about-page .about-container .about-landing .plan .container {
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-page .about-container .about-landing .plan .container p {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 550px;
}
.about-page .about-container .about-landing .plan .container .scope {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.about-page .about-container .about-landing .plan .container .scope .scope-title {
  transform: rotate(-90deg);
  color: #fff;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.08px;
  text-wrap: nowrap;
}
.about-page .about-container .about-landing .plan .container .btn {
  margin-top: 0;
  display: flex;
  width: 100px;
  height: 150px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-left: 50px;
}
.about-page .about-container .about-landing .plan .container .btn.action-btn {
  border-radius: 60px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.about-page .about-container .about-landing .plan .container .btn img {
  width: 80px;
  height: 80px;
  z-index: 10;
  color: rgba(55, 187, 158, 0.8274509804);
}
.about-page .about-container .about-landing .plan .container .background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 0;
  border-radius: 50%;
}/*# sourceMappingURL=about-page.css.map */