.home-thanks-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.home-thanks-container .home-thanks-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  padding-left: 128px;
  left: 0;
  top: 0;
}
.home-thanks-container .home-thanks-inner p {
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.2px;
}
.home-thanks-container .thanks {
  white-space: nowrap; /* Keeps the text on one line */
  display: inline-block;
  position: absolute;
  left: 130px;
  bottom: 30px;
  color: #fff;
  text-align: center;
  font-size: 200px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -6px;
}

@media only screen and (max-width: 575px) {
  .home-thanks-container {
    position: fixed;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    top: 0;
  }
  .home-thanks-container .home-thanks-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    padding-left: 20px;
    left: 0;
    top: 0;
  }
  .home-thanks-container .home-thanks-inner p {
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.2px;
  }
  .home-thanks-container .thanks {
    white-space: nowrap; /* Keeps the text on one line */
    display: inline-block;
    position: absolute;
    left: 130px;
    bottom: 100px;
    color: #fff;
    text-align: center;
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -3px;
  }
}/*# sourceMappingURL=home-thanks.css.map */