.help-you {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 0;
  padding-left: 40px;
  flex-direction: column;
}
.help-you .plan {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.help-you .plan h1 {
  color: #fff;
  font-family: Raleway;
  font-size: 200px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-wrap: nowrap;
  margin-right: 60px;
}
.help-you .plan p {
  color: #fff;
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
}
.help-you .plan:nth-child(2) p {
  text-align: end;
  margin-right: 60px;
}
.help-you .plan:nth-child(2) h1 {
  margin: 0;
  color: #fff;
  font-family: Raleway;
  font-size: 200px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}/*# sourceMappingURL=help-you.css.map */