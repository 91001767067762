.about-mobile-footer {
  width: 100%;
  position: relative;
  z-index: 1;
}
.about-mobile-footer .content {
  padding: 0 20px;
  margin-bottom: 50px;
}
.about-mobile-footer .content.thought-process h2 {
  color: #323232;
}
.about-mobile-footer .content.thought-process p {
  color: #323232;
}
.about-mobile-footer .content h2 {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 20px;
}
.about-mobile-footer .content p {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-mobile-footer .content .get-started {
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #2c2c2c;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
  margin-top: 20px;
}
.about-mobile-footer .mobile-footer {
  display: flex;
  width: 100%;
  padding: 20px 20px 280px 20px;
  flex-direction: column;
  align-items: center;
  background: #f0f0f0;
}
.about-mobile-footer .mobile-footer.thought-process {
  background: #323232;
}
.about-mobile-footer .mobile-footer.thought-process .mobile-logo h2 {
  color: #ffffff;
}
.about-mobile-footer .mobile-footer.thought-process .contact-details p {
  color: #ffffff;
}
.about-mobile-footer .mobile-footer.thought-process .contact-details a {
  color: #ffffff;
}
.about-mobile-footer .mobile-footer.thought-process .buisness-details p {
  color: #ffffff;
}
.about-mobile-footer .mobile-footer.thought-process .buisness-details .address {
  color: #ffffff;
}
.about-mobile-footer .mobile-footer.thought-process .mobile-links {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.about-mobile-footer .mobile-footer.thought-process .mobile-links a {
  color: #ffffff;
}
.about-mobile-footer .mobile-footer.thought-process .socials p {
  color: #ffffff;
}
.about-mobile-footer .mobile-footer.thought-process .socials .social-icons img {
  filter: invert(1) brightness(2);
}
.about-mobile-footer .mobile-footer .mobile-logo {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 60px;
}
.about-mobile-footer .mobile-footer .mobile-logo.bamise {
  flex-direction: column;
}
.about-mobile-footer .mobile-footer .mobile-logo.bamise h2 {
  margin-top: 10px;
  text-align: center;
}
.about-mobile-footer .mobile-footer .mobile-logo img {
  filter: invert(1) brightness(2);
  margin-right: 10px;
  margin-left: 0;
}
.about-mobile-footer .mobile-footer .mobile-logo h2 {
  padding: 0;
  margin: 0;
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.about-mobile-footer .mobile-footer .contact-details {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}
.about-mobile-footer .mobile-footer .contact-details p {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 10px;
}
.about-mobile-footer .mobile-footer .contact-details a {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.about-mobile-footer .mobile-footer .buisness-details {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
}
.about-mobile-footer .mobile-footer .buisness-details p {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 10px;
}
.about-mobile-footer .mobile-footer .buisness-details .address {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.about-mobile-footer .mobile-footer .mobile-links {
  margin-bottom: 40px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid rgba(44, 44, 44, 0.3);
}
.about-mobile-footer .mobile-footer .mobile-links a {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about-mobile-footer .mobile-footer .socials {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.about-mobile-footer .mobile-footer .socials p {
  margin-bottom: 10px;
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.about-mobile-footer .mobile-footer .socials .social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}
.about-mobile-footer .mobile-footer .socials .social-icons img {
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
}
.about-mobile-footer .mobile-footer .socials .social-icons img:last-child {
  margin-right: 0;
}/*# sourceMappingURL=about-mobile-footer.css.map */