.work-landing {
  padding-left: 200px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 100px;
}
.work-landing .work-landing-section {
  height: 70%;
  background: yellow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}
.work-landing h2 {
  padding-top: 40px;
  color: #fff;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
  letter-spacing: -0.9px;
  border-top: 1px solid #fff;
}
.work-landing .service {
  margin-right: 121px;
}
.work-landing .service p {
  width: 243px;
}/*# sourceMappingURL=work-landing.css.map */