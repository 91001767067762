.contact-us {
  background: url("../../svgs/home_background.svg");
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 50px 0;
  padding-left: 200px;
  padding-right: 100px;
  height: 100vh;
  overflow: hidden;

  .contact-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0;
    right: 0;

    .container-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 20px;

      h1 {
        color: #2c2c2c;
        font-family: Raleway;
        font-size: 70px;
        font-style: normal;
        font-weight: 800;
        line-height: 60px;
        letter-spacing: -2.1px;
        margin-right: 112px;
      }

      .form {
        flex: 0.8;
        height: 100%;
        margin: 0;
        padding: 0;
        position: relative;

        .animation {
          position: absolute;
          left: -400px;
          top: 15%;

          width: 600px;
          pointer-events: none;
        }

        .label {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;

          &.first-child {
            .wrap-label {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                color: rgb(255, 45, 45);
                font-size: 14px;
              }
            }
          }

          label {
            color: #2c2c2c;
            font-family: Raleway;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 10px;
          }

          input {
            height: 50px;
            padding: 0px 30px;
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            width: 100%;
            line-height: normal;
            border-radius: var(--Radius-50, 50px);
            background: #f1f1f1;
            border: none;
            box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset,
              -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
            outline: none;

            &::placeholder {
              color: #a7a7a7;
            }
          }

          textarea {
            resize: none;
            display: flex;
            height: 100px;
            padding: 20px 30px;
            border-radius: 30px;
            background: #f1f1f1;
            box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset,
              -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            outline: none;
            border: none;

            &::placeholder {
              color: #a7a7a7;
            }
          }
        }
      }
    }

    .contact-footer {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .details {
        flex: 1;
        display: flex;
        transform: translateY(20%);

        h2 {
          color: #2c2c2c;
          font-family: Raleway;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin-bottom: 10px;
        }

        .contact-details {
          display: flex;
          flex-direction: column;
          margin-right: 100px;

          a {
            color: #2c2c2c;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .buisness-details {
          display: flex;
          flex-direction: column;

          p {
            color: #2c2c2c;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .stretch {
        p {
          color: #fff;
          text-align: center;
          font-family: Raleway;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.72px;
        }
      }
    }
  }

  .img-bp {
    position: absolute;
    right: 0;
    top: 40%;
    transition: 0.2s opacity ease-in-out;
    transition-delay: 1;
    pointer-events: none;

    &.hide {
      opacity: 0;
      display: none;
    }
  }

  .img-bpp {
    position: absolute;
    top: 40%;
    right: 43%;
    transform: scale(2);
    opacity: 0;
    pointer-events: none;

    &.reveal {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 575px) {
    display: none;
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    .contact-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      .container-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 20px;

        h1 {
          color: #2c2c2c;
          font-family: Raleway;
          font-size: 70px;
          font-style: normal;
          font-weight: 800;
          line-height: 60px;
          letter-spacing: -2.1px;
          margin-right: 112px;
          margin-bottom: 40px;
        }

        .form {
          flex: 0.9;
          height: 100%;
          position: relative;

          .animation {
            position: absolute;
            left: 300px;
            top: -60%;
            width: 600px;
            pointer-events: none;
          }

          @media only screen and (min-device-width: 820px) and (max-device-width: 820px) and (orientation: portrait) {
            .animation {
              position: absolute;
              left: 200px;
              top: -60%;
              width: 600px;
              pointer-events: none;
            }
          }

          .label {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            label {
              color: #2c2c2c;
              font-family: Raleway;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin-bottom: 10px;
            }

            input {
              height: 50px;
              padding: 0px 30px;
              font-family: Raleway;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              width: 100%;
              line-height: normal;
              border-radius: var(--Radius-50, 50px);
              background: #f1f1f1;
              border: none;
              box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset,
                -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
              outline: none;

              &::placeholder {
                color: #a7a7a7;
              }
            }

            textarea {
              resize: none;
              display: flex;
              height: 100px;
              padding: 20px 30px;
              border-radius: 30px;
              background: #f1f1f1;
              box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset,
                -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
              font-family: Raleway;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              outline: none;
              border: none;

              &::placeholder {
                color: #a7a7a7;
              }
            }
          }
        }
      }

      .contact-footer {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .details {
          flex: 1;
          display: flex;
          transform: translateY(20%);

          h2 {
            color: #2c2c2c;
            font-family: Raleway;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-bottom: 10px;
          }

          .contact-details {
            display: flex;
            flex-direction: column;
            margin-right: 100px;

            a {
              color: #2c2c2c;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .buisness-details {
            display: flex;
            flex-direction: column;

            p {
              color: #2c2c2c;
              font-family: Raleway;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }

        .stretch {
          p {
            color: #fff;
            text-align: center;
            font-family: Raleway;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.72px;
          }
        }
      }
    }
  }
}

.contact-mobile {
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  padding: 0 20px;
  position: relative;

  *::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari, and Opera */

  .mobile-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  .stretch {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 2;

    p {
      color: #fff;
      text-align: center;
      font-family: Raleway;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.6px;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    width: 100%;
    padding-top: 180px;

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .contact-title {
      padding-right: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 40px;

      h1 {
        color: var(--color-grey-Altblack, #323232);
        text-align: right;
        font-family: Raleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 25px; /* 78.125% */
        letter-spacing: -0.96px;
        width: 70%;
      }
    }

    .form {
      width: 100%;
      padding-bottom: 200px;

      .label {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &.first-child {
          .wrap-label {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              color: rgb(255, 45, 45);
              font-size: 12px;
            }
          }
        }

        label {
          color: #2c2c2c;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 10px;
        }

        input {
          height: 60px;
          padding: 0px 30px;
          border-radius: var(--Radius-50, 50px);
          background: #fff;
          box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset,
            -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: none;
          outline: none;

          &::placeholder {
            color: rgba(27, 27, 27, 0.4);
          }
        }

        textarea {
          resize: none;
          height: 120px;
          padding: 30px;
          border-radius: 30px;
          background: #fff;
          box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset,
            -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
          font-family: Raleway;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          outline: none;
          border: none;

          &::placeholder {
            color: rgba(27, 27, 27, 0.4);
          }
        }
      }
    }
  }

  .img-bp {
    position: absolute;
    width: 70px;
    right: 0;
    top: 350px;
    transition: 0.2s opacity ease-in-out;
    transition-delay: 1;
    pointer-events: none;

    &.hide {
      opacity: 0;
      display: none;
    }
  }

  .img-bpp {
    position: absolute;
    top: 350px;
    right: 43%;
    width: 70px;
    transform: scale(1.8);
    opacity: 0;
    pointer-events: none;

    &.reveal {
      opacity: 1;
    }
  }
}
