.about-mobile-section {
  display: none;
  width: 100vw;
  position: relative;
}
.about-mobile-section::-webkit-scrollbar {
  display: none;
}
.about-mobile-section .mobile-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.about-mobile-section .about-mobile-container {
  position: relative;
  z-index: 1;
  margin-top: 110px;
  width: 100%;
}
.about-mobile-section .about-mobile-container .scroll-bottom {
  position: fixed;
  right: 20px;
  bottom: 10%;
  z-index: 10000;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(44, 44, 44, 0.4);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.about-mobile-section .about-mobile-container::-webkit-scrollbar {
  display: none;
}
.about-mobile-section .about-mobile-container .about-mobile-landing {
  width: 100%;
  margin-bottom: 290px;
  padding: 0 20px;
  overflow-x: hidden;
}
.about-mobile-section .about-mobile-container .about-mobile-landing .welcome-content {
  width: 87%;
  margin-bottom: 60px;
}
.about-mobile-section .about-mobile-container .about-mobile-landing .welcome-content p {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .about-mobile-landing h1 {
  color: #f0f0f0;
  width: 100%;
  font-family: Raleway;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: 70px; /* 100% */
  letter-spacing: -2.1px;
  text-wrap: wrap;
  margin-bottom: 60px;
}
.about-mobile-section .about-mobile-container .about-mobile-landing .outro {
  width: 100%;
}
.about-mobile-section .about-mobile-container .about-mobile-landing .outro p {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .about-mobile-help {
  width: 100%;
  margin-bottom: 250px;
  padding: 0 20px;
  overflow-x: hidden;
}
.about-mobile-section .about-mobile-container .about-mobile-help .h1 {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.5px;
  margin-bottom: 115px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .first-frame {
  width: 100%;
  position: relative;
}
.about-mobile-section .about-mobile-container .about-mobile-help .first-frame .cards {
  width: 100%;
}
.about-mobile-section .about-mobile-container .about-mobile-help .first-frame .cards .card {
  display: flex;
  height: 300px;
  padding: 50px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border-radius: 15px;
  justify-content: space-between;
  border: 1px solid rgba(240, 240, 240, 0.3);
  background: rgba(240, 240, 240, 0.01);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.about-mobile-section .about-mobile-container .about-mobile-help .first-frame .cards .card:first-child {
  border-radius: 15px;
  background: radial-gradient(66.3% 99.7% at 75.7% 29.37%, rgba(230, 229, 203, 0.24) 0%, rgba(138, 138, 138, 0) 100%);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  align-items: center;
  justify-content: center;
}
.about-mobile-section .about-mobile-container .about-mobile-help .first-frame .cards .card h1 {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 187.5% */
  letter-spacing: -1.44px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .first-frame .cards .card h2 {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .first-frame .cards .card p {
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.48px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .middle-card {
  width: 100%;
  display: flex;
  height: 500px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--Radius-50, 50px);
  border-radius: 15px;
  background: rgba(44, 44, 44, 0.05);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  margin: 100px 0;
  z-index: 1;
  position: relative;
}
.about-mobile-section .about-mobile-container .about-mobile-help .middle-card h1 {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 35px; /* 87.5% */
  letter-spacing: -1.2px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .middle-card p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.48px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .middle-card img {
  position: absolute;
  z-index: 100;
}
.about-mobile-section .about-mobile-container .about-mobile-help .middle-card img.left {
  top: -140px;
  right: 30px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .middle-card img.right {
  bottom: -140px;
  left: 30px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .additional {
  width: 100%;
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 104.167% */
  letter-spacing: -0.72px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .other-features {
  width: 100%;
}
.about-mobile-section .about-mobile-container .about-mobile-help .other-features .card {
  display: flex;
  height: 300px;
  padding: 50px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border-radius: 15px;
  justify-content: space-between;
  border: 1px solid rgba(240, 240, 240, 0.3);
  background: rgba(240, 240, 240, 0.01);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.about-mobile-section .about-mobile-container .about-mobile-help .other-features .card h1 {
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 90px; /* 187.5% */
  letter-spacing: -1.44px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .other-features .card h2 {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.72px;
}
.about-mobile-section .about-mobile-container .about-mobile-help .other-features .card p {
  color: #fff;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.48px;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio {
  width: 100%;
  background-image: url("../../svgs/home_background_mobile.svg");
  background-size: cover;
  background-position: center;
  margin-bottom: 220px;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .intro {
  padding: 0 20px;
  padding-top: 200px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1; /* Safari fix */
  backface-visibility: hidden;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .intro h1 {
  color: #2c2c2c;
  font-family: Raleway;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -3px;
  margin-bottom: 35px;
  text-align: center;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .intro h2 {
  color: #2c2c2c;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 100% */
  letter-spacing: -0.6px;
  margin: 0 auto;
  margin-bottom: 35px;
  width: 60%;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .intro p {
  color: #2c2c2c;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios {
  width: 100%;
  position: relative;
  padding-bottom: 200px;
  padding-top: 60px;
  background: #ffffff;
  transform: translateZ(0);
  will-change: transform;
  z-index: 10;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 80px;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio h2 {
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.9px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(44, 44, 44, 0.3);
  margin-bottom: 5px;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio p {
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.48px;
  margin-bottom: 30px;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio .img-container {
  width: 100%;
  height: 150px;
  position: relative;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio .img-container .img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  -o-object-position: center;
     object-position: center;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio .img-container .proceed-icon-wrapper {
  position: absolute;
  right: -5px;
  bottom: -18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio .img-container .proceed-icon-wrapper img {
  width: 30px;
  height: 30px;
  filter: invert(1) brightness(2);
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .portfolio .content {
  color: #2c2c2c;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 95%;
  margin: 0 auto;
  margin-top: 20px;
}
.about-mobile-section .about-mobile-container .about-mobile-portofolio .portfolios .all-portfolio {
  margin: 0 auto;
  display: flex;
  width: 150px;
  height: 150px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50%;
  background: #2c2c2c;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.6px;
}
.about-mobile-section .about-mobile-container .about-meet-overview {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 350px;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team {
  width: 100%;
  margin-bottom: 50px;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team h3 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team h1 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -3px;
  margin-bottom: 50px;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team {
  width: 100%;
  height: 700px;
  position: relative;
  overflow: hidden;
  border-radius: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 10px;
  background: rgb(0, 30, 0);
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team.second {
  background: rgb(67, 67, 0);
  transform: translateZ(0);
  will-change: transform;
  z-index: 10;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team.first {
  position: sticky;
  top: 0;
  z-index: -1; /* Safari fix */
  backface-visibility: hidden;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team img.head-picture {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
  height: 100%;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .scope {
  position: absolute;
  right: 20px;
  top: 0;
  width: 94px;
  z-index: 1;
  height: 384px;
  transform: translateY(85%);
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .scope .career {
  transform: rotate(-90deg);
  color: #f0f0f0;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #f0f0f0;
  font-family: Raleway;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.4px;
  text-wrap: nowrap;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .bottom-content {
  position: relative;
  z-index: 1;
  padding: 50px 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .bottom-content .left {
  flex: 1;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .bottom-content .left h4 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.4px;
  margin-bottom: 20px;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .bottom-content .left p {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .bottom-content .right .icon {
  display: flex;
  width: 72px;
  height: 72px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: var(--Radius-50, 50px);
  background: rgba(27, 27, 27, 0.05);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}
.about-mobile-section .about-mobile-container .about-meet-overview .meet-team .team .bottom-content .right .icon img {
  width: 32px;
  height: 32px;
}
.about-mobile-section .about-mobile-container .about-meet-overview .overview {
  width: 100%;
  margin-bottom: 100px;
}
.about-mobile-section .about-mobile-container .about-meet-overview .overview h1 {
  margin-bottom: 35px;
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 64px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .about-meet-overview .overview p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .thought-process-landing {
  width: 100%;
  padding: 0 20px;
}
.about-mobile-section .about-mobile-container .thought-process-landing .title {
  padding-top: 40px;
  margin-bottom: 60px;
  color: #323232;
  font-family: Raleway;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px; /* 85.714% */
  letter-spacing: -3.5px;
}
.about-mobile-section .about-mobile-container .thought-process-landing .content {
  color: #323232;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
  padding-right: 50px;
}
.about-mobile-section .about-mobile-container .thought-container-section {
  width: 100%;
  padding: 0 20px;
  margin-top: 90px;
}
.about-mobile-section .about-mobile-container .thought-container-section .thought-container {
  width: 100%;
  display: flex;
  padding: 50px 30px 53px 30px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px 50px 50px 50px;
  border: 1px solid #323232;
  margin-bottom: 10px;
}
.about-mobile-section .about-mobile-container .thought-container-section .thought-container .title {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #323232;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.5px;
}
.about-mobile-section .about-mobile-container .thought-container-section .thought-container .content {
  color: #323232;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .thought-container-section .thought-container.dark {
  background: #323232;
}
.about-mobile-section .about-mobile-container .thought-container-section .thought-container.dark .title {
  color: #f0f0f0;
}
.about-mobile-section .about-mobile-container .thought-container-section .thought-container.dark .content {
  color: #ffffff;
}
.about-mobile-section .about-mobile-container .thought-container-tools {
  width: 100%;
  padding: 0 20px;
  margin-top: 190px;
  margin-bottom: 240px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .tools-container {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 100px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .tools-container .title {
  color: #323232;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .tools-container .tools {
  display: flex;
  align-items: center;
}
.about-mobile-section .about-mobile-container .thought-container-tools .tools-container .tools img {
  width: 60px;
  height: 60px;
  border-radius: 3px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .tools-container .tools img:first-child {
  margin-right: 10px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section {
  padding: 0 10px;
  width: 100%;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .title {
  color: #323232;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2px;
  margin-bottom: 25px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .strengths {
  width: 100%;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .strengths .strength {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .strengths .strength p {
  color: #323232;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .strengths .strength .contain {
  display: flex;
  align-items: center;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .strengths .strength .contain .bar-container {
  width: 60px;
  height: 7px;
  border: 1px solid #323232;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 5px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .strengths .strength .contain .bar-container .bar {
  background: #323232;
  transform-origin: left;
  height: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.about-mobile-section .about-mobile-container .thought-container-tools .strength-section .strengths .strength .contain .progress {
  color: #323232;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media only screen and (max-width: 575px) {
  .about-mobile-section {
    display: block;
  }
  .about-mobile-section::-webkit-scrollbar {
    display: none;
  }
  .about-mobile-section::-webkit-scrollbar-thumb {
    display: none;
  }
}/*# sourceMappingURL=about-mobile-section.css.map */