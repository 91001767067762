.page-not-found {
  background: url("../svgs/home_background_black.svg");
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  position: fixed;
  top: 0;

  .error-container {
    width: 100%;
    height: 100%;
    display: flex;

    .left-error {
      display: flex;
      padding-left: 250px;
      padding-top: 150px;
      padding-bottom: 100px;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;

      h1 {
        color: #fff;
        font-size: 100px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: -3px;
      }

      .error-footer {
        display: flex;

        .link {
          display: flex;
          align-items: center;
          margin-right: 100px;

          .left {
            margin-right: 20px;

            h3 {
              color: #fff;
              font-size: 24px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 10px;
            }

            p {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }

          .right {
            .icon-container {
              display: flex;
              width: 60px;
              height: 60px;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              background: var(--Color-Primary-Black, #1b1b1b);

              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }

    .right-error {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-right: 30px;

      p {
        margin-bottom: 40px;
        color: #f0f0f0;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      img {
        width: 32px;
        height: 32px;
        margin-bottom: 20px;
      }
    }
  }

  @media only screen and (max-width: 575px) {
    display: none;
  }

  @media (min-width: 576px) and (max-width: 768px) {
    .error-container {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 50px 0;

      .left-error {
        display: flex;
        padding-left: 150px;
        padding-top: 150px;
        padding-bottom: 100px;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        h1 {
          color: #fff;
          font-size: 70px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          letter-spacing: -3px;
        }

        .error-footer {
          display: flex;
          flex-direction: column;

          .link {
            display: flex;
            align-items: center;
            margin-right: 50px;
            margin-bottom: 50px;

            &:last-child {
              margin-right: 0;
            }

            .left {
              margin-right: 20px;

              h3 {
                color: #fff;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 10px;
              }

              p {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
              }
            }

            .right {
              .icon-container {
                display: flex;
                width: 60px;
                height: 60px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: var(--Color-Primary-Black, #1b1b1b);

                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }

      .right-error {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-right: 30px;

        p {
          margin-bottom: 40px;
          color: #f0f0f0;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        img {
          width: 32px;
          height: 32px;
          margin-bottom: 20px;
        }
      }
    }
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    .error-container {
      width: 100%;
      height: 100%;
      display: flex;
      padding: 250px 0;

      .left-error {
        display: flex;
        padding-left: 200px;
        padding-top: 150px;
        padding-bottom: 100px;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        h1 {
          color: #fff;
          font-size: 90px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          letter-spacing: -3px;
        }

        .error-footer {
          display: flex;

          .link {
            display: flex;
            align-items: center;
            margin-right: 50px;

            &:last-child {
              margin-right: 0;
            }

            .left {
              margin-right: 20px;

              h3 {
                color: #fff;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 10px;
              }

              p {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
              }
            }

            .right {
              .icon-container {
                display: flex;
                width: 60px;
                height: 60px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: var(--Color-Primary-Black, #1b1b1b);

                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }

      .right-error {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-right: 30px;

        p {
          margin-bottom: 40px;
          color: #f0f0f0;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        img {
          width: 32px;
          height: 32px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.page-not-found-mobile {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  position: relative;
  padding-top: 160px;

  .mobile-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 1;

    h1 {
      color: #f0f0f0;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -1.5px;
      margin-bottom: 90px;
    }

    .error-footer {
      width: 100%;
      margin-bottom: 100px;

      .link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 40px;
        padding-right: 30px;

        h3 {
          margin-bottom: 10px;
          color: #f0f0f0;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        p {
          color: #f0f0f0;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
