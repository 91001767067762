.loader {
  height: calc(100vh + 150px);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: block;

  &.visible {
    display: none;
  }

  .title {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .dots {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: white;
      margin-right: 10px;
    }

    h1 {
      color: #f0f0f0;
      text-align: center;
      font-size: 64px;
      font-style: normal;
      font-weight: 800;
      line-height: 80px; /* 125% */
      letter-spacing: -1.92px;
      text-transform: capitalize;
    }
  }
}

.loader svg {
  height: 100%;
  width: 100%;
}

.loader svg path {
  stroke: black;
  stroke-width: 1px;
}

@media only screen and (max-width: 575px) {
  .loader {
    height: calc(100vh + 150px);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: block;

    &.visible {
      display: none;
    }

    .title {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -40%);
      font-size: 2em;
      font-weight: bold;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;

      .dots {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: white;
        margin-right: 10px;
      }

      h1 {
        color: #f0f0f0;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 80px; /* 285.714% */
        letter-spacing: -0.84px;
      }
    }
  }
}
