.burger-menu-icon {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 90;
  position: fixed;
  left: 0;
  transition: 0.4s all ease-in-out;
  pointer-events: none;
  left: 30px;
  top: 45%;

  &.burger-dark {
    transition: 0.2s background ease-in-out;

    .bar {
      background: white;
    }

    &:hover {
      .bar {
        background: #2c2c2c;
      }
    }
  }

  &:hover {
    .bar {
      background: white;
    }
  }

  .bar {
    border-radius: 50px;
    background-color: #2c2c2c;
  }

  .bar:first-child {
    height: 3px;
    border-radius: 60px;
  }

  .bar:nth-child(2) {
    height: 5px;
  }

  .bar:nth-child(3) {
    height: 6.5px;
  }

  .bar:nth-child(4) {
    height: 8px;
  }

  .bounds {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: all;
    cursor: pointer;

    &:hover {
      transform: scale(3);
    }
  }
  @media only screen and (max-width: 575px) {
    display: none;
  }
}
