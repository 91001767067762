.work-project-page .work-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  z-index: 0;
}
.work-project-page .work-landing {
  padding: 150px 0;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 100px;
}
.work-project-page .work-landing h1 {
  color: #fff;
  font-family: Raleway;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -3px;
  text-transform: uppercase;
  white-space: nowrap;
}
.work-project-page .work-landing .container {
  display: flex;
}
.work-project-page .work-landing .container p {
  color: #fff;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  letter-spacing: -0.6px;
  margin-bottom: 40px;
  word-wrap: nowrap;
}
.work-project-page .work-landing .container h2 {
  padding-top: 40px;
  color: #fff;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: nowrap;
  letter-spacing: -0.9px;
  border-top: 1px solid #fff;
}
.work-project-page .work-landing .container .service {
  margin-right: 121px;
}
.work-project-page .work-landing .container .service p {
  width: 243px;
}
.work-project-page .work-image-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.work-project-page .work-image-container .laptop-image-container {
  width: 1745px;
  height: 100%;
  position: relative;
}
.work-project-page .work-image-container .laptop-image-container video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.work-project-page .work-image-container .phones {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1100px;
}
.work-project-page .work-image-container .phones .mobile {
  width: 310.556px;
  height: 600px;
  flex-shrink: 0;
}
.work-project-page .next-case {
  background: url("../../svgs/home_background.svg");
  height: 100vh;
  width: 1300px;
  background-size: cover;
  background-position: center;
  display: flex;
}
.work-project-page .next-case .left {
  display: flex;
  align-items: flex-end;
  padding-bottom: 50px;
  margin-right: 26px;
  padding-left: 50px;
}
.work-project-page .next-case .left .stretch p {
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.work-project-page .next-case .right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.work-project-page .next-case .right .scope {
  width: 47px;
  height: 173px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 127px;
}
.work-project-page .next-case .right .scope p {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -1.2px;
}
.work-project-page .next-case .right h1 {
  color: var(--Color-Primary-Black, #1b1b1b);
  font-family: Raleway;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.1px;
  transition: 0.3s all ease-in-out;
  text-transform: capitalize;
}
.work-project-page .next-case .right .case {
  position: absolute;
  right: -65%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 850px;
  height: 600px;
  padding: 70px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: var(--Color-Primary-Black, #1b1b1b);
  -webkit-backdrop-filter: blur(150px);
          backdrop-filter: blur(150px);
  transition: 0.3s all ease-in-out;
}
.work-project-page .next-case .right .case .left-case {
  flex: 0.3;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  margin-right: 11px;
}
.work-project-page .next-case .right .case .left-case h2 {
  padding: 0;
  margin: 0;
  color: #fff;
  font-family: Raleway;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.work-project-page .next-case .right .case .left-case h3 {
  padding: 30px 0;
  color: #fff;
  font-family: Raleway;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.work-project-page .next-case .right .case .left-case p {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.work-project-page .next-case .right .case .right-case {
  flex: 0.7;
  height: 100%;
  background: red;
}
.work-project-page .next-case .right .case .right-case img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.work-project-page .next-case .right:hover .case {
  right: 0%;
}
.work-project-page .next-case .right:hover h1 {
  transform: translateX(40%) rotate(-90deg);
}/*# sourceMappingURL=work-project-page.css.map */