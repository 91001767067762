.proceed-icon {
  border-radius: 60px;
  background: rgba(250, 255, 188, 0.8);
  box-shadow: 0px 4px 50px 0px rgba(231, 255, 133, 0.64);
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  display: flex;
  width: 80px;
  height: 80px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  opacity: 0;
  transform: scale(0.2);
  position: fixed;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.proceed-icon.visible {
  opacity: 1;
  transform: scale(1);
}/*# sourceMappingURL=stick-proceed-icon.css.map */