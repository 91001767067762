.work-mobile {
  width: 100vw;
  position: relative;
  padding-top: 157px;

  &::-webkit-scrollbar {
    display: none;
  }

  .mobile-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  main {
    position: relative;
    z-index: 1;

    .work-title {
      color: #f0f0f0;
      text-align: center;
      font-family: Raleway;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: -1.2px;
    }

    .view-project-flex {
      margin: 0 auto;
      display: flex;
      padding: 25px 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 200px;
      background: rgba(27, 27, 27, 0.5);
      backdrop-filter: blur(25px);
      margin-top: 20px;
      align-self: center;
      width: fit-content;
      color: #fff;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.48px;
    }

    .work-mobile-container {
      width: 100%;
      margin-bottom: 150px;
      padding: 0 20px;

      .cardContainer {
        height: 100vh;
        padding-top: 30px;
        padding-top: 200px;
        display: flex;
        justify-content: center;
        position: sticky;
        top: 0;
        width: 100%;

        // overflow-x: hidden;

        .card {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 540px;
          border-radius: 20px;
          overflow: hidden;
          padding: 20px;
          backdrop-filter: blur(150px);

          //   img {
          //     width: 100%;
          //     height: 50%;
          //     border-radius: 25px;
          //   }

          .card-content {
            width: 100%;
            flex: 0.3;
            margin-bottom: 15px;

            h2 {
              color: #f0f0f0;
              font-family: Raleway;
              font-size: 30px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            h3 {
              padding: 20px 0;
              color: #f0f0f0;
              font-family: Raleway;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            p {
              color: #f0f0f0;
              font-family: Raleway;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
          }

          .imageContainer {
            flex: 0.7;
            width: 100%;
            overflow: hidden;

            .inner {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: cover;
              }
            }
          }
        }
      }
    }
  }
}
