.work-landing {
  padding-left: 200px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 100px;

  .work-landing-section {
    height: 70%;
    background: yellow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }

  // .container {
  //   display: flex;

  // p {
  //   color: #fff;
  //   font-family: Raleway;
  //   font-size: 20px;
  //   font-style: normal;
  //   font-weight: 200;
  //   line-height: normal;
  //   letter-spacing: -0.6px;
  //   margin-bottom: 40px;
  //   word-wrap: nowrap;
  // }

  h2 {
    padding-top: 40px;
    color: #fff;
    font-family: Raleway;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-wrap: nowrap;
    letter-spacing: -0.9px;
    border-top: 1px solid #fff;
  }

  .service {
    margin-right: 121px;

    p {
      width: 243px;
    }
  }
  // }
}
