.meet-container {
  height: 100vh;
  width: 1800px;
  padding: 20px 0;
  padding-left: 130px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.meet-container.meet-bamise-container {
  padding-left: 230px;
  width: 1600px;
}
.meet-container .content {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0.5;
}
.meet-container .content .ctn-title {
  display: flex;
  justify-content: space-between;
}
.meet-container .content .ctn-title h4 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2px;
  text-wrap: nowrap;
}
.meet-container .content .ctn-title .stretch {
  margin-right: 30px;
  position: relative;
  top: -20%;
}
.meet-container .content .ctn-title .stretch .btn-content {
  z-index: 10;
  display: flex;
}
.meet-container .content .ctn-title .stretch .btn-content p {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.72px;
  transform: rotate(-10deg) translateY(30%);
  margin-right: 10px;
}
.meet-container .content .keys h1 {
  color: #f0f0f0;
  font-family: Raleway;
  font-size: 100px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -5px;
  text-wrap: nowrap;
}
.meet-container .images {
  height: 80%;
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.meet-container .images .img {
  height: 100%;
  width: 198px;
  margin-right: 11px;
  overflow: hidden;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s all ease-in-out;
  cursor: pointer;
  position: relative;
  background: rgb(18, 18, 18);
}
.meet-container .images .img:hover {
  background: rgb(0, 30, 0);
}
.meet-container .images .img:nth-child(2):hover {
  background: rgb(67, 67, 0);
}
.meet-container .images .img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.meet-container .images .img .ctn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 50px;
  padding-left: 39px;
  display: flex;
  padding-bottom: 35px;
  padding-right: 0;
  transition: 0.8s all ease-in-out;
}
.meet-container .images .img .ctn .left {
  flex: 1;
  display: none;
  transition: 0.8s all ease-in-out;
}
.meet-container .images .img .ctn .right {
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 13px;
  transition: 0.5s all ease-in-out;
}
.meet-container .images .img .ctn .right .social {
  display: none;
  transition: 0.8s all ease-in-out;
}
.meet-container .images .img .ctn .right .scope {
  height: 240px;
  transform: translateY(43%);
  transition: 0.2s all ease-in-out;
}
.meet-container .images .img .ctn .right .scope .career {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #fff;
  font-family: Raleway;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -1.5px;
  transition: 0.2s all ease-in-out;
}
.meet-container .images .img:hover {
  width: 600px;
}
.meet-container .images .img:hover .ctn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 50px;
  padding-left: 39px;
  display: flex;
}
.meet-container .images .img:hover .ctn .left {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
}
.meet-container .images .img:hover .ctn .left h3 {
  color: #fff;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
  text-wrap: nowrap;
}
.meet-container .images .img:hover .ctn .left p {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.72px;
  text-wrap: nowrap;
}
.meet-container .images .img:hover .ctn .right {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
.meet-container .images .img:hover .ctn .right .scope {
  width: 80px;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  transform: translateY(0);
}
.meet-container .images .img:hover .ctn .right .scope .career {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #fff;
  font-family: Raleway;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.4px;
}
.meet-container .images .img:hover .ctn .right .social {
  display: inherit;
  border-radius: var(--Radius-50, 50px);
  background: rgba(27, 27, 27, 0.05);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  padding: 20px;
}
.meet-container .images .img:nth-child(2) .ctn .right .scope {
  transform: translateY(50%) translateX(5%);
}
.meet-container .images .img:nth-child(2):hover .ctn .right .scope {
  transform: translateY(0%) translateX(0%);
}
.meet-container .images .img.bamise {
  width: 600px;
}
.meet-container .images .img.bamise .ctn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 50px;
  padding-left: 39px;
  display: flex;
}
.meet-container .images .img.bamise .ctn .left {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
}
.meet-container .images .img.bamise .ctn .left h3 {
  color: #fff;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
  text-wrap: nowrap;
}
.meet-container .images .img.bamise .ctn .left p {
  color: #fff;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.72px;
  text-wrap: nowrap;
}
.meet-container .images .img.bamise .ctn .right {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
}
.meet-container .images .img.bamise .ctn .right .scope {
  width: 80px;
  height: 384px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  transform: translateY(0);
}
.meet-container .images .img.bamise .ctn .right .scope .career {
  transform: rotate(-90deg);
  text-wrap: nowrap;
  color: #fff;
  font-family: Raleway;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -2.4px;
}
.meet-container .images .img.bamise .ctn .right .social {
  display: inherit;
  border-radius: var(--Radius-50, 50px);
  background: rgba(27, 27, 27, 0.05);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  padding: 20px;
}
.meet-container .images .img.bamise .ctn .right .scope {
  transform: translateY(50%) translateX(5%);
}
.meet-container .images .img.bamise .ctn .right .scope {
  transform: translateY(0%) translateX(0%);
}/*# sourceMappingURL=meet-team.css.map */