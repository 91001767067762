.about-overview {
  padding-left: 400px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-overview .content {
  height: 75%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.about-overview .content h1 {
  color: #fff;
  font-family: Raleway;
  font-size: 200px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.about-overview .content p {
  width: 1156px;
  color: #fff;
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}/*# sourceMappingURL=about-overview.css.map */