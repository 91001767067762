.about-navbar {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100px;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px 70px 70px 5px;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(25px);
  z-index: 10;
  padding-top: 50px;

  &.thought-process {
    background: rgba(255, 255, 255, 0.5);

    .logo {
      .scope {
        p {
          color: #323232;
        }
      }
    }

    .links {
      .scope {
        a {
          color: #323232;
        }
      }

      &.links-bamise {
        .scope {
          a {
            color: #323232;
          }
        }
      }
    }
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .scope {
      width: 50px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      p {
        transform: rotate(-90deg);
        text-wrap: nowrap;
        color: #fff;
        font-family: Raleway;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &.scope-bamise {
        height: 210px;
        width: 50px;
        margin-bottom: 5px;

        p {
          font-size: 18px;
        }
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding-bottom: 60px;

    &.links-bamise {
      padding-bottom: 30px;

      .scope {
        width: 50px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        &.get {
          height: 50px;
        }

        &:first-child {
          height: 40px;
          margin-bottom: 28px;
        }

        &:nth-child(2) {
          margin-bottom: 20px;
        }

        &:nth-child(3) {
          height: 80px;
          margin-bottom: 20px;
        }

        &:nth-child(4) {
          height: 50px;
        }

        a {
          transform: rotate(-90deg);
          text-wrap: nowrap;
          color: #fff;
          font-family: Raleway;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -0.48px;
          transition: 0.2s all ease-in-out;

          &.active {
            font-weight: 800;
          }

          &:hover {
            font-weight: 800;
          }
        }
      }
    }

    .scope {
      width: 50px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      &.get {
        height: 50px;
      }

      &:first-child {
        height: 65px;
      }

      &:nth-child(3) {
        height: 80px;
      }

      &:nth-child(4) {
        height: 50px;
      }

      a {
        transform: rotate(-90deg);
        text-wrap: nowrap;
        color: #fff;
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -0.48px;
        transition: 0.2s all ease-in-out;

        &.active {
          font-weight: 800;
        }

        &:hover {
          font-weight: 800;
        }
      }
    }
  }

  @media only screen and (max-width: 575px) {
    display: none;
  }
}
