.parent-btn {
  left: 100px;
  top: 21px;
  position: absolute;
}
.parent-btn .back-btn {
  position: absolute;
  z-index: 9;
  border-radius: 0px 300px 300px 0px;
  background: #2c2c2c;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  display: flex;
  width: 150px;
  height: 100px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
.parent-btn .back-btn .text-btn {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  z-index: 1;
}
.parent-btn .back-btn .background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 0;
  border-radius: 50%;
}
@media only screen and (max-width: 575px) {
  .parent-btn {
    display: none;
  }
}/*# sourceMappingURL=back-btn.css.map */