.contact-modal {
  position: absolute;
  right: -200%;
  opacity: 0;
  height: 100%;
  width: 755px;
  background: rgba(27, 27, 27, 0.9);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  transition: 1s all cubic-bezier(0.99, -0.01, 0, 1);
}
.contact-modal.review {
  right: 0;
  opacity: 1;
}
.contact-modal .icon-wrap {
  display: none;
}
.contact-modal .contact-title {
  color: #f1f1f1;
  text-align: center;
  font-family: Raleway;
  font-size: 90px;
  font-style: normal;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: -2.7px;
}
.contact-modal .modes {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-modal .modes .mode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 100px;
  background: #f0f0f0;
  height: 80px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
.contact-modal .modes .mode:hover {
  transform: scale(0.95);
}
.contact-modal .modes .mode .social-text {
  margin-left: 10px;
  color: var(--Color-Primary-Black, #1b1b1b);
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact-modal .btn-contact {
  display: flex;
  width: 575px;
  height: 80px;
  padding: 20px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  border-radius: var(--Radius-50, 50px);
  background: #1d1d1d;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  position: relative;
  overflow: hidden;
}
.contact-modal .btn-contact.hidden {
  opacity: 0;
  pointer-events: none;
}
.contact-modal .btn-contact .text {
  flex: 1;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  position: relative;
  z-index: 1;
}
.contact-modal .btn-contact .icon-container {
  position: relative;
  z-index: 1;
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--color-grey-Altblack, #323232);
}
.contact-modal .btn-contact .background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform-origin: bottom;
  z-index: 0;
  border-radius: 50%;
}
.contact-modal .btn-contact .icon-container {
  border-radius: 60px;
  background: #3c3c3c;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
}
.contact-modal .form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contact-modal .form .form-mode {
  cursor: pointer;
  margin-bottom: 50px;
  width: 100%;
  border-radius: 100px;
  background: linear-gradient(90deg, #6a5a38 0%, #563e38 30%, #515f61 70%, #463b4c 100%);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: 0.2s all ease-in-out;
}
.contact-modal .form .form-mode:hover {
  transform: scale(0.95);
}
.contact-modal .form .form-mode img {
  filter: invert(1);
}
.contact-modal .form .form-mode .social-text {
  margin-left: 10px;
  color: #f0f0f0;
  text-align: center;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact-modal .form h3 {
  padding-top: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-align: center;
  font-family: Raleway;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  margin-bottom: 20px;
}
.contact-modal .form input {
  margin-bottom: 50px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: var(--Radius-50, 50px);
  background: #f1f1f1;
  box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
  height: 70px;
  padding: 0px 30px;
  text-align: center;
  color: rgba(27, 27, 27, 0.6);
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact-modal .form input.input-error {
  margin-bottom: 15px;
}
.contact-modal .form .error {
  margin-bottom: 20px;
  width: 100%;
  color: rgb(255, 94, 94);
  position: relative;
  padding-left: 30px;
}
.contact-modal .form .error::before {
  content: "";
  position: absolute;
  top: 40%;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(255, 52, 52);
}
@media only screen and (max-width: 575px) {
  .contact-modal {
    position: fixed;
    top: 0;
    right: -200%;
    opacity: 0;
    height: 100%;
    width: 100%;
    background: rgba(27, 27, 27, 0.9);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
    padding: 80px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: 1s all cubic-bezier(0.99, -0.01, 0, 1);
  }
  .contact-modal .icon-wrap {
    display: flex;
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: rgba(27, 27, 27, 0.5);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
  .contact-modal .contact-title {
    color: #f0f0f0;
    text-align: center;
    font-family: Raleway;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 80% */
    letter-spacing: -1.5px;
  }
  .contact-modal .modes {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    justify-content: center;
  }
  .contact-modal .modes .mode {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 100px;
    background: #f0f0f0;
    height: 60px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
  }
  .contact-modal .modes .mode:hover {
    transform: scale(0.95);
  }
  .contact-modal .modes .mode .social-text {
    margin-left: 10px;
    color: var(--Color-Primary-Black, #1b1b1b);
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact-modal .form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
  }
  .contact-modal .form .form-mode {
    cursor: pointer;
    margin-bottom: 50px;
    width: 100%;
    border-radius: 100px;
    background: linear-gradient(90deg, #6a5a38 0%, #563e38 30%, #515f61 70%, #463b4c 100%);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .contact-modal .form .form-mode img {
    filter: invert(1);
  }
  .contact-modal .form .form-mode .social-text {
    margin-left: 10px;
    color: #f0f0f0;
    text-align: center;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact-modal .form h3 {
    padding-top: 50px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    text-align: center;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    margin-bottom: 20px;
  }
  .contact-modal .form input {
    margin-bottom: 50px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: var(--Radius-50, 50px);
    background: #f1f1f1;
    box-shadow: 4px 4px 30px 0px rgba(0, 0, 0, 0.05) inset, -4px -4px 30px 0px rgba(255, 255, 255, 0.05) inset;
    height: 60px;
    padding: 0px 30px;
    text-align: center;
    color: rgba(27, 27, 27, 0.6);
    text-align: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contact-modal .form input.input-error {
    margin-bottom: 15px;
  }
  .contact-modal .form .error {
    font-size: 14px;
  }
  .contact-modal .btn-contact {
    display: flex;
    width: 330px;
    height: 70px;
    padding: 20px 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 50px;
    border-radius: var(--Radius-50, 50px);
    background: #1d1d1d;
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
    position: relative;
    overflow: hidden;
  }
  .contact-modal .btn-contact.hidden {
    opacity: 0;
    pointer-events: none;
  }
  .contact-modal .btn-contact .text {
    flex: 1;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    position: relative;
    z-index: 1;
  }
  .contact-modal .btn-contact .icon-container {
    position: relative;
    z-index: 1;
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 50px;
    background: var(--color-grey-Altblack, #323232);
  }
  .contact-modal .btn-contact .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: bottom;
    z-index: 0;
    border-radius: 50%;
  }
  .contact-modal .btn-contact .icon-container {
    border-radius: 60px;
    background: #3c3c3c;
    -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .contact-modal {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-modal .contact-title {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contact-modal {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-modal .contact-title {
    margin-bottom: 20px;
  }
}/*# sourceMappingURL=contact-modal.css.map */